import React from 'react'
// import { summary } from './Overview'
import AIBlue from "../../../assets/svg/ai-blue.svg"
import { useAppSelector } from '../../../lib/hooks'
import { RootState } from '../../../lib/store'
import { shallowEqual } from 'react-redux'
import Flag from "../../../assets/svg/summary/Flag.svg"
import Bulb from "../../../assets/svg/summary/Bulb.svg"
import Team from "../../../assets/team.png"
import Market from "../../../assets/svg/summary/Market.svg"
import UploadIcon from "../../../assets/svg/Upload.svg"
import Paws from "../../../assets/svg/summary/Paws.svg"
import Competitive from "../../../assets/svg/summary/Competitive.svg"
import Why from "../../../assets/svg/summary/Why.svg"
import AiLogoWhite from "../../../assets/svg/ai-white.svg"
import SendButton from "../../../assets/svg/SendButton.svg"
import { useAiFAQMutation, useGetSingleAIFAQConversationQuery } from '../../../lib/features/ai_agent/aiAgentApiSlice'
import { Button } from '../../../components/shared/Button'
import { useLazyGetStartUpQuery, useUpdateStartUpAndUserMutation } from '../../../lib/features/startup/startupApiSlice'
import { successMessage } from '../../../constants/notification'

function ExecutiveSummary() {
    const startupData = useAppSelector((state: RootState) => state.startup, shallowEqual)
    const user = useAppSelector((state: RootState) => state.user, shallowEqual)
    const [question, setQuestion] = React.useState('');


    const [chatActive, setChatActive] = React.useState(false)

    const [problem, setProblem] = React.useState(startupData.problem)
    const [solution, setSolution] = React.useState(startupData.solution)
    const [market, setMarket] = React.useState(startupData.market)
    const [competitive, setCompetitive] = React.useState(startupData.competitive_advantages)
    const [why, setWhy] = React.useState(startupData.good_investment_case)

    const [aiFAQ, { isLoading: askIsLoading }] = useAiFAQMutation()
    const { data, refetch } = useGetSingleAIFAQConversationQuery({ userID: user.id })

    const scrollableDivRef = React.useRef<HTMLDivElement | null>(null);

    const inputRef = React.useRef<HTMLInputElement | null>(null);
    const buttonRef = React.useRef<HTMLButtonElement | null>(null);

    const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {

        if (event.key === 'Enter') {
            // Trigger button click
            buttonRef.current?.click();
            scrollToBottom()
        }
    };

    const [updateStartUpAndUser] = useUpdateStartUpAndUserMutation()
    const [getStartUp] = useLazyGetStartUpQuery()



    const summary: any[] = [
        {
            id: 1,
            title: "Problem",
            icon: Flag,
            content: problem,
            assistant: ["Elite mentors' importance?", "Challenges without guidance?", "Impact on long-term success?"],
            setValue: setProblem
        },
        {
            id: 2,
            title: "Solution",
            icon: Bulb,
            content: solution,
            assistant: ["AI mentoring effectiveness?", "AI vs. human mentors?", "AI for early/late-stage?"],
            setValue: setSolution
        },
        {
            id: 3,
            title: "Market",
            icon: Market,
            content: market,
            assistant: ["Focus on SMBs?", "Defining diverse founders?", "Reaching global SMBs?"],
            setValue: setMarket
        },
        {
            id: 4,
            title: "Competitive advantage",
            icon: Competitive,
            content: competitive,
            assistant: ["Elite mentors' importance?", "Challenges without guidance?", "Impact on long-term success?"],
            setValue: setCompetitive
        },
        {
            id: 5,
            title: "Why is this a good investment case?",
            icon: Why,
            content: why,
            assistant: ["Elite mentors' importance?", "Challenges without guidance?", "Impact on long-term success?"],
            setValue: setWhy
        },
    ]
    const scrollToBottom = React.useCallback(() => {
        if (scrollableDivRef.current) {
            scrollableDivRef.current.scrollIntoView({
                block: 'end',
                behavior: 'smooth',

            })
        }
    }, [])

    const handleFAQ = async (question: string) => {
        setChatActive(true)
        scrollToBottom()
        await aiFAQ({ user_id: user.id, question: question }).then((res) => {
            setQuestion("")
            refetch()
            console.log(res)
        })
    }
    return (
        <>
            <div className='lg:px-10 px-4 pt-4 w-full'>
                <section className='border-b w-full border-[#E9ECEF]'>
                    <div className='flex items-center justify-between '>
                        <div className='flex gap-x-6 items-center'>
                            <h2 className='text-2xl font-medium'>Executive Summary</h2>


                        </div>

                    </div>

                    <div className='mt-8 w-full p-6 border border-[#E8E8E8] rounded-md shadow-sm shadow-black/10 space-y-6 '>
                        {summary.map((item, index) => (
                            <div className='flex flex-col gap-y-3' key={index}>
                                <div className='flex gap-x-2 items-center'>
                                    <img src={item.icon} alt="" />
                                    <p className='text-sm font-normal'>{item.title}</p>
                                </div>
                                <textarea className='border px-3 py-2 rounded-md border-[#E8E8E8] text-sm text-[#4F4F4F] h-24' value={item.content} onChange={(e) => { item.setValue(e.target.value) }} />

                                <div className='flex items-center gap-x-2'>
                                    <div className='flex gap-x-2 items-center'>
                                        <img src={AIBlue} alt="" className='size-3' />
                                        <p className='text-primary'>AI assistant:</p>
                                    </div>
                                    {item.assistant.map((assistant: string, index: number) => (
                                        <button className='text-sm font-normal text-primary border border-[#0E4A8D] rounded-full py-2 px-3' key={index}
                                            value={assistant}
                                            onClick={() => { setQuestion(assistant); handleFAQ(assistant) }}
                                        >{assistant}</button>
                                    ))}

                                </div>
                                <div className=' mt-5 mx-16 border-b'></div>
                            </div>
                        ))}

                        <Button onClick={() => {
                            updateStartUpAndUser({
                                id: user?.id,
                                startup_id: startupData?.id,
                                user_data: {},
                                start_up_data: {
                                    problem: problem,
                                    solution: solution,
                                    market: market,
                                    competitive_advantages: competitive,
                                    good_investment_case: why,
                                }
                            }).then(() => {
                                getStartUp(user?.id)
                                successMessage("Information saved successfully")
                            })
                        }} className='text-primary px-8 py-3.5'>
                            Save
                        </Button>
                    </div>



                    <div className=' mt-5'></div>
                </section>
            </div>


            <div className='fixed bottom-10 right-10 flex items-end'>
                <div className={`${chatActive ? 'h-[550px] opacity-100' : 'h-0 opacity-0'} w-[450px] bg-white rounded-md shadow-xl shadow-black/10 flex flex-col transition-all duration-300 ease-in-out`}>
                    <div className='bg-[#097DFF] px-5 py-4 flex items-center gap-x-3 mb-2'>
                        <img src={AiLogoWhite} alt="" className='size-6' />
                        <p className='text-white'>AI assistant</p>
                    </div>

                    <div className='w-full h-full overflow-y-scroll px-3 space-y-3 pb-5' ref={scrollableDivRef}>
                        {data?.map((item: any, index: number) => {

                            const isQuestion = item?.role === 'user'
                            const parsedConversation = !isQuestion && item.content
                            // const isLastItem = index === data?.length - 1
                            return (
                                isQuestion ?
                                    <div className='p-2.5 bg-[#E3F0FF] w-2/3 float-right rounded-md'>
                                        {/* <div className='flex gap-x-4 items-center'>
                                            <img src={Flag} alt="" />
                                            <p className='text-sm font-normal'>Problem</p>
                                        </div> */}
                                        <p className='text-sm text-primary font-bold'>{item.content.USER}</p>
                                    </div>
                                    :

                                    <div className='relative w-2/3 float-left '>
                                        <img src={AIBlue} alt="" className='my-1' />
                                        <div className='p-2.5 bg-primary rounded-md'>
                                            <p className='text-sm text-white'>{parsedConversation.AI}</p>
                                        </div>
                                    </div>
                            )
                        })}
                        {question !== "" &&
                            <div className='p-2.5 bg-[#E3F0FF] w-2/3 float-right rounded-md'>
                                {/* <div className='flex gap-x-4 items-center'>
                                    <img src={Flag} alt="" />
                                    <p className='text-sm font-normal'>Problem</p>
                                </div> */}
                                <p className='text-sm text-primary font-bold mt-4'>{question}</p>
                            </div>
                        }
                        {askIsLoading &&
                            <div className='relative w-2/3 float-left'>
                                <img src={AIBlue} alt="" className='my-1' />
                                <div className='h-20 w-full bg-primary/30 rounded-md animate-pulse'></div>

                            </div>
                        }






                    </div>

                    <div className='px-4 py-2'>
                        <div className='relative'>
                            <input type="text" className='border border-[#E8E8E8] rounded-md pl-3 pr-10 py-2 w-full' placeholder="Type here" value={question} onChange={(e) => { setQuestion(e.target.value) }} ref={inputRef} onKeyDown={handleKeyDown} />
                            <button className='absolute right-2 top-1/2 -translate-y-1/2' ref={buttonRef} onClick={() => { handleFAQ(question); }}>
                                <img src={SendButton} alt="" />
                            </button>
                        </div>
                    </div>

                </div>


                <button className='float-right' onClick={() => { setChatActive(!chatActive) }}>
                    <img src={AIBlue} alt="" className='size-10' />
                </button>



            </div>
        </>
    )
}

export default ExecutiveSummary
