import React from 'react'
import { useParams } from "react-router-dom";
import { useGetSingleConversationQuery, useAskMutation } from '../../lib/features/conversation/conversationApiSlice';
import { RootState } from '../../lib/store';
import { useAppSelector } from '../../lib/hooks';
import { Sources } from './components/Sources';
// import tools from '../../assets/svg/Tools.svg'
import { Connection } from './components/Connection';
import askBtn from "../../assets/svg/askBtn.svg";
import share from '../../assets/svg/shareBtn.svg'
import { PulseLoader } from "react-spinners";
import { ArrowRight, Copy, SquareArrowOutUpRight, RotateCcw, ThumbsDown, LayoutList } from 'lucide-react'
import { SearchSkeleton } from '../../components/SearchSkeleton';






function Conversation() {
    let { id } = useParams();
    const [question, setQuestion] = React.useState('');
    const scrollableDivRef = React.useRef<HTMLDivElement | null>(null);

    const inputRef = React.useRef<HTMLInputElement | null>(null);
    const buttonRef = React.useRef<HTMLButtonElement | null>(null);

    const userId = useAppSelector((state: RootState) => state.user?.id)

    const { data, refetch } = useGetSingleConversationQuery({ userID: userId, conversationId: id })
    const [ask, { isLoading: askIsLoading }] = useAskMutation()

    const scrollToBottom = React.useCallback(() => {
        if (scrollableDivRef.current) {
            scrollableDivRef.current.scrollIntoView({
                block: 'end',
                behavior: 'smooth',

            })
        }
    }, [])

    const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
        if (event.key === 'Enter') {
            // Trigger button click
            buttonRef.current?.click();
        }
    };

    // if (data) {
    //     let parsedConversation: { answer: string, relatedQuestions: string[], sources: { title: string, link: string }[] } | undefined
    //     parsedConversation = JSON.parse(data?.content?.content || "")
    //     
    // }


    return (
        <div className='h-full w-full overflow-y-auto' >
            <div className='lg:p-9 flex flex-col relative p-3' ref={scrollableDivRef}>
                <div className='flex lg:justify-end justify-center gap-x-3'>
                    <Connection persona={data?.chatPersona} />
                    {/* <div style={{ marginLeft: 10 }}>
                        <img src={share} alt={'share'} />
                    </div> */}
                </div>

                {/*Answer/Question*/}
                <div className='lg:w-[60vw] w-full lg:max-w-[60vw] mx-auto' >
                    {data?.content.map((item: any, index: number) => {
                        const isQuestion = item?.role === 'user'
                        const parsedConversation = !isQuestion && JSON.parse(item.content)
                        const isLastItem = index === data?.content.length - 1
                        // 
                        return (

                            isQuestion ?
                                <>
                                    <div
                                        style={{ fontSize: 25, marginTop: 40 }}
                                    >
                                        {item.content}
                                    </div>
                                </> :

                                <div className='mt-5'>
                                    <Sources sources={parsedConversation.sources ?? []} />
                                    <div style={{
                                        display: 'flex',
                                        alignItems: 'center',
                                        marginTop: 20,
                                    }}>
                                        <h2 className="flex items-center leading-none py-2 text-lg font-semibold">
                                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="lucide lucide-book-check mr-1.5 text-muted-foreground"><path d="M4 19.5v-15A2.5 2.5 0 0 1 6.5 2H20v20H6.5a2.5 2.5 0 0 1 0-5H20"></path><path d="m9 9.5 2 2 4-4"></path>
                                            </svg>
                                            Answer
                                        </h2>
                                    </div>

                                    <div className='mt-2'>{parsedConversation?.answer}</div>

                                    <div className='mt-2 flex items-center gap-2.5' >
                                        <h2 className="flex items-center leading-none py-2 text-lg font-semibold">
                                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="lucide lucide-repeat2 mr-1.5 text-muted-foreground"><path d="m2 9 3-3 3 3"></path><path d="M13 18H7a2 2 0 0 1-2-2V6"></path><path d="m22 15-3 3-3-3"></path><path d="M11 6h6a2 2 0 0 1 2 2v10"></path>
                                            </svg>
                                            Related
                                        </h2>
                                    </div>
                                    {parsedConversation?.relatedQuestions.map((rq: any, index: number) =>
                                        <div className="flex items-center w-full" key={index}>
                                            <ArrowRight className="h-4 w-4 mr-2 mt-1 flex-shrink-0 text-accent-foreground/50" />
                                            <button
                                                className="flex-1 justify-start px-0 py-1 h-fit "
                                                value={rq}
                                                onClick={() => {
                                                    scrollToBottom()
                                                    setQuestion(rq)
                                                    ask({
                                                        id: id,
                                                        userId: userId,
                                                        title: data?.title,
                                                        chatPersona: data?.chatPersona,
                                                        content: [
                                                            {
                                                                role: "user",
                                                                content: rq
                                                            }
                                                        ]
                                                    }).then((res) => {
                                                        setQuestion("")
                                                        refetch()
                                                        scrollToBottom()
                                                    }).catch((err) => {

                                                    })

                                                }}

                                            ><p className='font-normal text-sm text-accent-foreground/50 whitespace-normal text-left'>{rq}</p></button>
                                        </div>
                                    )}
                                    {/*TOOLS*/}
                                    {/* <div style={{ marginTop: 20, }}>
                                        <img src={tools} alt={'tools'} />
                                    </div> */}
                                    {/* <div className='mt-5 flex items-center justify-between max-w-[810px] text-accent-foreground/50'>
                                        <div className='flex items-center gap-x-4'>
                                            <button className='flex items-center gap-x-1'>
                                                <SquareArrowOutUpRight className='size-4' />
                                                <p>Share</p>
                                            </button>
                                            <button className='flex items-center gap-x-1'>
                                                <RotateCcw className='size-4' />
                                                <p>Rewrite</p>
                                            </button>
                                        </div>

                                        <div className='flex items-center gap-x-4'>
                                            <button>
                                                <LayoutList className='size-4' />
                                            </button>
                                            <button>
                                                <Copy className='size-4' />
                                            </button>
                                            <button>
                                                <ThumbsDown className='size-4' />
                                            </button>
                                        </div>
                                    </div> */}
                                    <hr className='my-6 outline outline-gray-300' />
                                    {isLastItem && !askIsLoading &&
                                        <div style={{ width: '100%', height: '18vh' }} />
                                    }

                                </div>
                        )
                    })}

                    {question !== "" && (
                        <>
                            <div
                                style={{ fontSize: 25, marginTop: 40 }}
                            >
                                {question}
                            </div>
                        </>
                    )}

                    {askIsLoading &&
                        <>
                            <SearchSkeleton />
                            <div style={{ width: '100%', height: '18vh' }} />
                        </>
                    }
                </div>

                <div className='fixed lg:w-full mt-5 lg:bottom-14 bottom-10 flex justify-center '

                >
                    <div className='lg:w-min w-full relative'>
                        <input
                            ref={inputRef}
                            onKeyDown={handleKeyDown}
                            placeholder={'What do you want to know?'}
                            value={question}
                            onChange={({ target: { value } }) => {
                                setQuestion(value)
                            }}
                            style={{
                                outline: 'none',

                                border: '1px solid #A5D8FF',
                                borderRadius: 100,
                                height: 54,
                                boxShadow: '0px 0px 8px 8px #E7F5FF',
                                paddingRight: 100,
                                paddingLeft: 20,
                                fontSize: 16,
                                color: '#495057'
                            }} className='lg:w-[68vh] w-full'
                        />

                        <div className='flex items-center absolute right-2.5 gap-1.5 top-3'>
                            {askIsLoading
                                ? <PulseLoader color="#228BE6" style={{ marginTop: 10, marginRight: 5 }} size={12} />
                                : <>
                                    {/* <div style={{ cursor: 'pointer' }}><img src={newSessionBtn} alt={'newSessionBtn'} /></div> */}
                                    <button
                                        ref={buttonRef}
                                        onClick={async () => {
                                            scrollToBottom()
                                            ask({
                                                id: id,
                                                userId: userId,
                                                title: data?.title,
                                                chatPersona: data?.chatPersona,
                                                content: [
                                                    {
                                                        role: "user",
                                                        content: question
                                                    }
                                                ]
                                            }).then((res) => {
                                                setQuestion("")
                                                refetch()
                                                scrollToBottom()
                                            }).catch((err) => {

                                            })

                                        }}
                                        style={{ cursor: 'pointer' }}>
                                        <img src={askBtn} alt={'askBtn'} />
                                    </button>
                                </>}
                        </div>
                    </div>
                </div>
            </div>


        </div>






    )
}

export default Conversation