import { Mutex } from 'async-mutex'
import type { RootState } from '../store'
import { fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import { authenticate, unauthenticate } from '../features/auth/authSlice'


import type {
    BaseQueryFn,
    FetchArgs,
    FetchBaseQueryError,
} from '@reduxjs/toolkit/query'


// export const BASE_URL = "http://127.0.0.1:8000/";
// export const BASE_URL = 'https://dev.foundertribes-api.com/';
export const BASE_URL = 'https://beta.foundertribes-api.com/';

const mutex = new Mutex()
const baseQuery = fetchBaseQuery({
    baseUrl: `${BASE_URL}`,
    credentials: 'same-origin',
    // credentials: 'include',
    prepareHeaders: (headers, { getState }) => {
        const accessToken: string | null = (getState() as RootState)?.auth.access
        if (accessToken) {
            headers.set('authorization', `Bearer ${accessToken}`)
        }
        return headers
    },
})




export const baseQueryWithReauth: BaseQueryFn<
    string | FetchArgs,
    unknown,
    FetchBaseQueryError
> = async (args, api, extraOptions) => {
    await mutex.waitForUnlock()
    let result = await baseQuery(args, api, extraOptions)
    const refreshToken: string | null = (api.getState() as RootState)?.auth.refresh
    if (result.error && result.error.status === 401) {
        if (!mutex.isLocked()) {
            const release = await mutex.acquire()
            try {
                const refreshResult = await baseQuery(
                    {
                        url: '/auth/refreshToken/',
                        method: 'POST',
                        body: { 'refreshToken': refreshToken || '' }
                    },
                    api,
                    extraOptions
                )
                if (refreshResult.data) {
                    api.dispatch(authenticate(refreshResult.data))
                    result = await baseQuery(args, api, extraOptions)
                } else {
                    api.dispatch(unauthenticate())
                }
            } finally {
                release()
            }
        } else {
            await mutex.waitForUnlock()
            result = await baseQuery(args, api, extraOptions)
        }
    }
    return result
}
