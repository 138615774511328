// import { message } from 'antd';
import { toast } from 'react-toastify';

const infoMessage = (text: string) => {
    // message.info(text);
    toast.info(text)
};

const successMessage = (text: string) => {
    // message.success(text);
    toast.success(text)

};

const errorMessage = (text: string) => {
    // message.error(text);
    toast.error(text)

};


export { infoMessage, successMessage, errorMessage}