import React from 'react'
// import { TutorialVideo } from '../../assets/video';
import { Button } from "antd";
import { useGetUserQuery, useUpdateUserMutation } from '../../lib/features/user/userApiSlice';
import { useAppSelector } from '../../lib/hooks';
import { RootState } from '../../lib/store';
import { errorMessage } from '../../constants/notification';
import PitchDeckModal from '../../components/PitchDeckModal';

function FirstTimeLogin() {
    const [pitchDeckModal, setPitchDeckModal] = React.useState(false)


    const user = useAppSelector((state: RootState) => state.user)


    const { refetch: userDataRefetch } = useGetUserQuery(user?.id)

    const videoRef = React.useRef<HTMLVideoElement | null>(null);
    const [updateUser] = useUpdateUserMutation()




    const handleOkTutorialModalClose = () => {
        try {
            updateUser({ userId: user?.id, watched_tutorial: true }).then((res) => {
                userDataRefetch()
                setPitchDeckModal(true)
                videoRef.current?.pause()
            }).catch((err) => {
                errorMessage("Failed to close video")
            });
        }
        catch (e: any) {
            errorMessage("Failed to update information")
        }
    };


    return (
        <div className='h-full flex justify-center items-center'>
            <div className='max-w-[1000px] w-full max-h-[530px]'>
                <video controls autoPlay className='w-full h-full rounded-3xl' ref={videoRef}>
                    <source src={"https://ft-static-media.s3.amazonaws.com/Tutorial.mp4"} type="video/mp4" />
                </video>

                <div className='flex items-center justify-center w-full mt-5 gap-x-4'>
                    {/* <Button
                        type="text"
                        onClick={handleOkTutorialModalClose}
                    >
                        Skip
                    </Button> */}
                    <Button
                        type='primary'
                        className='!bg-primary'
                        onClick={handleOkTutorialModalClose}
                    >
                        Get Started
                    </Button>
                </div>
            </div>

            <PitchDeckModal pitchDeckModal={pitchDeckModal} setPitchDeckModal={setPitchDeckModal} />


        </div>
    )
}

export default FirstTimeLogin
