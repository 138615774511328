import { apiSlice } from "../../services/apiSlice";


export const conversationApiSlice = apiSlice.injectEndpoints({
    endpoints: builder => ({
        getConversations: builder.query({
            query: (userId) => ({
                url: `ask/${userId}`,
                method: 'GET',
            })
        }),
        getSingleConversation: builder.query({
            query: (data) => ({
                url: `ask/${data.userID}/${data.conversationId}`,
                method: 'GET',
            })
        }),
        ask: builder.mutation({
            query: (data) => ({
                url: `ask/`,
                method: 'POST',
                body: data
            })
        }),


    })
})

export const {
    useGetConversationsQuery,
    useLazyGetConversationsQuery,
    useGetSingleConversationQuery,
    useAskMutation
} = conversationApiSlice