import { FC } from 'react'
import { Avatar } from "../../../components/Avatar/Avatar";
import { Modal, Skeleton } from "antd";
import { AiOutlineMail } from "react-icons/ai";
import { BiLogoLinkedin } from "react-icons/bi";
import { TbWorldWww } from "react-icons/tb";
import Rating from './Rating';
import ModalBackground from '../../../assets/bg_modal1.jpg';
import { IoClose } from "react-icons/io5";
import { Tooltip } from 'antd';
import { defaultProfile } from "../../../constants/defaults";







interface IProps {
    avatar?: string | undefined
    name: string,
    fund?: string,
    sectors?: string
    email?: string
    Reason?: string
    matchLevel: string
    bio?: string
    tag?: boolean
    fundwebsite?: string
    linkedin?: string
    loading?: boolean | undefined
    active?: boolean | undefined
    stages?: string | undefined
    index?: string | number
    setRecommendationCard?: any
    recommendationCard?: any
}

export const InvestorCard: FC<IProps> = ({
    avatar,
    name,
    fund,
    email,
    sectors,
    matchLevel,
    Reason,
    bio,
    fundwebsite,
    tag,
    linkedin,
    stages,
    loading,
    active,
    index,
    recommendationCard,
    setRecommendationCard
}) => {


    const showModal = () => {
        setRecommendationCard(index);
    };

    const handleOk = () => {
        setRecommendationCard(null);
    };

    const handleCancel = () => {
        setRecommendationCard(null, "cancel");
    };


    return (
        <>
            <div className='relative flex flex-col justify-between lg:m-2.5 gap-2.5 rounded-lg pb-8 pt-5  px-6 shadow-lg border-t cursor-pointer hover:shadow-2xl' onClick={() => { showModal() }} key={index}>
                <Skeleton loading={loading} active={active} avatar>
                    <div className='flex justify-end'>
                        {tag ?
                            <span className="w-max bg-green-100 text-green-800 text-xs font-medium me-2 px-2.5 py-0.5 rounded">ANGEL</span>
                            :
                            <span className="w-max top-3 bg-[#BDDCFF] text-[#0E4A8D] text-xs font-medium me-2 2xl:px-1.5 px-1 2xl:py-2 py-0.5 rounded">VENTURE CAPITALIST</span>
                        }
                    </div>

                    <div className='flex justify-between'>
                        <div className='flex items-center justify-start gap-x-3'>
                            <Avatar avatarUrl={avatar || defaultProfile} size={64} />
                            <div>
                                <div style={{ fontSize: 16, fontWeight: '500' }}>{name}</div>
                                {!tag &&
                                    <div style={{ fontSize: 14, fontWeight: '400', color: '#868E96' }}>Fund: {fund}</div>
                                }
                                <div className='flex mt-2 gap-x-2'>
                                    {/* {email &&
                                        <Tooltip title="Email">
                                            <a href={`mailto:${email}`}>
                                                <AiOutlineMail className='hover:text-primary cursor-pointer' />
                                            </a>
                                        </Tooltip>
                                    } */}

                                    {linkedin &&
                                        <Tooltip title="Linkedin">
                                            <a href={`${linkedin}`} target='_blank' rel='noreferrer'>
                                                <BiLogoLinkedin className='hover:text-primary cursor-pointer' />
                                            </a>
                                        </Tooltip>
                                    }

                                    {fundwebsite &&
                                        <Tooltip title="Website">
                                            <a href={`${fundwebsite ? fundwebsite?.startsWith("www") ? `https://${fundwebsite}` : fundwebsite : ""}` || ""} target='_blank' rel='noreferrer' onClick={handleCancel}>
                                                <TbWorldWww className='hover:text-primary cursor-pointer' />
                                            </a>
                                        </Tooltip>
                                    }

                                </div>
                            </div>
                        </div>

                        {/* <div className='flex flex-col gap-y-2 items-center'>
                        <div><img src={connect} alt={'connect'} /></div>
                        <div style={{ cursor: 'pointer' }} onClick={showModal}><img src={moreInfo} alt={'moreInfo'} /></div>
                    </div> */}
                    </div>
                    {sectors &&
                        <div className='flex flex-wrap items-center gap-1 w-full'>
                            <p className='text-sm'>Verticals: </p>
                            {sectors.split(",").map((s, index) => (

                                <span
                                    key={index}
                                    style={{
                                        backgroundColor: '#E9ECEF',
                                        borderRadius: 100,
                                        color: '#868E96',
                                        fontSize: 12,
                                        padding: '4px 12px'
                                    }}>{s}</span>
                            ))}
                        </div>}
                    {stages &&
                        <div className='flex flex-wrap items-center gap-x-1 w-full'>
                            <p className='text-sm'>Stages:</p>
                            {stages?.split(",").map((item, index) => (
                                <div className='bg-[#BDDCFF] text-[#0E4A8D] text-xs font-medium me-2 2xl:px-1.5 px-1 2xl:py-2 py-0.5 rounded' key={index}>
                                    {item.charAt(0).toUpperCase() + item.slice(1)}
                                </div>
                            ))}
                        </div>
                    }
                    <hr />

                    <div className='flex flex-col relative' >
                        <div className='flex gap-x-2 text-sm'>
                            <div className='flex'>
                                <p className=''>Match level:</p>
                            </div>

                            <div className='flex'>
                                <Rating rating={parseFloat(matchLevel)} maxRating={tag ? 4 : 6} />
                            </div>
                        </div>

                        {/* <div className='w-full'>
                        <Progress percent={(parseInt(matchLevel) / 8) * 100} />

                    </div> */}
                    </div>

                    <div >
                        <p className='h-40 overflow-y-auto'>{Reason}</p>
                    </div>



                </Skeleton>
                <Skeleton loading={loading} active={active}></Skeleton>
            </div>

            <Modal closeIcon={<IoClose className='size-6 absolute right-0 top-0 rounded-md bg-white text-black' />}
                cancelButtonProps={{ style: { display: 'none' } }}
                okButtonProps={{ style: { display: 'none' } }}
                open={recommendationCard === index} onOk={handleOk} onCancel={handleCancel}
                className='lg:!w-1/2 !w-full'
            >
                <div style={{ display: 'flex', flexDirection: 'column' }}>
                    <div style={{ overflow: 'hidden', maxHeight: 150 }} className='w-full'>
                        <img alt=""
                            style={{ transform: 'translateY:150px' }}
                            src={ModalBackground}
                            className='w-full'
                        />
                    </div>

                    <div style={{ top: 125 }} className='absolute left-1/2 -translate-x-1/2'>
                        <Avatar avatarUrl={avatar || ''} size={84} />
                    </div>

                    <div style={{ textAlign: 'center', marginTop: 50 }}>
                        <div style={{ fontSize: 18, fontWeight: '600' }}>{name}</div>
                        <div style={{ fontSize: 14, fontWeight: '400', color: '#868E96' }}>{email}</div>
                    </div>

                    <div className='flex items-center justify-center mt-2 '>
                        <div className='flex gap-x-2'>
                            {/* {email &&
                                <Tooltip title="Email">
                                    <a href={`mailto:${email}`}>
                                        <AiOutlineMail className='hover:text-primary cursor-pointer' />
                                    </a>
                                </Tooltip>
                            } */}
                            {linkedin &&
                                <Tooltip title="Linkedin">
                                    <a href={`${linkedin}`} target='_blank' rel='noreferrer'>
                                        <BiLogoLinkedin className='hover:text-primary cursor-pointer' />
                                    </a>
                                </Tooltip>
                            }

                            {fundwebsite &&
                                <Tooltip title="Website">
                                    <a href={`${fundwebsite ? fundwebsite?.startsWith("www") ? `https://${fundwebsite}` : fundwebsite : ""}` || ""} target='_blank' rel='noreferrer' onClick={handleCancel}>
                                        <TbWorldWww className='hover:text-primary cursor-pointer' />
                                    </a>
                                </Tooltip>
                            }
                        </div>
                    </div>

                    {/* <div className='text-center mt-2'>
        <div>🚀 Empowering startups to scale & innovate 💡</div>
        <div> 🔍 Seeking visionary founders with disruptive solutions</div>
        <div>🌱 Strategic investments and mentorship 📍 NYC</div>
    </div> */}

                    <div className='mt-2'>
                        {bio}
                    </div>



                    <div
                        style={{ width: '100%', display: 'flex', flexDirection: 'column', marginTop: 10 }}
                    >
                        <div style={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'space-between',
                            padding: 10,
                            borderBottom: '1px solid #E9ECEF'
                        }}>
                            {sectors &&
                                <>
                                    <div style={{ fontWeight: '600' }}>Verticals</div>
                                    <div>
                                        {sectors.split(",").map((s, i) => (
                                            <span
                                                key={i}
                                                style={{
                                                    backgroundColor: '#E9ECEF',
                                                    borderRadius: 100,
                                                    color: '#868E96',
                                                    fontSize: 12,
                                                    padding: '4px 12px'
                                                }}>{s}</span>
                                        ))}</div>
                                </>}
                        </div>

                        {/* <div style={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'space-between',
                            padding: 10,
                            borderBottom: '1px solid #E9ECEF'
                        }}>
                            <div style={{ fontWeight: '600' }}>Current Fund Size</div>
                            <div style={{ color: '#868E96' }}><span
                                style={{ fontWeight: '400', color: 'black' }}>$680K</span> / <span
                                >$1MM</span></div>
                        </div>

                        <div style={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'space-between',
                            padding: 10,
                            borderBottom: '1px solid #E9ECEF'
                        }}>
                            <div style={{ fontWeight: '600' }}>Acquisitions Over 100M</div>
                            <div style={{ fontWeight: '400' }}>2</div>
                        </div>

                        <div style={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'space-between',
                            padding: 10,
                            borderBottom: '1px solid #E9ECEF'
                        }}>
                            <div style={{ fontWeight: '600' }}>Acquisitions Over 1B</div>
                            <div style={{ fontWeight: '400' }}>None</div>
                        </div>

                        <div style={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'space-between',
                            padding: 10,
                        }}>
                            <div style={{ fontWeight: '600' }}>General Partners</div>
                            <div style={{ fontWeight: '400' }}>Hall PLC, Sullivan PLC</div>
                        </div> */}
                    </div>
                </div>
            </Modal>

        </>

    )
};

