import React from 'react';
import { Outlet } from 'react-router-dom';
import { Navbar } from '../components/Navbar/Navbar';
import { SidebarProvider } from '../hooks/use-sidebar';
import Topbar from '../components/Navbar/Topbar';

const Layout = ({showNavbar}: {showNavbar?: boolean}) => {



    return (
        <SidebarProvider>
            <Topbar />
            <div className='lg:flex'>
                {showNavbar && <Navbar />}

                <div className='bg-white rounded-2xl h-[93.5vh] w-full z-[99] shadow-[0px 10px 15px -3px rgba(0,0,0,0.1)] peer-[[data-state=open]]:ml-6 peer-[[data-state=closed]]:ml-0 peer-[[data-state=open]]:opacity-0 peer-[[data-state=closed]]:opacity-100 peer-[[data-state=closed]]:lg:opacity-100 peer-[[data-state=open]]:lg:opacity-100 transition-all'>

                    <Outlet />
                </div>
            </div>
        </SidebarProvider>
    );
};

export default Layout;