import { apiSlice } from "../../services/apiSlice";


export const userApiSlice = apiSlice.injectEndpoints({
    endpoints: builder => ({
        getUser: builder.query({
            query: (userId) => ({
                url: `users/${userId}`,
                method: 'GET',
            })
        }),
        updateUser: builder.mutation({
            query: (credentials) => ({
                url: `users/update/${credentials.userId}/`,
                method: 'PUT',
                body: credentials
            })
        }),
        updateUserProfileImg: builder.mutation({
            query: (credentials: { userId: string | undefined, formData: FormData }) => ({
                url: `users/upload-image/${credentials.userId}/`,
                method: 'POST',
                body: credentials.formData,
            })
        }),
        updateProfileImage: builder.mutation({
            query: (credentials: { userId: string | undefined }) => ({
                url: `users/image-url/${credentials.userId}/`,
                method: 'POST',
                body: {}
            })
        }),
        updateFeedback: builder.mutation({
            query: (credentials) => ({
                url: `users/submit-feedback/${credentials.userId}/`,
                method: 'POST',
                body: { feedback: credentials.feedback }
            })
        }),
        updateSubResponse: builder.mutation({
            query: (credentials) => ({
                url: `users/submit-response/${credentials.userId}/`,
                method: 'POST',
                body: { response: credentials.response }
            })
        }),
    })
})

export const {
    useGetUserQuery,
    useUpdateUserMutation,
    useUpdateUserProfileImgMutation,
    useUpdateFeedbackMutation,
    useUpdateSubResponseMutation,
    useUpdateProfileImageMutation

} = userApiSlice