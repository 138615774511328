import React from 'react'
import TeamLogo from "../../../assets/svg/Team.svg"
import EditIcon from "../../../assets/svg/Edit.svg"
import FounderImage from "../../../assets/Founders.png"
import Web from "../../../assets/svg/team/Web.svg"
import Email from "../../../assets/svg/team/Email.svg"
import Phone from "../../../assets/svg/team/Phone.svg"
import Linkedin from "../../../assets/svg/team/Linkedin.svg"
import { Button } from 'antd'
import { Form, Input } from 'antd';


function Team() {

  const profileSocial = [

    {
      id: 1,
      icon: Email,
      title: "Email",
      content: "sample@gmail.com"
    },
    {
      id: 2,
      icon: Web,
      title: "Website",
      content: "www.founders.com"
    },
    {
      id: 3,
      icon: Phone,
      title: "Phone",
      content: "(123) 456-7890"
    },
    {
      id: 4,
      icon: Linkedin,
      title: "Linkedin",
      content: "jasondoe"
    }
  ]
  return (
    <div className='lg:px-10 px-4 pt-4 w-full'>
      <section className='border-b w-full border-[#E9ECEF]'>
        <div className='flex items-center justify-between '>
          <div className='flex gap-x-6 items-center'>
            <h2 className='text-2xl font-medium'>Team</h2>
          </div>
        </div>
        <div className=' mt-5'></div>
      </section>

      <section>
        <div className='grid grid-cols-2 gap-x-4 mt-7'>

          <div >
            <div className='w-full flex justify-between items-center py-1'>
              <p>3 members</p>
              <img src={TeamLogo} alt="" />
            </div>

            <div className='overflow-y-scroll h-[70%]'>
              {/* info */}
              <div className='bg-[#F2F3F5] pt-6'>

                <div className='mx-3 bg-white px-3 py-2 rounded-md border border-[#E8E8E8] text-sm text-[#4F4F4F]'>

                  <div className='flex items-center justify-between py-2 border-b border-[#E8E8E8]'>
                    <p className='text-sm'>Your info</p>
                    <div className='flex gap-x-2 items-center'>
                      <p className='text-primary text-xs'>Edit</p>
                      <img src={EditIcon} alt="" />
                    </div>
                  </div>

                  <div className='flex items-center justify-between py-4 gap-x-3'>
                    <div className=''>
                      <img src={FounderImage} alt="" className='w-full' />
                      <Button className='w-full mt-2 py-6'>Change Profile</Button>
                    </div>

                    <div className='w-full '>
                      <div className='space-y-3'>
                        <h3 className='text-base font-medium'>Jason Doe (You)</h3>
                        <p className='text-xs'>Chief Executive Officer (CEO)</p>
                        <p className='text-xs mt-2'>Jason Doe is a visionary leader with over 15 years of experience in the industry. His strategic thinking and ability to anticipate market trends have positioned the company as a leader in its field. Jason is known for his dynamic leadership style, fostering a culture of innovation and growth. He believes in empowering his team to reach their full potential and is committed to driving the company toward sustainable success. Under his leadership, the company has expanded into new markets, doubled its revenue, and continues to break new ground in innovation.</p>
                      </div>
                      <div className='w-full grid grid-cols-2 gap-x-3 gap-y-6 mt-6'>
                        {profileSocial.map((item, index) => (
                          <div key={index} className='flex gap-x-2 items-center'>
                            <img src={item.icon} alt="" />
                            <p className='text-xs'>{item.content}</p>
                          </div>
                        ))}

                      </div>
                    </div>

                  </div>

                </div>

              </div>

              {/* member 1 */}
              <div className='bg-[#F2F3F5] pt-6'>

                <div className='mx-3 bg-white px-3 py-2 rounded-md border border-[#E8E8E8] text-sm text-[#4F4F4F]'>

                  <div className='flex items-center justify-between py-4 gap-x-3'>
                    <div className=''>
                      <img src={FounderImage} alt="" className='w-full' />
                      <Button className='w-full mt-2 py-6'>Change Profile</Button>
                    </div>

                    <div className='w-full '>
                      <div className='space-y-3'>
                        <h3 className='text-base font-medium'>Jason Doe (You)</h3>
                        <p className='text-xs'>Chief Executive Officer (CEO)</p>
                        <p className='text-xs mt-2'>Jason Doe is a visionary leader with over 15 years of experience in the industry. His strategic thinking and ability to anticipate market trends have positioned the company as a leader in its field. Jason is known for his dynamic leadership style, fostering a culture of innovation and growth. He believes in empowering his team to reach their full potential and is committed to driving the company toward sustainable success. Under his leadership, the company has expanded into new markets, doubled its revenue, and continues to break new ground in innovation.</p>
                      </div>
                      <div className='w-full grid grid-cols-2 gap-x-3 gap-y-6 mt-6'>
                        {profileSocial.map((item, index) => (
                          <div key={index} className='flex gap-x-2 items-center'>
                            <img src={item.icon} alt="" />
                            <p className='text-xs'>{item.content}</p>
                          </div>
                        ))}

                      </div>
                    </div>

                  </div>

                </div>

              </div>

              {/* member 2 */}
              <div className='bg-[#F2F3F5] pt-6'>

                <div className='mx-3 bg-white px-3 py-2 rounded-md border border-[#E8E8E8] text-sm text-[#4F4F4F]'>

                  <div className='flex items-center justify-between py-4 gap-x-3'>
                    <div className=''>
                      <img src={FounderImage} alt="" className='w-full' />
                      <Button className='w-full mt-2 py-6'>Change Profile</Button>
                    </div>

                    <div className='w-full '>
                      <div className='space-y-3'>
                        <h3 className='text-base font-medium'>Jason Doe (You)</h3>
                        <p className='text-xs'>Chief Executive Officer (CEO)</p>
                        <p className='text-xs mt-2'>Jason Doe is a visionary leader with over 15 years of experience in the industry. His strategic thinking and ability to anticipate market trends have positioned the company as a leader in its field. Jason is known for his dynamic leadership style, fostering a culture of innovation and growth. He believes in empowering his team to reach their full potential and is committed to driving the company toward sustainable success. Under his leadership, the company has expanded into new markets, doubled its revenue, and continues to break new ground in innovation.</p>
                      </div>
                      <div className='w-full grid grid-cols-2 gap-x-3 gap-y-6 mt-6'>
                        {profileSocial.map((item, index) => (
                          <div key={index} className='flex gap-x-2 items-center'>
                            <img src={item.icon} alt="" />
                            <p className='text-xs'>{item.content}</p>
                          </div>
                        ))}

                      </div>
                    </div>

                  </div>

                </div>

              </div>
            </div>


          </div>

          <div>
            <div className='py-2 rounded-md border border-[#E8E8E8] text-sm text-[#4F4F4F] mt-8'>
              <div className='border-b border-[#E8E8E8] flex items-center justify-between py-4 px-5'>
                <p className='font-medium text-sm'>Add a team member</p>
              </div>

              <div className='px-5 py-2'>
                <div>
                  <Form
                    layout="vertical"
                    className='w-full'
                  >
                    <div className='flex gap-x-1 items-end w-full'>
                      <Form.Item
                        name="name_of_start_up"
                        className='w-full'
                        label="Share link"
                        rules={[{ required: false, message: 'Please input the name of your start-up!' }]}

                      >
                        <Input addonBefore={<p>https://</p>} placeholder="Share your pitch" />
                      </Form.Item>
                      <Form.Item>
                        <Button type="text" htmlType="submit" className='bg-black text-white'>
                          Copy Link
                        </Button>
                      </Form.Item>
                    </div>
                    <div className='flex gap-x-1 items-end w-full'>
                      <Form.Item
                        name="name_of_start_up"
                        className='w-full'
                        label="Add via email"
                        rules={[{ required: false, message: 'Please input the name of your start-up!' }]}

                      >
                        <Input addonBefore={<p>Email</p>} placeholder="sample@email.com" />
                      </Form.Item>
                      <Form.Item>
                        <Button type="text" htmlType="submit" className='bg-black text-white'>
                          Add
                        </Button>
                      </Form.Item>
                    </div>
                  </Form>
                </div>
                <Button className='w-full text-primary'>Add more</Button>

              </div>

            </div>
          </div>

        </div>
      </section>
    </div>
  )
}

export default Team