import React from 'react';
import { Navigate, Outlet } from 'react-router-dom';
import { useAppSelector } from '../lib/hooks';

const ProtectedRoute = () => {
    const isAuthenticated = useAppSelector(state => state.auth.isAuthenticated);

    return isAuthenticated ? <Outlet /> : <Navigate to="/" replace />;
};

export default ProtectedRoute;