import { FC, useRef, useState } from 'react'
import account from "../../assets/svg/account.svg";
import { defaultProfile } from "../../constants/defaults";
import pen from "../../assets/svg/pen.svg";
import { Avatar, Button, Form, FormProps, Input } from 'antd';
import { userBadges } from "./config";
import { Tag } from "../Profile/components/Tag";
import { useNavigate } from "react-router-dom";
import { RootState } from '../../lib/store';
import { useAppSelector } from '../../lib/hooks';
import { unauthenticate } from '../../lib/features/auth/authSlice';
import { unSetProfile } from '../../lib/features/user/userSlice';
import { useGetUserQuery, useUpdateUserMutation, useUpdateUserProfileImgMutation } from '../../lib/features/user/userApiSlice';
import { useDispatch } from 'react-redux';
import { errorMessage, successMessage } from '../../constants/notification';

type FieldType = {
    first_name?: string;
    last_name?: string;
    email?: string;
    linkedin_profile?: string;
    founderSuperPower?: string;
};


interface IProps {
}

export const UserSetting: FC<IProps> = () => {
    const user = useAppSelector((state: RootState) => state.user)
    const [isLoading, setIsLoading] = useState(false);
    const [name] = useState(user?.first_name);
    const [email] = useState(user?.email);
    const [role] = useState(user?.religion);
    const [badges, setBadges] = useState(userBadges);
    const [avatar, setAvatar] = useState<File>();
    const [imagePath, setImagePath] = useState(user?.founder_image_url || defaultProfile);;
    const fileInputRef = useRef<HTMLInputElement>(null);
    const navigate = useNavigate();
    const dispatch = useDispatch()

    const [updateUser, { isLoading: updateUserIsLoading }] = useUpdateUserMutation()
    const { data: userData, isLoading: userDataIsLoading, refetch: userDataRefetch } = useGetUserQuery(user?.id)

    const [updateUserProfileImg] = useUpdateUserProfileImgMutation()


    const onFinish: FormProps<FieldType>["onFinish"] = async (values) => {
        // 
        try {
            updateUser({ userId: user?.id, ...values }).then((res) => {
                userDataRefetch()
                successMessage(res?.data?.message || "Information saved successfully")
            }).catch((err) => {

                errorMessage("Failed to update information")
            });
        }
        catch (e: any) {

            setIsLoading(false)
        }
    }

    const onFinishFailed: FormProps<FieldType>["onFinishFailed"] = (errorInfo) => {

    };

    const openFilePicker = () => {
        if (fileInputRef.current) {
            fileInputRef.current.click();
        }
    };

    const handleFileSelect = () => {
        if (fileInputRef.current && fileInputRef.current.files) {
            const file = fileInputRef.current.files[0];
            if (file && file.type.startsWith('image/')) {
                // Do something with the selected image file
                setAvatar(file)
                const selectedImage = document.querySelector('.selectedImage');
                const reader = new FileReader();

                reader.onload = function () {
                    if (!selectedImage) return
                    // @ts-ignore
                    selectedImage.src = reader.result;
                };

                if (file) {
                    reader.readAsDataURL(file);
                    // Display the path of the selected image

                    const formData = new FormData();
                    formData.append('file', file);

                    setImagePath(URL.createObjectURL(file));

                    updateUserProfileImg({ userId: user?.id, formData }).then((res: any) => {
                        userDataRefetch();
                        successMessage("Profile Image updated successfully");
                    }).catch((err) => {
                        errorMessage("Failed to update profile image");
                    });
                }
            }
        }
    };

    return (
        <div className='flex flex-col items-center h-full overflow-y-auto' >
            <div className='lg:p-8 p-4 lg:w-4/5 w-full' style={{
                fontSize: 24,
                borderBottom: '1px solid #E9ECEF',
            }}>Settings
            </div>

            <div
                style={{
                    display: 'flex',
                    flexDirection: 'column',
                    borderBottom: '1px solid #E9ECEF',

                }} className='flex flex-collg:p-6 p-4 lg:w-4/5 w-full'
            >
                <div style={{
                    display: 'flex',
                    alignItems: 'center',
                }}>
                    <div style={{ marginRight: 10 }}><img src={account} alt={'recommendations'} />
                    </div>
                    <div style={{ fontSize: 18, marginBottom: 5 }}>Account</div>
                </div>
            </div>

            <div className='w-full lg:w-4/5 p-4 lg:p-6'>
                <Form
                    layout="vertical"
                    style={{ width: '100%', alignSelf: 'flex-start', marginTop: 0 }}
                    initialValues={{ email: user?.email, first_name: user?.first_name, last_name: user?.last_name, linkedin_profile: user?.linkedin_profile, bio: user?.bio, bio_link: user?.bio_link }}
                    onFinish={onFinish}
                    onFinishFailed={onFinishFailed}
                    autoComplete="off"
                >

                    <div style={{ position: 'relative', cursor: 'pointer' }} onClick={openFilePicker}>
                        <div>Avatar</div>
                        <input
                            accept="image/*"
                            ref={fileInputRef}
                            type={'file'}
                            style={{ display: 'none' }}
                            onChange={handleFileSelect}
                        />

                        <Avatar className={'selectedImage'} size={96} src={imagePath || user?.founder_image_url || defaultProfile}
                            style={{ margin: '20px 0' }} />
                        <img src={pen} alt={'pen'}
                            style={{ position: 'absolute', zIndex: 2, left: 70, bottom: 20 }} />
                    </div>

                    <div className='grid md:grid-cols-2 grid-cols-1 gap-x-4'>
                        <Form.Item<FieldType>
                            hasFeedback
                            label="First Name"
                            name="first_name"
                            rules={[{ required: true, message: 'Please input your first name!' }]}
                        >
                            <Input placeholder="First Name" />
                        </Form.Item>
                        <Form.Item<FieldType>
                            hasFeedback
                            label="Last Name"
                            name="last_name"

                            rules={[{ required: true, message: 'Please input your last name!' }]}
                        >
                            <Input placeholder="Last Name" />
                        </Form.Item>
                    </div>

                    <Form.Item<FieldType>
                        hasFeedback
                        label="LinkedIn Profile"
                        name="linkedin_profile"
                        rules={[{ required: true, message: 'Whats your linkedIn profile?' }]}
                    >
                        <Input placeholder="LinkedIn profile" />
                    </Form.Item>

                    <Form.Item
                        name="personal_website"
                        label="Personal Website"
                    >
                        <Input placeholder="Personal Website" />
                    </Form.Item>

                    <Form.Item
                        name={'email'}
                        hasFeedback
                        label="Email">
                        <Input disabled />
                    </Form.Item>

                    {/* <div>Founder profile</div>
                    <div style={{
                        display: 'flex',
                        alignItems: 'center',
                        gap: 5,
                        flexWrap: 'wrap',
                        marginTop: 20,
                        marginBottom: 20
                    }}>
                        {generateTags().founderProfile}
                    </div> */}

                    <Form.Item
                        name="bio"
                        label="Bio(s)"
                    >
                        <Input.TextArea placeholder="Bio" />
                    </Form.Item>

                    <Form.Item
                        name="bio_link"
                        label="Bio Link"
                    >
                        <Input placeholder="Bio Link" />
                    </Form.Item>


                    {/* <div style={{ marginTop: 25, paddingBottom: 10 }}>
                        <div style={{ fontSize: 14, color: '#495057', fontWeight: '500' }}>
                            Optional Demographic Information Form
                        </div>
                        <div style={{ fontSize: 14, color: '#868E96', marginTop: 5, marginBottom: 10 }}>
                            Filling the form is entirely optional, but useful for generating the best matches.
                        </div>

                        <img
                            src={fillForm}
                            alt={'fillForm'}
                            style={{ cursor: 'pointer' }}
                            onClick={() => {
                                navigate('/settings')
                            }} />
                    </div> */}
                    <Button
                        onClick={() => {
                            dispatch(unauthenticate())
                            dispatch(unSetProfile())
                            navigate('/')
                        }}
                        type="dashed"
                        style={{
                            width: 90,
                            borderRadius: 100,
                            marginTop: 10,
                            float: 'left'
                        }}
                        htmlType="submit"
                    >
                        Logout
                    </Button>

                    <Form.Item>
                        <Button
                            disabled={updateUserIsLoading}
                            loading={updateUserIsLoading}
                            type="primary"
                            style={{
                                backgroundColor: '#228BE6',
                                width: 90,
                                borderRadius: 100,
                                marginTop: 10,
                                float: 'right'
                            }}
                            htmlType="submit"
                        >
                            Save
                        </Button>
                    </Form.Item>
                </Form>
            </div>
        </div>
    )
};

