import React, { useState, useCallback } from 'react';
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { useAppSelector } from '../../../lib/hooks';
import { RootState } from '../../../lib/store';
import { defaultProfile } from '../../../constants/defaults';

interface Item {
    id: string;
    content: string;
    image?: string
}

// fake data generator
const getItems = (count: number, offset = 0): Item[] =>
    Array.from({ length: count }, (v, k) => k).map(k => ({
        id: `item-${k + offset}`,
        content: `Zero One Hundred`
    }));

// a little function to help with reordering the result
const reorder = (list: Item[], startIndex: number, endIndex: number): Item[] => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);
    return result;
};

/**
 * Moves an item from one list to another list.
 */
const move = (
    source: Item[],
    destination: Item[],
    droppableSource: { index: number; droppableId: string },
    droppableDestination: { index: number; droppableId: string }
) => {
    const sourceClone = Array.from(source);
    const destClone = Array.from(destination);
    const [removed] = sourceClone.splice(droppableSource.index, 1);
    destClone.splice(droppableDestination.index, 0, removed);

    const result: { [key: string]: Item[] } = {};
    result[droppableSource.droppableId] = sourceClone;
    result[droppableDestination.droppableId] = destClone;

    return result;
};

const grid = 8;

const getItemStyle = (isDragging: boolean, draggableStyle: any): React.CSSProperties => ({
    userSelect: 'none',
    padding: grid * 2,
    margin: `0 0 ${grid}px 0`,
    background: isDragging ? 'lightgreen' : 'white',
    ...draggableStyle
});

const getListStyle = (isDraggingOver: boolean): React.CSSProperties => ({
    background: isDraggingOver ? '#C9C9CA' : '#F4F4F5',
    padding: grid,
    width: 250
});

function Dnd() {
    const userRecommendations = useAppSelector((state: RootState) => state.user.previous_offered_vcs)

    const itemsList: Item[] = Object.values(userRecommendations!!).map((key, index) => {
        return {
            id: index.toString(),
            content: key.Name,
            image: key.metadata?.ProfilepicturesinS3 || defaultProfile
        }
    })
    const [items, setItems] = useState(itemsList);
    const [selected, setSelected] = useState<Item[]>([]);
    const [inProgress, setInProgress] = useState<Item[]>([]);  // New column
    const [completed, setCompleted] = useState<Item[]>([]);    // New column

    const id2List = {
        droppable: 'items',
        droppable2: 'selected',
        droppable3: 'inProgress',   // New column
        droppable4: 'completed'     // New column
    };
    console.log(Object.values(userRecommendations!!))

    const getList = useCallback(
        (id: any) => {
            switch (id) {
                case 'droppable':
                    return items;
                case 'droppable2':
                    return selected;
                case 'droppable3':
                    return inProgress;   // New column
                case 'droppable4':
                    return completed;    // New column
                default:
                    return [];
            }
        },
        [items, selected, inProgress, completed]
    );

    const onDragEnd = (result: any) => {
        const { source, destination } = result;

        if (!destination) {
            return;
        }

        if (source.droppableId === destination.droppableId) {
            const reorderedItems = reorder(
                getList(source.droppableId),
                source.index,
                destination.index
            );

            if (source.droppableId === 'droppable') {
                setItems(reorderedItems);
            } else if (source.droppableId === 'droppable2') {
                setSelected(reorderedItems);
            } else if (source.droppableId === 'droppable3') {
                setInProgress(reorderedItems);  // New column
            } else {
                setCompleted(reorderedItems);   // New column
            }
        } else {
            const movedResult = move(
                getList(source.droppableId),
                getList(destination.droppableId),
                source,
                destination
            );

            setItems(movedResult.droppable || items);
            setSelected(movedResult.droppable2 || selected);
            setInProgress(movedResult.droppable3 || inProgress);  // New column
            setCompleted(movedResult.droppable4 || completed);    // New column
        }
    };

    return (
        <DragDropContext onDragEnd={onDragEnd}>
            <div className='flex gap-x-6'>
                {/* First column */}
                <Droppable droppableId="droppable">
                    {(provided: any, snapshot: any) => (
                        <div
                            ref={provided.innerRef}
                            style={getListStyle(snapshot.isDraggingOver)}
                            className='!p-5 rounded-lg'
                        >
                            <h3 className='mb-5 text-base'>AI Matches</h3>
                            {items.map((item, index) => (
                                <Draggable key={item.id} draggableId={item.id} index={index}>
                                    {(provided: any, snapshot: any) => (
                                        <div
                                            ref={provided.innerRef}
                                            {...provided.draggableProps}
                                            {...provided.dragHandleProps}
                                            style={getItemStyle(
                                                snapshot.isDragging,
                                                provided.draggableProps.style
                                            )}
                                            className='rounded-md'
                                        >
                                            <div className='flex flex-col gap-y-2'>
                                                <div className='flex items-center justify-end'>
                                                    <span className='bg-[#9DFF9D] text-[#1A5B20] py-0.5 px-1 rounded-md'>76%</span>
                                                </div>
                                                <div className='flex items-center gap-x-2'>
                                                    <img src={item.image} alt="" className='w-6 h-6 rounded-full' />
                                                    {item.content}
                                                </div>
                                            </div>
                                        </div>
                                    )}
                                </Draggable>
                            ))}
                            {provided.placeholder}
                        </div>
                    )}
                </Droppable>

                {/* Second column */}
                <Droppable droppableId="droppable2">
                    {(provided: any, snapshot: any) => (
                        <div
                            ref={provided.innerRef}
                            style={getListStyle(snapshot.isDraggingOver)}
                            className='!p-5 rounded-lg'
                        >
                            <h3 className='mb-5 text-base'>Shortlist</h3>
                            {selected.map((item, index) => (
                                <Draggable key={item.id} draggableId={item.id} index={index}>
                                    {(provided: any, snapshot: any) => (
                                        <div
                                            ref={provided.innerRef}
                                            {...provided.draggableProps}
                                            {...provided.dragHandleProps}
                                            style={getItemStyle(
                                                snapshot.isDragging,
                                                provided.draggableProps.style
                                            )}
                                            className='rounded-md'
                                        >
                                            <div className='flex flex-col gap-y-2'>
                                                <div className='flex items-center justify-end'>
                                                    <span className='bg-[#9DFF9D] text-[#1A5B20] py-0.5 px-1 rounded-md'>76%</span>
                                                </div>
                                                <div className='flex items-center gap-x-2'>
                                                    <img src={item.image} alt="" className='w-6 h-6 rounded-full' />
                                                    {item.content}
                                                </div>
                                            </div>
                                        </div>
                                    )}
                                </Draggable>
                            ))}
                            {provided.placeholder}
                        </div>
                    )}
                </Droppable>

                {/* Third column - In Progress */}
                <Droppable droppableId="droppable3">
                    {(provided: any, snapshot: any) => (
                        <div
                            ref={provided.innerRef}
                            style={getListStyle(snapshot.isDraggingOver)}
                            className='!p-5 rounded-lg'
                        >
                            <h3 className='mb-5 text-base'>Reached Out</h3>
                            {inProgress.map((item, index) => (
                                <Draggable key={item.id} draggableId={item.id} index={index}>
                                    {(provided: any, snapshot: any) => (
                                        <div
                                            ref={provided.innerRef}
                                            {...provided.draggableProps}
                                            {...provided.dragHandleProps}
                                            style={getItemStyle(
                                                snapshot.isDragging,
                                                provided.draggableProps.style
                                            )}
                                            className='rounded-md'
                                        >
                                            <div className='flex flex-col gap-y-2'>
                                                <div className='flex items-center justify-end'>
                                                    <span className='bg-[#9DFF9D] text-[#1A5B20] py-0.5 px-1 rounded-md'>76%</span>
                                                </div>
                                                <div className='flex items-center gap-x-2'>
                                                    <img src={item.image} alt="" className='w-6 h-6 rounded-full' />
                                                    {item.content}
                                                </div>
                                            </div>
                                        </div>
                                    )}
                                </Draggable>
                            ))}
                            {provided.placeholder}
                        </div>
                    )}
                </Droppable>

                {/* Fourth column - Completed */}
                <Droppable droppableId="droppable4">
                    {(provided: any, snapshot: any) => (
                        <div
                            ref={provided.innerRef}
                            style={getListStyle(snapshot.isDraggingOver)}
                            className='!p-5 rounded-lg'
                        >
                            <h3 className='mb-5 text-base'>Q&A</h3>
                            {completed.map((item, index) => (
                                <Draggable key={item.id} draggableId={item.id} index={index}>
                                    {(provided: any, snapshot: any) => (
                                        <div
                                            ref={provided.innerRef}
                                            {...provided.draggableProps}
                                            {...provided.dragHandleProps}
                                            style={getItemStyle(
                                                snapshot.isDragging,
                                                provided.draggableProps.style
                                            )}
                                            className='rounded-md'
                                        >
                                            <div className='flex flex-col gap-y-2'>
                                                <div className='flex items-center justify-end'>
                                                    <span className='bg-[#9DFF9D] text-[#1A5B20] py-0.5 px-1 rounded-md'>76%</span>
                                                </div>
                                                <div className='flex items-center gap-x-2'>
                                                    <img src={item.image} alt="" className='w-6 h-6 rounded-full' />
                                                    {item.content}
                                                </div>
                                            </div>
                                        </div>
                                    )}
                                </Draggable>
                            ))}
                            {provided.placeholder}
                        </div>
                    )}
                </Droppable>
            </div>
        </DragDropContext>
    );
}

export default Dnd;
