// import { IUser } from "../../../@types/types";
import { createSlice } from '@reduxjs/toolkit'


interface AuthInterface {
    isAuthenticated: boolean;
    isLoading: boolean;
    access: string | null;
    refresh: string | null;
}



// export interface IState {
//     user: IUser | undefined,
//     auth: AuthInterface | undefined
// }


const authInitialState = {
    isAuthenticated: false,
    isLoading: false,
    access: null,
    refresh: null
} as AuthInterface;

const authSlice = createSlice({
    name: 'auth',
    initialState: authInitialState,
    reducers: {
        authenticate: (state, action) => {
            state.isAuthenticated = true;
            state.isLoading = false;
            state.access = action.payload.AccessToken;
            state.refresh = action.payload.refresh;
        },
        unauthenticate: state => {
            state.isAuthenticated = false;
            state.isLoading = false;
            state.access = null;
            state.refresh = null;
        },
        completeLoading: state => {
            state.isLoading = false;
        }

    },
    
})


export const { authenticate, unauthenticate, completeLoading } = authSlice.actions
export default authSlice.reducer