import React, { useCallback, useEffect, useMemo, useState } from 'react'
import Overview from './Profiletabs/Overview'
import { Information } from './Profiletabs/Information';
import PitchDeck from './Profiletabs/PitchDeck';
import ExecutiveSummary from './Profiletabs/ExecutiveSummary';
import Team from './Profiletabs/Team';
import FundRaising from './Profiletabs/FundRaising';
import { Switch } from 'antd';

function MainProfile() {

  const [hash, setHash] = useState("");

  useEffect(() => {
    // Optional: Add an event listener for hash changes
    const handleHashChange = () => {
      setHash(window.location.hash.substring(1) || "Overview"); // Default to "Overview" if the hash is empty
    };

    window.addEventListener("hashchange", handleHashChange);

    // Clean up event listener on component unmount
    return () => {
      window.removeEventListener("hashchange", handleHashChange);
    };
  }, []);

  const isActive = useCallback(
    (value: string) => {
      return hash === value;
    },
    [hash],
  )

  // const isActive = (value: string) => {
  //   return hash === value
  // }

  return (
    <div className='h-full overflow-y-scroll'
    >
      {/*TITLE*/}
      <div className='lg:p-5 p-4 border-b border-[#E9ECEF] text-2xl bg-white fixed w-full z-50'>Profile
      </div>



      <div className='px-11 pt-[85px] flex gap-x-16'>


        <div className='w-[150px] space-y-2 fixed flex flex-col gap-y-2'>
          <p onClick={() => setHash("")} className={`cursor-pointer ${isActive("Overview") || isActive("") ? 'text-primary' : ''}`}>Overview</p>
          <p onClick={() => setHash("Information")} className={`cursor-pointer ${isActive("Information") ? 'text-primary' : ''}`}>Information</p>
          <p onClick={() => setHash("Pitch-Deck")} className={`cursor-pointer ${isActive("Pitch-Deck") ? 'text-primary' : ''}`}>Pitch Deck</p>
          <p onClick={() => setHash("Executive-Summary")} className={`cursor-pointer ${isActive("Executive-Summary") ? 'text-primary' : ''}`}>Executive Summary</p>
          <p onClick={() => setHash("Fund-Raising")} className={`cursor-pointer ${isActive("Fund-Raising") ? 'text-primary' : ''}`}>Fund Raising</p>
          <p onClick={() => setHash("Overview")} className={`cursor-pointer ${isActive("Overview") ? 'text-primary' : ''}`}>Traction</p>
          <p onClick={() => setHash("Team")} className={`cursor-pointer ${isActive("Team") ? 'text-primary' : ''}`}>Team</p>
          <p onClick={() => setHash("Overview")} className={`cursor-pointer ${isActive("Overview") ? 'text-primary' : ''}`}>Attachments</p>
        </div>

        <div className='pl-[200px] w-full'>
          {isActive("Overview") || isActive("") ? <Overview setHash={setHash} /> : null}
          {isActive("Information") ? <Information /> : null}
          {isActive("Pitch-Deck") ? <PitchDeck /> : null}
          {isActive("Executive-Summary") ? <ExecutiveSummary /> : null}
          {isActive("Fund-Raising") ? <FundRaising /> : null}
          {/* {isActive("Traction") ? <div>Traction</div> : null} */}
          {isActive("Team") ? <Team /> : null}
          {/* {isActive("Attachments") ? <div>Attachments</div> : null} */}
        </div>

      </div>




    </div>
  )
}

export default MainProfile
