import React from 'react'
import Web from "../../../assets/svg/Web.svg"
import Info from "../../../assets/svg/Info.svg"
import Logo from "../../../assets/svg/logo.svg"
import { Form, Input, Select, Button, Upload } from 'antd';
import {
    GetCountries
} from "react-country-state-city";
import { Country } from '../tabs/demographicsInfo'
import Flag from "../../../assets/svg/summary/Flag.svg"
import Bulb from "../../../assets/svg/summary/Bulb.svg"
import Team from "../../../assets/team.png"
import Market from "../../../assets/svg/summary/Market.svg"
import UploadIcon from "../../../assets/svg/Upload.svg"
import Competitive from "../../../assets/svg/summary/Competitive.svg"
import Why from "../../../assets/svg/summary/Why.svg"
import type { UploadProps } from 'antd';
import { useAppSelector } from '../../../lib/hooks'
import { RootState } from '../../../lib/store'
import { shallowEqual } from 'react-redux'
import { useGetPitchDeckMutation } from '../../../lib/features/pitch_deck/pitchDeck'
import { errorMessage } from '../../../constants/notification'
import { DocumentViewer } from 'react-documents';


const { Option } = Select;

const { Dragger } = Upload;

const props: UploadProps = {
    name: 'file',
    multiple: true,
    action: 'https://660d2bd96ddfa2943b33731c.mockapi.io/api/upload',
};


function Overview({ setHash }: { setHash: any }) {
    const startupData = useAppSelector((state: RootState) => state.startup, shallowEqual)
    const userData = useAppSelector((state: RootState) => state.user, shallowEqual)

    const [getPitchDeck] = useGetPitchDeckMutation()

    const [pitchDeckFile, setPitchDeckFile] = React.useState<string>("")
    React.useEffect(() => {
        if (userData?.pitch_deck_object_key) {
            getPitchDeck({ user_id: userData?.id, object_key: userData?.pitch_deck_object_key }).then((res) => {
                console.log(res.data.file_path)
                setPitchDeckFile(res.data.file_path)
            }).catch((err) => {
                setPitchDeckFile("")
                errorMessage("Failed to get pitch deck")
            })
        }
    }, [getPitchDeck, userData?.id, userData?.pitch_deck_object_key])


    const summary: any[] = [
        {
            id: 1,
            title: "Problem",
            icon: Flag,
            content: startupData.problem,
            assistant: ["Elite mentors' importance?", "Challenges without guidance?", "Impact on long-term success?"],

        },
        {
            id: 2,
            title: "Solution",
            icon: Bulb,
            content: startupData.solution,
            assistant: ["AI mentoring effectiveness?", "AI vs. human mentors?", "AI for early/late-stage?"],
        },
        {
            id: 3,
            title: "Market",
            icon: Market,
            content: startupData.market,
            assistant: ["Focus on SMBs?", "Defining diverse founders?", "Reaching global SMBs?"],
        },
        {
            id: 4,
            title: "Competitive advantage",
            icon: Competitive,
            content: startupData.competitive_advantages,
            assistant: ["Elite mentors' importance?", "Challenges without guidance?", "Impact on long-term success?"],
        },
        {
            id: 5,
            title: "Why is this a good investment case?",
            icon: Why,
            content: startupData.good_investment_case,
            assistant: ["Elite mentors' importance?", "Challenges without guidance?", "Impact on long-term success?"],
        },
    ]


    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const tags = ["United States", "Enterprise Software", "AI", "Community", "B2B2C", "Software"]

    const value = window.location.hash.substring(1);
    console.log(value, "value")

    const [form] = Form.useForm();
    const [countriesList, setCountriesList] = React.useState<Country[]>([]);



    React.useEffect(() => {
        GetCountries().then((result: any) => {
            setCountriesList(result);
        });
    }, []);

    const CountEmptyItems = React.memo(({ list }: { list: string[] }) => {
        const countEmptyItems = list.filter((item: string) => item === "").length;
        return countEmptyItems > 0 ? (
            <div className='flex gap-x-1'>
                <img src={Info} alt="" />
                <p className='text-[#FF434E]'>{countEmptyItems} missing elements</p>
            </div>
        ) : null;
    });




    const initialValues = {
        name_of_start_up: startupData?.name_of_start_up || "",
        about_start_up: startupData?.about_start_up || "",
        country: userData?.country || "",
    }
    return (
            <div>

                {/* Overview */}
                <section className='border-b w-full border-[#E9ECEF]'>
                    <h2 className='text-xl font-medium'>{startupData?.name_of_start_up || ""}</h2>

                    {/* <div className='flex gap-x-1 mt-3'>

                    {tags.map((tag, index) => (
                        <div className='rounded-full px-5 py-2 border border-[#B5B5B5] text-sm text-[#4F4F4F]'>
                            {tag}
                        </div>
                    ))}

                </div>

                <div className='rounded-full px-5 py-2 border border-primary text-sm text-primary mt-3 w-max flex gap-x-2'>
                    <img src={Add} alt="" />
                    Add more
                </div> */}
                    {userData.personal_website &&
                        <div className='flex gap-x-2 mt-3'>
                            <img src={Web} alt="" />
                            <p className='text-primary text-bas'>{userData?.personal_website}</p>
                        </div>
                    }


                    <div className=' mt-5'></div>
                </section>

                {/* Information */}
                <section className='my-7 border-b w-full border-[#E9ECEF]'>
                    <div className='flex items-center justify-between '>
                        <div className='flex gap-x-3 items-center'>
                            <h2 className='text-xl font-medium'>Information</h2>
                            <CountEmptyItems list={[startupData?.name_of_start_up || "", startupData?.about_start_up || "", userData?.country || "",]} />
                        </div>

                        <Button size="large" className='text-primary px-8 py-3.5' onClick={() => { setHash("Information") }}>
                            View
                        </Button>
                    </div>

                    <div className='mt-2.5'>
                        <div className='w-full p-6 border border-[#E8E8E8] rounded-md shadow-sm shadow-black/10 '>
                            <Form
                                className='w-full'
                                form={form}
                                layout="vertical"
                                onFinish={() => { }}
                                initialValues={initialValues}

                            >

                                <div className='flex gap-x-5'>
                                    <div className='py-7 px-12 border border-[#CBD5E1]'>
                                        <img src={Logo} alt="" className='w-[100px] h-[100px] rounded-full' />
                                    </div>

                                    <div className='w-full'>


                                        <div className="w-full grid grid-cols-2 gap-x-5">
                                            <Form.Item
                                                name="name_of_start_up"
                                                label="Startup Name"

                                            >
                                                <Input placeholder="Name of Start-Up" disabled />
                                            </Form.Item>

                                            <Form.Item
                                                name="country"
                                                label="Country"
                                                rules={[{ required: true }]}

                                            >
                                                <Select placeholder="Select Country"
                                                    onChange={(e) => {
                                                    }}
                                                    disabled
                                                >
                                                    <Option value={""} >Select country</Option>
                                                    {countriesList.map((country: any, index: number) => (
                                                        <Option key={index} value={country?.id}>{country?.name}</Option>
                                                    ))}
                                                </Select>
                                            </Form.Item>

                                            <Form.Item
                                                name="name_of_start_up"
                                                label="Website"
                                                rules={[{ required: true, message: 'Please input the name of your start-up!' }]}

                                            >
                                                <Input addonBefore={<p>https://</p>} placeholder="Website" disabled />
                                            </Form.Item>

                                            <Form.Item
                                                name="currency"
                                                label="Currency"
                                                rules={[{ required: true }]}

                                            >
                                                <Select placeholder="Select Currency"
                                                    onChange={(e) => {

                                                    }}
                                                    disabled
                                                >
                                                    <Option value={"USDollar"} >Select country</Option>
                                                    {["USDollar"].map((curr: any, index: number) => (
                                                        <Option key={index} value={curr}>{curr}</Option>
                                                    ))}
                                                </Select>
                                            </Form.Item>
                                        </div>

                                    </div>
                                </div>


                                <div className='mt-4'>
                                    <Form.Item
                                        name="about_start_up"
                                        label="One-liner"

                                        rules={[{ required: false, message: 'Please input the name of your start-up!' }]}

                                    >
                                        <Input placeholder="Generative AI-Powered mentorship and networking platform for entrepreneurs." disabled />
                                    </Form.Item>
                                </div>
                            </Form>
                        </div>


                    </div>
                    <div className=' mt-5'></div>
                </section>

                {/* Pitch deck */}
                <section className='mt-7 border-b w-full border-[#E9ECEF]'>
                    <div className='flex gap-x-3 items-center justify-between'>
                        <h2 className='text-xl font-medium'>Pitch Deck</h2>
                        <Button size="large" className='text-primary px-8 py-3.5' onClick={() => { setHash("Pitch-Deck") }}>
                            View
                        </Button>
                    </div>

                    <div className='mt-7 grid grid-cols-2 gap-x-4'>

                        <div className='w-full p-6 border border-[#E8E8E8] rounded-md shadow-sm shadow-black/10 max-h-[290px] overflow-y-auto'>
                            {pitchDeckFile !== "" ?
                                <DocumentViewer
                                    url={pitchDeckFile}
                                    viewer="url"
                                />
                                :
                                <div className='flex items-center justify-center h-full'>
                                    <p className='text-2xl font-bold'>No pitch deck uploaded yet</p>
                                </div>
                            }
                        </div>

                        <div className='flex flex-col gap-y-3'>
                            <div className='w-full py-4 px-5 border border-[#E8E8E8] rounded-md shadow-sm shadow-black/10 '>
                                <Form
                                    layout="vertical"
                                    className='w-full'
                                    initialValues={{
                                        share_pitch: pitchDeckFile
                                    }}
                                >
                                    <div className='flex gap-x-1 items-end w-full'>
                                        <Form.Item
                                            name="share_pitch"
                                            className='w-full'
                                            label="Share your pitch"
                                            rules={[{ required: false, message: 'Please input the name of your start-up!' }]}

                                        >
                                            <Input placeholder=""  disabled />
                                            {/* <Input addonBefore={<p>https://</p>} placeholder="Share your pitch" defaultValue={pitchDeckFile} /> */}
                                        </Form.Item>
                                        <Form.Item>
                                            <Button type="text" htmlType="submit" className='bg-black text-white'>
                                                Copy Link
                                            </Button>
                                        </Form.Item>
                                    </div>
                                </Form>
                            </div>

                            <div className='w-full p-6 border border-[#E8E8E8] rounded-md shadow-sm shadow-black/10'>
                                <p className='text-sm font-normal'>Views</p>
                                <p className='text-6xl text-[#5FA3F0] font-bold mt-4'>0</p>
                            </div>



                        </div>

                    </div>
                    <div className=' mt-5'></div>
                </section>

                {/* Executive Summary */}
                <section className='my-7 border-b w-full border-[#E9ECEF]'>
                    <div className='flex items-center justify-between '>
                        <div className='flex gap-x-3 items-center'>
                            <h2 className='text-xl font-medium'>Executive Summary</h2>
                            <CountEmptyItems list={[startupData?.problem || "", startupData?.solution || "", startupData?.market || "", startupData?.competitive_advantages || "", startupData?.good_investment_case || ""]} />
                        </div>
                        <Button size="large" className='text-primary px-8 py-3.5' onClick={() => { setHash("Executive-Summary") }}>
                            View
                        </Button>
                    </div>

                    <div className='mt-8 w-full p-6 border border-[#E8E8E8] rounded-md shadow-sm shadow-black/10 space-y-6 '>
                        {summary.map((item, index) => (
                            <div className='flex flex-col gap-y-3' key={index}>
                                <div className='flex gap-x-2 items-center'>
                                    <img src={item.icon} alt="" />
                                    <p className='text-sm font-normal'>{item.title}</p>
                                </div>
                                <div className='border px-3 py-2 rounded-md border-[#E8E8E8] text-sm text-[#4F4F4F]'>
                                    <p className='text-sm font-normal'>{item.content}</p>
                                </div>
                                <div className=' mt-5 mx-16 border-b'></div>
                            </div>
                        ))}
                    </div>


                    <div className=' mt-5'></div>
                </section>

                {/* Fund Raising */}
                <section className='mt-7 border-b w-full border-[#E9ECEF]'>
                    <div className='flex gap-x-3 items-center justify-between'>
                        <h2 className='text-xl font-medium'>Fund Raising</h2>
                        <Button size="large" className='text-primary px-8 py-3.5' onClick={() => { setHash("Fund-Raising") }}>
                            View
                        </Button>
                    </div>

                    <div className='mt-7 gap-x-4'>

                        <div className='w-full p-6 border border-[#E8E8E8] rounded-md shadow-sm shadow-black/10 flex gap-x-3'>

                            <div className='p-6 border border-[#E8E8E8] rounded-md shadow-sm shadow-black/10 flex items-center gap-x-5 w-3/6'>
                                <div>
                                    <p className='text-sm font-normal'>Used of funds</p>
                                    <p className='text-base text-[#5FA3F0] font-bold mt-4'>{startupData.use_of_fund || 0}</p>
                                </div>
                                <div>
                                    <p className='text-base'>Product Development <br /> 100%</p>
                                </div>

                            </div>

                            <div className='p-6 border border-[#E8E8E8] rounded-md shadow-sm shadow-black/10 flex flex-col justify-center gap-y-5 w-1/6'>
                                <p className='text-xs'>Current Round</p>
                                <p className='text-sm text-[#5FA3F0] font-bold'>{startupData.product_stages || "-"}</p>
                            </div>

                            <div className='p-6 border border-[#E8E8E8] rounded-md shadow-sm shadow-black/10 flex flex-col justify-center gap-y-5 w-1/6'>
                                <p className='text-xs'>Capital seeking</p>
                                <p className='text-sm text-[#5FA3F0] font-bold'>{startupData.capital_seeking || 0}</p>
                            </div>

                            <div className='p-6 border border-[#E8E8E8] rounded-md shadow-sm shadow-black/10 flex flex-col justify-center gap-y-5 w-1/6'>
                                <p className='text-xs'>Pre-money valuation</p>
                                <p className='text-sm text-[#5FA3F0] font-bold'>{startupData.pre_money_valuation || 0}</p>
                            </div>

                            <div className='p-6 border border-[#E8E8E8] rounded-md shadow-sm shadow-black/10 flex flex-col justify-center gap-y-5 w-1/6'>
                                <p className='text-xs'>Already raised of the round</p>
                                <p className='text-sm text-[#5FA3F0] font-bold'>$0</p>
                            </div>

                        </div>


                    </div>
                    <div className=' mt-5'></div>
                </section>

                {/* Traction */}
                <section className='mt-7 border-b w-full border-[#E9ECEF]'>
                    <div className='flex gap-x-3 items-center justify-between'>
                        <h2 className='text-xl font-medium'>Traction</h2>
                        <Button size="large" className='text-primary px-8 py-3.5'>
                            View
                        </Button>
                    </div>

                    <div className='mt-7 gap-x-4'>

                        <div className='w-full p-6 border border-[#E8E8E8] rounded-md shadow-sm shadow-black/10 flex gap-x-3 justify-between'>

                            <div className='w-full p-6 border border-[#E8E8E8] rounded-md shadow-sm shadow-black/10 flex flex-col justify-center gap-y-5'>
                                <p className='text-xs'>MRR</p>
                                <p className='text-sm text-[#5FA3F0] font-bold'>{startupData.monthly_recurring_revenue || 0}</p>
                            </div>

                            <div className='w-full p-6 border border-[#E8E8E8] rounded-md shadow-sm shadow-black/10 flex flex-col justify-center gap-y-5'>
                                <p className='text-xs'>Private funds raised before current round</p>
                                <p className='text-sm text-[#5FA3F0] font-bold'>{startupData.private_fund_raised_bcr || 0}</p>
                            </div>

                            <div className='w-full p-6 border border-[#E8E8E8] rounded-md shadow-sm shadow-black/10 flex flex-col justify-center gap-y-5'>
                                <p className='text-xs'>Public funds raised before current round</p>
                                <p className='text-sm text-[#5FA3F0] font-bold'>{startupData.public_fund_raised_bcr || 0}</p>
                            </div>

                        </div>


                    </div>
                    <div className=' mt-5'></div>
                </section>

                {/* Team */}
                <section className='mt-7 border-b w-full border-[#E9ECEF]'>
                    <div className='flex gap-x-3 items-center justify-between'>
                        <h2 className='text-xl font-medium'>Team</h2>
                        <Button size="large" className='text-primary px-8 py-3.5' onClick={() => { setHash("Team") }}>
                            View
                        </Button>
                    </div>

                    <div className='mt-7 gap-x-4'>

                        <div className='w-full p-6 border border-[#E8E8E8] rounded-md shadow-sm shadow-black/10'>
                            <h3 className='text-xl font-medium'>Team Members</h3>

                            <div className='mt-6'>
                                <div className='flex justify-between items-center'>
                                    <div className='flex gap-x-3 items-center'>
                                        <img src={Team} alt="" />
                                        <div>
                                            <p className='text-base font-normal'>Jason Doe (You)</p>
                                            <p className='text-xs'>Chief Executive Officer (CEO)</p>
                                            <p className='text-xs'>A visionary leader with a passion for innovation, steering the company towards growth and success.</p>
                                        </div>
                                    </div>
                                    <div className='py-2 px-9 border border-[#E8E8E8] rounded-md shadow-sm shadow-black/10'>
                                        <p className='text-sm'>Owner</p>
                                    </div>
                                </div>

                            </div>



                        </div>


                    </div>
                    <div className=' mt-5'></div>
                </section>


                {/* Attachment */}
                <section className='mt-7 border-b w-full border-[#E9ECEF]'>
                    <div className='flex gap-x-3 items-center justify-between'>
                        <h2 className='text-xl font-medium'>Attachments</h2>
                        <Button size="large" className='text-primary px-8 py-3.5'>
                            View
                        </Button>
                    </div>

                    <div className='mt-7 grid grid-cols-2 gap-x-4'>

                        <div className='w-full p-6 border border-[#E8E8E8] rounded-md shadow-sm shadow-black/10 '>
                            <Dragger {...props}>
                                <div className='flex items-center justify-center mb-2'>
                                    <img src={UploadIcon} alt="" />
                                </div>
                                <p className="ant-upload-hint">
                                    Upload or Drag and drop your files here
                                </p>
                            </Dragger>
                        </div>

                        <div className='flex flex-col gap-y-3'>
                            <div className='w-full py-4 px-5 border border-[#E8E8E8] rounded-md shadow-sm shadow-black/10 '>
                                <Form
                                    layout="vertical"
                                    className='w-full'
                                >
                                    <div className='flex gap-x-1 items-end w-full'>
                                        <Form.Item
                                            name="name_of_start_up"
                                            className='w-full'
                                            label="Add External link"

                                        >
                                            <Input addonBefore={<p>https://</p>} placeholder="" />
                                        </Form.Item>
                                        <Form.Item>
                                            <Button type="text" htmlType="submit" className='bg-black text-white'>
                                                Add Link
                                            </Button>
                                        </Form.Item>
                                    </div>
                                    <div className='w-full'>
                                        <Form.Item>
                                            <Button type="text" htmlType="submit" className='bg-[#5FA3F0] text-white w-full'>
                                                Add more
                                            </Button>
                                        </Form.Item>
                                    </div>
                                </Form>
                            </div>
                        </div>

                    </div>
                    <div className=' mt-5'></div>
                </section>


            </div>
    )
}

export default Overview