import React, { FC, useCallback, useState } from 'react'
import handshake from '../../assets/svg/handshake.svg'
import { InvestorCard } from "./components/InvestorCard";
import { shallowEqual, useSelector } from "react-redux";
import { defaultProfile } from "../../constants/defaults";
import { Alert } from 'antd';
import { Link } from 'react-router-dom';
import { Modal, Button, Timeline } from "antd";
import { RootState } from '../../lib/store';
import { useSemanticMutation } from '../../lib/features/auth/authApiSlice';
import { useGetUserQuery, useUpdateFeedbackMutation, useUpdateSubResponseMutation } from '../../lib/features/user/userApiSlice';
import { useAppSelector } from '../../lib/hooks';
import { infoMessage, successMessage } from '../../constants/notification';
import { toast } from 'react-toastify';
import Kofi from "../../assets/KofiAmpadu.jpg"
import Shashi from "../../assets/ShashiSrikantan.jpg"
import Fabian from "../../assets/Fabian.png"
import { calculatePercentage } from '../../utils/calculatePercentage';

const dummyReason: string[] = [
    "The VC fund is an excellent match for your startup as it aligns perfectly with your current stage and sector. The fund's focus on fertility tech and digital health solutions complements your platform's mission to provide a patient-centered, cost-saving, and hassle-free fertility clinic. Furthermore, the shared educational background and geographical proximity between you and the VC enhance the potential for a strong, collaborative relationship.",

    "Fabian is an excellent match for you as a venture capitalist due to several compelling reasons. Firstly, Fabian is offering a stage that matches your startup's requirements, ensuring that your application receives the appropriate level of support and investment at this critical early stage. Additionally, the sector of Fabian's fund, aligns perfectly with your startup's sector, providing a strong foundation for mutual growth and success. The fund's alignment with your startup's profile description further underscores its suitability, as Fabian's expertise in healthcare and life sciences will be invaluable in driving the patient-centered, cost-saving, and hassle-free solutions your platform offers. Lastly, the recommendation for Fabian as a VC aligns with the shared cultural background between you two, fostering a deeper connection and understanding that can enhance collaboration and trust. These factors collectively make Fabian Ronisky a highly suitable venture capitalist for your startup.",

    "Shashi Srikantan is an excellent match for you due to several key factors. Firstly, the stage of investment that Shashi offers aligns perfectly with the requirements of your startup, ensuring that you receive the appropriate level of support and resources. Additionally, the sector focus of Shashi's fund is in harmony with your startup's sector, which means that she has a deep understanding of the industry and can provide valuable insights and guidance. Lastly, the fund is highly recommended because it aligns well with your startup's profile description, making it a strategic fit for your business needs. Shashi's extensive experience and background further reinforce her capability to support your innovative application."
]

interface IProps {
}

interface TimelineInterface {
    children: string
}

export function capitalizeFirstLetter(word: string) {
    if (word.length === 0) return word; // Handle empty string
    return word.charAt(0).toUpperCase() + word.slice(1);
}



export const Recommendation: FC<IProps> = () => {

    const userRecommendations = useSelector((state: RootState) => state.user.previous_offered_vcs)
    const userRecommendationsInvestors = useSelector((state: RootState) => state.user.previous_offered_investors)
    const user = useAppSelector((state: RootState) => state.user, shallowEqual)
    const startupData = useAppSelector((state: RootState) => state.startup, shallowEqual)
    console.log("Investors", userRecommendations)
    // const accessToken = useAppSelector((state: RootState) => state.auth.access)
    // const [tutorialModal, setTutorialModal] = useState<boolean>(!user?.watched_tutorial)

    // console.log("user waatched tutorial", user?.watched_tutorial)
    // const [updateUser] = useUpdateUserMutation()

    // const videoRef = useRef<HTMLVideoElement | null>(null);

    // useEffect(() => {
    //     if (!tutorialModal && videoRef.current) {
    //         videoRef.current.pause(); // Pause the video when modal is closed
    //     }
    // }, [tutorialModal]);



    const calculateNonEmptyPercentage = useCallback(() => {
        const val = calculatePercentage(user, startupData)
        return val
    }, [startupData, user])


    const [isModalOpen, setIsModalOpen] = useState(false);
    const [isFeedbackModalOpen, setIsFeedbackModalOpen] = useState(false);

    const [recommendationCard, setRecommendationCard] = useState<any>(null)

    const showModal = () => {
        setIsModalOpen(true);
    };

    const handleOk = () => {
        setIsModalOpen(false)
    };

    const handleCancel = () => {
        setIsModalOpen(false);
    };

    const showFeedbackModal = () => {
        setIsFeedbackModalOpen(true);
    };

    const handleFeedbackOk = () => {
        setIsFeedbackModalOpen(false)
    };

    const handleFeedbackCancel = () => {
        setIsFeedbackModalOpen(false);
    };

    const [navigateToAsk, setNavigateToAsk] = React.useState(false)
    const [feedback, setFeedback] = React.useState("")





    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const { isLoading: userDataIsLoading, refetch: userDataRefetch } = useGetUserQuery(user?.id)

    const semanticNotif = [`Searching for (${capitalizeFirstLetter(startupData?.product_stages || "")}) stage investors`, `Getting ${user.race} Venture Capitalists`, `Finding VCs in ${capitalizeFirstLetter(user?.state || "")}`, `Searching for investors that are focused on ${capitalizeFirstLetter(startupData?.industry || "")}`, "Searching for investors willing to lead."]

    const [semanticNofifList, setSemanticNofitList] = React.useState(semanticNotif)
    const [timelineItems, setTimelineItems] = useState<TimelineInterface[] | []>([]);
    const [pendingNotification, setPendingNotification] = useState("");

    const [semantic, { isLoading: getSemanticLoading }] = useSemanticMutation()
    const [updateSubResponse, { isLoading: updateSubResponseLoading }] = useUpdateSubResponseMutation()
    const [updateFeedback, { isLoading: updateFeedbackLoading }] = useUpdateFeedbackMutation()

    const getSemantic = () => {
        let semanticCompleted = false;
        if (userRecommendations && Object.keys(userRecommendations).length >= 50 && user.sub_response === "") {
            showModal()
        } else {
            const toastId = toast.loading("Getting recommendations");

            try {
                semantic({ user_id: user?.id || '', startup_id: startupData?.id || "" }).then((res: any) => {
                    semanticCompleted = true;
                    if (res.error) {
                        throw new Error(res.error?.data?.detail || "Failed to fetch recommendations")

                    }
                    if ((res.data instanceof Object) && Object.keys(res.data).length === 0) {
                        semanticCompleted = true;
                        // infoMessage("No recommendations retrieved, Please make sure your profile data is 100% completed.");
                        toast.update(toastId, { render: "No recommendations retrieved, Please make sure your profile data is 100% completed.", type: "info", isLoading: false, autoClose: 4000 })
                        return
                    }

                    // 
                    userDataRefetch()
                    // successMessage("Personalized recommendations retrieved")
                    toast.update(toastId, { render: "Personalized recommendations retrieved", type: "success", isLoading: false, autoClose: 4000 })
                    setTimelineItems([])

                    setTimeout(() => {
                        setNavigateToAsk(true)
                    }, 3000)

                }).catch((err) => {
                    semanticCompleted = true;
                    // infoMessage(err.toString() || "Failed to fetch recommendations")
                    toast.update(toastId, { render: err.toString() || "Failed to fetch recommendations", type: "error", isLoading: false, autoClose: 4000 })

                })
            } catch (err: any) {
                infoMessage(err)
            }

            if (!semanticCompleted) {
                const updateTimeline = (index: any) => {
                    if (index < semanticNofifList.length) {
                        if (!timelineItems.some(timelineItem => timelineItem.children === semanticNofifList[index])) {
                            setPendingNotification(
                                semanticNofifList[(index + 1) % semanticNofifList.length]
                            );
                            setSemanticNofitList((prevList) => {
                                const newList = prevList.filter((_, i) => i !== index);
                                return newList;
                            });
                            setTimelineItems((prevItems) => [
                                ...prevItems,
                                { children: semanticNofifList[index] }
                            ]);


                        }
                    }
                };

                const intervals = [0, 4000, 8000, 12000, 14000, 18000];
                intervals.forEach((delay, indexInterval) => {
                    setTimeout(() => updateTimeline(indexInterval), delay);
                });
            }

        }


    }

    const handleSubResponse = (res: any) => {

        updateSubResponse({ response: res, userId: user.id })
            .then(() => {
                handleOk()
                showFeedbackModal()
                userDataRefetch()
                successMessage("Subscription response received")
            })
            .catch(() => { })

    }

    function handleSubmitFeedback(e: any) {
        e.preventDefault()
        updateFeedback({ userId: user.id, feedback: feedback }).then(() => { userDataRefetch(); handleFeedbackOk(); successMessage(`Thank you, ${user?.first_name}, for your feedback! We appreciate your feedback `) }).catch(() => { })

    }



    return (
        <div className='overflow-y-auto h-full'
        >
            {/* <div style={{
                fontSize: 24,
                fontWeight: '500',
                marginTop: 32,
                paddingBottom: 32,
                borderBottom: '1px solid #DEE2E6',
            }} className='lg:pl-8 pl-4'>
                <p>Welcome to FounderTribes.</p>
                {parseInt(calculateNonEmptyPercentage()) < 90 &&
                    <p className=''>The world of personalized investor recommendations! Click <span onClick={showPitchDeckModal} className='text-blue-500 underline cursor-pointer'>here</span> to get started</p>
                }
            </div> */}


            <div className='flex flex-col lg:p-8 p-4' >
                <div className='flex flex-col lg:flex-row lg:items-center lg:justify-between lg:gap-y-0 gap-y-3 border-b pb-5'>
                    <div>
                        <div className='flex items-center gap-1.5 w-full' >
                            <div><img src={handshake} alt={'handshake'} /></div>
                            <div style={{ fontSize: 18 }}>Investor Recommendations</div>
                        </div>
                        {parseInt(calculateNonEmptyPercentage()) >= 90 &&
                            <p>Number of recommendations: {userRecommendationsInvestors && userRecommendations && Object.keys(userRecommendations).length > 50 ? 50 : userRecommendationsInvestors && userRecommendations && Object.keys(userRecommendations).length + Object.keys(userRecommendationsInvestors).length}</p>
                        }
                    </div>

                    {parseInt(calculateNonEmptyPercentage()) >= 90 &&
                        <Button type="primary" size="large" className='lg:rounded-full' onClick={getSemantic} loading={getSemanticLoading || userDataIsLoading} >
                            Get {userRecommendations && Object.keys(userRecommendations).length >= 10 && "more"} Recommendations
                        </Button>
                    }

                </div>
                {
                    getSemanticLoading &&
                    // <div className='flex items-center justify-center h-[50vh]'>
                    <div className='mt-6'>
                        <div>
                            <Timeline className='text-2xl font-bold'
                                pending={pendingNotification}
                                reverse={false}
                                items={timelineItems}
                            />
                        </div>
                    </div>

                }
                {navigateToAsk &&
                    <div>
                        <Alert
                            message="Informational Notes"
                            description={
                                <div>
                                    We're not done supporting you yet! Click <Link className='underline text-blue-600' to="/AskAnything">here</Link> to get help evaluating your pitch deck or drafting your blurb and investor email.
                                </div>
                            }
                            type="info"
                            showIcon
                            closable
                            onClose={() => setNavigateToAsk(false)}
                        />
                    </div>
                }

                {/* userRecommendationsInvestors */}




                {
                    userRecommendations && Object.keys(userRecommendations).length > 0 ? (
                        <div className='grid xl:grid-cols-3 md:grid-cols-2 mt-4' >
                            {Object.values(userRecommendations).sort((a, b) => b.Score - a.Score).slice(0, 50).map((key, index) => {

                                const recommendation = key;
                                return (
                                    <InvestorCard
                                        key={index}
                                        index={index}
                                        // avatar={defaultProfile}
                                        avatar={recommendation?.metadata?.ProfilepicturesinS3 || defaultProfile}
                                        Reason={recommendation.Reason}
                                        name={recommendation.Name}
                                        fund={recommendation.FundName}
                                        stages={recommendation?.metadata?.Stageofinvestment}
                                        sectors={recommendation?.metadata?.Sector}
                                        email={recommendation?.metadata?.Email}
                                        matchLevel={recommendation?.Score.toString()}
                                        bio={recommendation?.metadata?.Longerbio}
                                        fundwebsite={recommendation?.metadata?.Fundwebsite}
                                        linkedin={recommendation?.metadata?.LinkedInprofile}
                                        loading={getSemanticLoading || userDataIsLoading}
                                        active={true}
                                        setRecommendationCard={setRecommendationCard}
                                        recommendationCard={recommendationCard}
                                    />
                                );
                            })}
                            {userRecommendationsInvestors && Object.values(userRecommendationsInvestors)!.sort((a, b) => b.Score - a.Score).slice(0, 50).map((key, index) => {

                                const recommendation = key;
                                return (
                                    <InvestorCard
                                        key={index - Object.keys(userRecommendations).length}
                                        index={index - Object.keys(userRecommendations).length}
                                        // avatar={defaultProfile}
                                        tag={true}
                                        avatar={recommendation?.metadata?.ProfilepictureinS3 || defaultProfile}
                                        Reason={recommendation.Reason}
                                        name={recommendation.Name}
                                        fund={recommendation.FundName}
                                        stages={recommendation?.metadata?.Stageofinvestment}
                                        sectors={recommendation?.metadata?.Sector}
                                        email={recommendation?.metadata?.Email}
                                        matchLevel={recommendation?.Score.toString()}
                                        bio={recommendation?.metadata?.Longerbio}
                                        fundwebsite={recommendation?.metadata?.Fundwebsite}
                                        linkedin={recommendation?.metadata?.LinkedInprofile}
                                        loading={getSemanticLoading || userDataIsLoading}
                                        active={true}
                                        setRecommendationCard={setRecommendationCard}
                                        recommendationCard={recommendationCard}
                                    />
                                );
                            })}

                        </div>
                    ) :

                        (
                            <>
                                <div className='grid xl:grid-cols-3 md:grid-cols-2 mt-4'>
                                    <InvestorCard
                                        key={-3000}
                                        index={-3000}
                                        avatar={Kofi}
                                        name={"Kofi Ampadu"}
                                        fund={"a16z (partner at TxO)"}
                                        stages={"Pre-seed, seed"}
                                        sectors={""}
                                        Reason={dummyReason[0]}
                                        email={'N/A'}
                                        matchLevel={"5"}
                                        bio={" Kofi Ampadu, a Partner at Andreessen Horowitz (a16z) and the Head of the Talent x Opportunity Initiative (TxO), plays a pivotal role in driving innovation and supporting visionary entrepreneurs. With a focus on fostering diversity and inclusion in the entrepreneurial landscape, Kofi Ampadu leads the TxO program, which collaborates with promising companies and founders to develop products and services inspired by unique cultural insights. As the Head of TxO at Andreessen Horowitz, Kofi Ampadu works closely with founders to address significant challenges, ranging from wellness through video games to delivering affordable internet service to underserved communities. His commitment to empowering entrepreneurs and promoting innovative solutions underscores his dedication to driving positive change and supporting underrepresented voices in the tech and startup ecosystem."}
                                        fundwebsite={"https://a16z.com"}
                                        linkedin={"https://www.linkedin.com/in/kofiampadu/"}
                                        loading={false}
                                        setRecommendationCard={setRecommendationCard}


                                    />
                                    <InvestorCard
                                        key={-2000}
                                        index={-2000}
                                        avatar={Fabian}
                                        Reason={dummyReason[1]}
                                        name={"Fabián Ronisky"}
                                        fund={"L'Attitude Ventures"}
                                        sectors={""}
                                        email={'N/A'}
                                        matchLevel={"5"}
                                        bio={"20"}
                                        fundwebsite={"https://lattitudeventures.com"}
                                        linkedin={"https://www.linkedin.com/in/fabianronisky/"}
                                        loading={false}
                                        setRecommendationCard={setRecommendationCard}


                                    />

                                    <InvestorCard
                                        key={-1000}
                                        index={-1000}
                                        avatar={Shashi}
                                        name={"Shashi Srikantan"}
                                        fund={"Level Up Ventures"}
                                        Reason={dummyReason[2]}
                                        stages={"Seed, Series A"}
                                        sectors={""}
                                        email={'shashi101@aol.com'}
                                        matchLevel={"5"}
                                        bio={"20"}
                                        fundwebsite={"https://levelupventures.com"}
                                        linkedin={"https://www.linkedin.com/in/shashi-srikantan/overlay/about-this-profile/"}
                                        loading={false}
                                        setRecommendationCard={setRecommendationCard}


                                    />
                                </div>
                                {/* {userCompany &&
                                    <div className='w-full flex justify-center justify-items mt-6'>
                                        <Button type="primary" size="large" shape="round" onClick={getSemantic} loading={getSemanticLoading} disabled={getSemanticLoading || userDataIsLoading} >
                                            Get Personalized Recommendations
                                        </Button>
                                    </div>

                                } */}
                            </>
                        )
                }



            </div>

            {/* Tutorial Modal Video */}
            {/* <Modal
                okText="Done"
                cancelText="Skip"
                // cancelButtonProps={{ style: { display: 'none' } }}
                // okButtonProps={{ style: { display: 'none' } }}
                open={tutorialModal} onOk={handleOkTutorialModalClose} onCancel={handleOkTutorialModalClose}
                className='lg:!w-1/2 !w-full'
            >
                <section className="bg-white">
                    <div className="py-8 px-4 mx-auto max-w-screen-xl lg:py-16 lg:px-6">
                        <video controls autoPlay className='w-full h-full' ref={videoRef}>
                            <source src={TutorialVideo} type="video/mp4" />
                        </video>
                    </div>
                </section>

            </Modal> */}

            {/* Pitch Deck Modal */}
            {/* <Modal
                cancelButtonProps={{ style: { display: 'none' } }}
                okButtonProps={{ style: { display: 'none' } }}
                open={pitchDeckModal} onOk={handleOkPitchDeckModalClose} onCancel={handleOkPitchDeckModalClose}
                className='lg:!w-1/2 !w-full'
            >
                <section className="bg-white">
                    <div className="py-8 px-4 mx-auto max-w-screen-xl lg:py-16 lg:px-6">
                        <h1 className='text-center text-2xl font-bold pb-8'>How would you like to get started?</h1>
                        <div className="space-y-8 lg:grid lg:grid-cols-2 sm:gap-6 xl:gap-10 lg:space-y-0">
                            <div className="flex flex-col p-6 mx-auto max-w-lg text-center text-gray-900 bg-white rounded-lg border border-gray-100 shadow  xl:p-8">
                                <h3 className="mb-4 text-2xl font-semibold">Upload your pitch deck</h3>
                                <p className="font-light text-gray-500 sm:text-lg">Upload your pitch deck to get personalized recommendations. (Recommended)</p>
                                <div className="flex justify-center items-baseline my-8">
                                    <Upload {...props}>
                                        <Button icon={<UploadOutlined />}>Click to Upload</Button>
                                    </Upload>

                                </div>

                            </div>

                            <div className="flex flex-col p-6 mx-auto max-w-lg text-center text-gray-900 bg-white rounded-lg border border-gray-100 shadow  xl:p-8">
                                <h3 className="mb-4 text-2xl font-semibold">Enter Information Manually</h3>
                                <p className="font-light text-gray-500 sm:text-lg">Enter your information manually to get personalized recommendations.</p>
                                <div className="flex justify-center items-baseline my-8">
                                    <Link to={"/profile"} className='border border-gray-300 px-5 py-1.5 rounded-md text-sm font-normal text-gray-900 hover:bg-gray-100 focus:outline-none focus:ring-4 focus:ring-primary focus:ring-offset-2 focus:border-primary hover:border-primary'>
                                        Enter manually
                                    </Link>
                                </div>

                            </div>

                        </div>
                    </div>
                </section>

            </Modal> */}


            {/* Recommedation Limit Modal */}
            <Modal
                cancelButtonProps={{ style: { display: 'none' } }}
                okButtonProps={{ style: { display: 'none' } }}
                open={isModalOpen} onOk={handleOk} onCancel={handleCancel}
                className='lg:!w-1/2 !w-full'
            >
                <section className="bg-white">
                    <div className="py-8 px-4 mx-auto max-w-screen-xl lg:py-16 lg:px-6">
                        <div className="mx-auto max-w-screen-md text-center mb-8 lg:mb-12">
                            <h2 className="mb-4 text-4xl tracking-tight font-extrabold text-gray-900 ">Recommendations Limit</h2>
                            <p className="mb-2 font-light text-gray-500 sm:text-xl">Thanks for testing our MVP! We've limited the results in the MVP to 15 investor profiles, but we have curated a database of more than 2400 investors (and growing!) who have invested in underrepresented founders in the US. We're planning to launch our commercial beta in October 2024, which will also include notifications to deadlines re: accelerators, non-dilutive funding and relevant events, in addition to updates about new and emerging funds. Would you like to sign up for our waitlist? Click here</p>

                            <p className="mb-2 font-light text-gray-500 sm:text-xl">Would you be willing to pay $50 a month or $500 a year to access unlimited access to funds, accelerators, grants and events personally tailored to you and your start-up?</p>
                        </div>
                        <div className='flex items-center justify-center w-full mb-2 space-x-3'>
                            <Button
                                disabled={updateSubResponseLoading}
                                loading={updateSubResponseLoading}
                                type="primary"
                                style={{ backgroundColor: '#228BE6', marginTop: 10 }}
                                onClick={() => { handleSubResponse("Yes") }}
                            >
                                Yes
                            </Button>
                            <Button
                                disabled={updateSubResponseLoading}
                                loading={updateSubResponseLoading}
                                type="default"
                                style={{ marginTop: 10 }}
                                onClick={() => { handleSubResponse("No") }}
                                className='bg-gray-300'
                            >
                                No
                            </Button>
                        </div>
                        <div className="space-y-8 lg:grid lg:grid-cols-1 sm:gap-6 xl:gap-10 lg:space-y-0">
                            <div className="flex flex-col p-6 mx-auto max-w-lg text-center text-gray-900 bg-white rounded-lg border border-gray-100 shadow  xl:p-8">
                                <h3 className="mb-4 text-2xl font-semibold">Founder's plan</h3>
                                <p className="font-light text-gray-500 sm:text-lg">Best option for continuous list of recommendations.</p>
                                <div className="flex justify-center items-baseline my-8">
                                    <span className="mr-2 text-5xl font-extrabold">$50</span>
                                    <span className="text-gray-500">/month</span>
                                </div>
                                <p>OR</p>
                                <div className="flex justify-center items-baseline my-8">
                                    <span className="mr-2 text-5xl font-extrabold">$600</span>
                                    <span className="text-gray-500">/year</span>
                                </div>
                                <ul className="mb-8 space-y-4 text-left">
                                    <li className="flex items-center space-x-3">
                                        <svg className="flex-shrink-0 w-5 h-5 text-green-500" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z" clip-rule="evenodd"></path></svg>
                                        <span>Unlimited Funds</span>
                                    </li>
                                    <li className="flex items-center space-x-3">
                                        <svg className="flex-shrink-0 w-5 h-5 text-green-500" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z" clip-rule="evenodd"></path></svg>
                                        <span>Unlimited Accelerators</span>
                                    </li>
                                    <li className="flex items-center space-x-3">
                                        <svg className="flex-shrink-0 w-5 h-5 text-green-500" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z" clip-rule="evenodd"></path></svg>
                                        {/* <span>Team size: <span className="font-semibold">1 developer</span></span> */}
                                        <span>Unlimited Grants</span>
                                    </li>
                                    {/* <li className="flex items-center space-x-3">

                                        <svg className="flex-shrink-0 w-5 h-5 text-green-500" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z" clip-rule="evenodd"></path></svg>
                                        <span>Premium support: <span className="font-semibold">6 months</span></span>
                                    </li> */}
                                </ul>
                                {/* <p>Are you willing to pay this fee to get a continuous list of relevant funds, accelerators, grants, etc. and warm intros to matched investors?
                                </p>
                                <div className='mt-2 space-x-3'>
                                    <button className="text-white hover:text-black bg-primary hover:bg-primary/50 focus:ring-4 focus:ring-primary font-medium rounded-lg text-sm px-5 py-2.5 text-center">Yes</button>
                                    <button className="text-black hover:text-white bg-gray-300 hover:bg-gray-300/50 focus:ring-4 focus:ring-gray-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center">No</button>
                                </div> */}
                            </div>

                        </div>
                    </div>
                </section>

            </Modal>

            {/* Feedback Modal */}
            <Modal
                cancelButtonProps={{ style: { display: 'none' } }}
                okButtonProps={{ style: { display: 'none' } }}
                open={isFeedbackModalOpen} onOk={handleFeedbackOk} onCancel={handleFeedbackCancel}
                className='lg:!w-1/2 !w-full'
            >
                <section className="bg-white">
                    <div className="py-8 px-4 mx-auto max-w-screen-xl lg:py-16 lg:px-6">
                        <div className="mx-auto max-w-screen-md text-center mb-8 lg:mb-12">
                            <h2 className="mb-4 text-4xl tracking-tight font-extrabold text-gray-900 ">Feedback</h2>
                            <p className="mb-2 font-light text-gray-500 sm:text-xl">Leave us a feedback.</p>
                        </div>
                        <form className="space-y-6" onSubmit={handleSubmitFeedback}>
                            <div className="mb-6">
                                <textarea value={feedback} onChange={(e) => setFeedback(e.target.value)} rows={5} name="name" className="mt-1 p-2 block w-full border border-gray-300 rounded-md focus:ring-primary focus:border-primary focus:outline-none" required />
                            </div>
                            <div className='flex items-center justify-center w-full mb-2 space-x-3'>

                                <Button
                                    disabled={updateFeedbackLoading}
                                    loading={updateFeedbackLoading}
                                    type="primary"
                                    style={{ backgroundColor: '#228BE6', width: '100%', marginTop: 10 }}
                                    htmlType="submit"
                                >
                                    Submit
                                </Button>

                            </div>
                        </form>


                    </div>
                </section>

            </Modal>
        </div>
    )
};

