import { combineReducers, configureStore } from '@reduxjs/toolkit';
import { persistReducer, persistStore } from "redux-persist";
import { apiSlice } from './services/apiSlice';
import authReducer from './features/auth/authSlice';

import storage from './storage';
import userReducer from './features/user/userSlice';
import startupReducer from './features/startup/startupSlice';
import conversationReducer from './features/conversation/conversationSlice';


const persistConfig = {
    key: "@#founderTribes",
    storage,
    whitelist: ["auth", "user", "startup", "conversation"],
};

const rootReducer = combineReducers({
    [apiSlice.reducerPath]: apiSlice.reducer,
    auth: authReducer,
    user: userReducer,
    startup: startupReducer,
    conversation: conversationReducer


});

const persistedReducer = persistReducer(persistConfig, rootReducer);


export const store = configureStore({
    reducer: persistedReducer,
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware({
            serializableCheck: false
            // serializableCheck: {
            //     ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
            // },
        }).concat(apiSlice.middleware),
    devTools: true,
});

export type RootState = ReturnType<(typeof store)['getState']>;
export type AppDispatch = (typeof store)['dispatch'];
export const persistor = persistStore(store);