import React from 'react'
import { useSidebar } from '../../hooks/use-sidebar'
import logo from '../../assets/svg/founderTribes.svg'
import { CgMenuRightAlt, CgClose } from "react-icons/cg";
import { Tooltip } from 'antd';


function Topbar() {
    const { toggleSidebar, isSidebarOpen, isLoading } = useSidebar()

    return (
        <div className='mb-3 top-5  z-[99] flex items-center justify-between gap-x-5' >
            <div className='pl-3'>
                <img src={logo} alt={'Founders Tribes Logo'} />
            </div>
            <Tooltip title="Menu" placement='left'>
                <div onClick={() => {

                    toggleSidebar()
                }} className='cursor-pointer'>
                    <CgMenuRightAlt className={`w-7 h-7 ${isSidebarOpen && !isLoading ? 'hidden' : 'block'} transition-all`} />
                    <CgClose className={`w-7 h-7 ${isSidebarOpen && !isLoading ? 'block' : 'hidden'} transition-all`} />
                </div>
            </Tooltip>
        </div>
    )
}

export default Topbar
