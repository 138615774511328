import React, {FC} from 'react'
import avatar from '../../../assets/svg/avatar4.svg'
import link from '../../../assets/svg/link.svg'
import gary from '../../../assets/svg/garyTag.svg'
import {useSelector} from "react-redux";
import { RootState } from '../../../lib/store';
import { garySvg, manuelaSvg, valentinaSvg } from '../../../api';
import { chatPersonas } from '../../../@types/types';

interface IProps {
    persona: any
}

const ChatPersonaAvatars = {
    Gary: garySvg,
    Manuela: manuelaSvg,
    Valentina: valentinaSvg,
};

export const Connection: FC<IProps> = ({persona} : {persona: chatPersonas}) => {
    const user = useSelector((state: RootState) => state.user)

    return (
        <div
            style={{
                display:         'flex',
                alignItems:      'center',
                gap:             4,
                padding:         '2px 6px',
                borderRadius:    100,
                backgroundColor: '#D0EBFF'
            }}
        >
            <div style={{
                display:         'flex',
                alignItems:      'center',
                gap:             4,
                backgroundColor: 'white',
                borderRadius:    100,
                padding:         '4px 8px'
            }}>
                <img src={user?.founder_image_url || avatar} alt={'avatar'} className='w-6 h-6 rounded-full'/>
                <div style={{marginLeft: 2, fontSize: 14, fontWeight: '500'}}>{user?.first_name}</div>
            </div>

            <div>
                <img src={link} alt={'link'} style={{marginTop: 5}}/>
            </div>

            <div>
                <img src={ChatPersonaAvatars[persona as chatPersonas]} alt={'gary'} className='w-6 h-6 rounded-full' />
            </div>
        </div>
    )
};

