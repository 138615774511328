
import React from 'react'
import { RootState } from '../../lib/store';
import { useAppSelector } from '../../lib/hooks';
import askBtn from "../../assets/svg/askBtn.svg";
import { PulseLoader } from "react-spinners";
import { ArrowRight } from 'lucide-react'
import { SearchSkeleton } from '../../components/SearchSkeleton';
import { useAiCompleteProfileMutation, useGetSingleAIConversationQuery } from '../../lib/features/ai_agent/aiAgentApiSlice';
import AIBlue from '../../assets/svg/ai-blue.svg'

function AICompleteProfile() {
    const [question, setQuestion] = React.useState('');
    const scrollableDivRef = React.useRef<HTMLDivElement | null>(null);

    const inputRef = React.useRef<HTMLInputElement | null>(null);
    const buttonRef = React.useRef<HTMLButtonElement | null>(null);

    const userId = useAppSelector((state: RootState) => state.user?.id)
    const startupId = useAppSelector((state: RootState) => state.startup?.id)

    const { data, refetch } = useGetSingleAIConversationQuery({ userID: userId })
    const [aiCompleteProfile, { isLoading: askIsLoading }] = useAiCompleteProfileMutation()

    const alphabets = ['A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J', 'K', 'L', 'M',
        'N', 'O', 'P', 'Q', 'R', 'S', 'T', 'U', 'V', 'W', 'X', 'Y', 'Z']

    const scrollToBottom = React.useCallback(() => {
        if (scrollableDivRef.current) {
            scrollableDivRef.current.scrollIntoView({
                block: 'end',
                behavior: 'smooth',

            })
        }
    }, [])

    const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {

        if (event.key === 'Enter') {
            // Trigger button click
            buttonRef.current?.click();
            scrollToBottom()
        }
    };

    React.useEffect(() => {
        if (data?.length === 0) {
            // console.log("This will come in handy")
            aiCompleteProfile({
                user_id: userId!!,
                startup_id: startupId!!,
                user_response: ""
            }).then((res) => {
                setQuestion("")
                refetch()
                scrollToBottom()
            }).catch((err) => {

            })
        }
    }, [aiCompleteProfile, data, refetch, scrollToBottom, startupId, userId])


    return (
        <div className='h-full w-full overflow-y-auto' >
            <div className='lg:p-9 flex flex-col relative p-3' >
                <div className='lg:w-[60vw] w-full lg:max-w-[60vw] mx-auto' ref={scrollableDivRef} >
                    {data?.map((item: any, index: number) => {
                        const isQuestion = item?.role === 'user'
                        const parsedConversation = !isQuestion && item.content
                        const isLastItem = index === data?.length - 1

                        // 
                        return (

                            isQuestion ?
                                <div key={index}>
                                    <div className='text-[18px] mt-10 w-full flex justify-end'>
                                        <div className='flex items-center gap-x-1'>
                                            <p>{item.content.USER}</p>
                                            <div className='border border-gray-500 rounded-full p-0.5 flex items-center justify-center'>
                                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 256 256" fill="currentColor" className="size-4"><path d="M230.92 212c-15.23-26.33-38.7-45.21-66.09-54.16a72 72 0 1 0-73.66 0c-27.39 8.94-50.86 27.82-66.09 54.16a8 8 0 1 0 13.85 8c18.84-32.56 52.14-52 89.07-52s70.23 19.44 89.07 52a8 8 0 1 0 13.85-8ZM72 96a56 56 0 1 1 56 56 56.06 56.06 0 0 1-56-56Z"></path></svg>
                                            </div>
                                        </div>
                                    </div>
                                </div> :

                                <div className='mt-5' key={index}>
                                    {/* <Sources sources={parsedConversation.sources ?? []} /> */}
                                    <div style={{
                                        display: 'flex',
                                        alignItems: 'center',
                                        marginTop: 20,
                                    }}>
                                        <h2 className="flex items-center leading-none py-2 text-lg font-semibold">
                                            <img src={AIBlue} alt="" />
                                            {/* <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="lucide lucide-book-check mr-1.5 text-muted-foreground"><path d="M4 19.5v-15A2.5 2.5 0 0 1 6.5 2H20v20H6.5a2.5 2.5 0 0 1 0-5H20"></path><path d="m9 9.5 2 2 4-4"></path>
                                            </svg> */}

                                        </h2>
                                    </div>

                                    <p className='mt-2 font-medium'>{parsedConversation?.AI}</p>
                                    {
                                        parsedConversation?.options.length > 0 && (
                                            <>
                                                <div className='mt-2 flex items-center gap-2.5' >
                                                    <h2 className="flex items-center leading-none py-2 text-lg font-semibold">
                                                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="lucide lucide-repeat2 mr-1.5 text-muted-foreground"><path d="m2 9 3-3 3 3"></path><path d="M13 18H7a2 2 0 0 1-2-2V6"></path><path d="m22 15-3 3-3-3"></path><path d="M11 6h6a2 2 0 0 1 2 2v10"></path>
                                                        </svg>
                                                        Choose Options
                                                    </h2>
                                                </div>
                                                <div className='grid grid-cols-2 gap-2'>
                                                    {parsedConversation?.options.map((option: any, index: number) =>
                                                        <div className="" key={index}>

                                                            <button
                                                                className="flex w-fit items-center gap-x-1 border border-gray-500 rounded-lg hover:bg-foreground/5 px-3 text-accent-foreground/50"
                                                                value={option}
                                                                onClick={() => {
                                                                    scrollToBottom()
                                                                    setQuestion(option)
                                                                    aiCompleteProfile({
                                                                        user_id: userId!!,
                                                                        startup_id: startupId!!,
                                                                        user_response: option
                                                                    }).then((res) => {
                                                                        setQuestion("")
                                                                        refetch()
                                                                        scrollToBottom()
                                                                    }).catch((err) => {

                                                                    })

                                                                }}

                                                            >
                                                                <p className='font-normal text-sm whitespace-normal text-left'>{alphabets[index]}</p>
                                                                <p className='font-normal text-sm text-accent-foreground/50 whitespace-normal text-left'>{option}</p>
                                                            </button>
                                                        </div>
                                                    )}
                                                </div>
                                            </>
                                        )
                                    }


                                    <div className='mt-2 flex items-center gap-2.5' >
                                        <h2 className="flex items-center leading-none py-2 text-lg font-semibold">
                                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="lucide lucide-repeat2 mr-1.5 text-muted-foreground"><path d="m2 9 3-3 3 3"></path><path d="M13 18H7a2 2 0 0 1-2-2V6"></path><path d="m22 15-3 3-3-3"></path><path d="M11 6h6a2 2 0 0 1 2 2v10"></path>
                                            </svg>
                                            Follow up question
                                        </h2>
                                    </div>
                                    {parsedConversation?.follow_up_questions.map((rq: any, index: number) =>
                                        <div className="flex items-center w-full" key={index}>
                                            <ArrowRight className="h-4 w-4 mr-2 mt-1 flex-shrink-0 text-accent-foreground/50" />
                                            <button
                                                className="flex-1 justify-start px-0 py-1 h-fit "
                                                value={rq}
                                                onClick={() => {
                                                    scrollToBottom()
                                                    setQuestion(rq)
                                                    aiCompleteProfile({
                                                        user_id: userId!!,
                                                        startup_id: startupId!!,
                                                        user_response: rq
                                                    }).then((res) => {
                                                        setQuestion("")
                                                        refetch()
                                                        scrollToBottom()
                                                    }).catch((err) => {

                                                    })

                                                }}

                                            ><p className='font-normal text-sm text-accent-foreground/50 whitespace-normal text-left'>{rq}</p></button>
                                        </div>
                                    )}


                                    <hr className='my-6 outline outline-gray-300' />

                                    {isLastItem && !askIsLoading && question === "" &&
                                        <div style={{ width: '100%', height: '18vh' }} />
                                    }

                                </div>
                        )
                    })}

                    {question !== "" && (
                        <>
                            <div className='text-[18px] mt-10 w-full flex justify-end'>
                                <div className='flex items-center gap-x-1'>
                                    <p>{question}</p>
                                    <div className='border border-gray-500 rounded-full p-0.5 flex items-center justify-center'>
                                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 256 256" fill="currentColor" className="size-4"><path d="M230.92 212c-15.23-26.33-38.7-45.21-66.09-54.16a72 72 0 1 0-73.66 0c-27.39 8.94-50.86 27.82-66.09 54.16a8 8 0 1 0 13.85 8c18.84-32.56 52.14-52 89.07-52s70.23 19.44 89.07 52a8 8 0 1 0 13.85-8ZM72 96a56 56 0 1 1 56 56 56.06 56.06 0 0 1-56-56Z"></path></svg>
                                    </div>
                                </div>
                            </div>
                        </>
                    )}

                    {!askIsLoading &&
                        <div style={{ width: '100%', height: '18vh' }} />
                    }




                    {askIsLoading &&
                        <>
                            <SearchSkeleton />
                            <div style={{ width: '100%', height: '18vh' }} />
                        </>
                    }
                </div>

                <div className='fixed lg:w-full mt-5 lg:bottom-14 left-10 bottom-10 flex justify-center '

                >
                    <div className='lg:w-min w-full relative'>
                        <input
                            ref={inputRef}
                            onKeyDown={handleKeyDown}
                            placeholder={'Reply'}
                            value={question}
                            onChange={({ target: { value } }) => {
                                setQuestion(value)
                            }}
                            style={{
                                outline: 'none',

                                border: '1px solid #A5D8FF',
                                borderRadius: 100,
                                height: 54,
                                boxShadow: '0px 0px 8px 8px #E7F5FF',
                                paddingRight: 100,
                                paddingLeft: 20,
                                fontSize: 16,
                                color: '#495057'
                            }} className='lg:w-[68vh] w-full'
                        />

                        <div className='flex items-center absolute right-2.5 gap-1.5 top-3'>
                            {askIsLoading
                                ? <PulseLoader color="#228BE6" style={{ marginTop: 10, marginRight: 5 }} size={12} />
                                : <>
                                    {/* <div style={{ cursor: 'pointer' }}><img src={newSessionBtn} alt={'newSessionBtn'} /></div> */}
                                    <button
                                        ref={buttonRef}
                                        onClick={async () => {
                                            scrollToBottom()
                                            aiCompleteProfile({
                                                user_id: userId!!,
                                                startup_id: startupId!!,
                                                user_response: question
                                            }).then((res) => {
                                                setQuestion("")
                                                refetch().then(() => {
                                                    scrollToBottom()
                                                })
                                                
                                            }).catch((err) => {
                                                scrollToBottom()
                                            })
                                            scrollToBottom()

                                        }}
                                        style={{ cursor: 'pointer' }}>
                                        <img src={askBtn} alt={'askBtn'} />
                                    </button>
                                </>}
                        </div>
                    </div>
                </div>
            </div>


        </div>






    )
}

export default AICompleteProfile