import React, {FC, useState} from 'react'
import profile from "../../assets/svg/profile.svg";
import {Box, ButtonBase, Checkbox, FormControl, FormControlLabel, Radio, RadioGroup} from "@mui/material";
import {Divider} from "antd";
import {useNavigate} from "react-router-dom";

interface IProps {
}

export const Settings: FC<IProps> = () => {
    const [selectedValue, setSelectedValue] = useState('');
    const [selectedValue1, setSelectedValue1] = useState('');
    const navigate = useNavigate();

    const handleChange = (event: any) => {
        setSelectedValue(event.target.value);
    };

    const handleChange1 = (event: any) => {
        setSelectedValue1(event.target.value);
    };

    return (
        <div className='h-[95vh] overflow-y-scroll'
        >
            {/*TITLE*/}
            <div style={{
                padding:       '32px 200px',
                fontSize:      24,
                paddingBottom: 20,
                borderBottom:  '1px solid #E9ECEF',
            }}>Settings
            </div>

            <div
                style={{
                    margin:         '32px 200px',
                    paddingBottom:  32,
                    display:        'flex',
                    alignItems:     'center',
                    justifyContent: 'flex-start',
                    borderBottom:   '1px solid #E9ECEF',
                    width:          '60%',
                }}
            >
                <div style={{marginRight: 10, float: 'left'}}><img src={profile} alt={'profile'}/>
                </div>
                <div style={{fontSize: 18, marginBottom: 5,}}>Demographic Information</div>
            </div>

            <div style={{marginLeft: 200}}>
                <div style={{fontSize: 16, color: '#495057', textAlign: 'left'}}>
                    Complete your profile to help our recommendation engine find the best matches for you.
                </div>
                <div style={{fontSize: 16, color: '#868E96', marginTop: 5, marginBottom: 20}}>
                    Your information is private and only used to instruct the AI to be more useful to you.
                </div>

                <div style={{display: 'flex', flexDirection: 'column'}}>
                    <div style={{marginTop: 20}}>How would you describe your gender identity?</div>
                    <div style={{fontSize: 14, color: '#868E96'}}>Mark all that apply</div>
                    <div style={{display: 'flex', alignItems: 'center', gap: 10, flexWrap: 'wrap'}}>
                        <FormControlLabel control={<Checkbox defaultChecked/>} label="Man"/>
                        <FormControlLabel control={<Checkbox/>} label="Woman"/>
                        <FormControlLabel control={<Checkbox/>} label="Non-binary"/>
                        <FormControlLabel control={<Checkbox/>} label="I prefer to self-describe"/>
                        <FormControlLabel control={<Checkbox/>} label="I don't wish to answer"/>
                    </div>

                    <Divider/>

                    <div>How would you describe your racial/ethnic background?</div>
                    <div style={{fontSize: 14, color: '#868E96'}}>Mark all that apply</div>
                    <div style={{display: 'flex', alignItems: 'center', gap: 10, flexWrap: 'wrap'}}>
                        <FormControlLabel control={<Checkbox defaultChecked/>} label="Black of African descent"/>
                        <FormControlLabel control={<Checkbox/>} label="East Asian"/>
                        <FormControlLabel control={<Checkbox/>} label="Hispanic, Latinx or of Spanish Origin"/>
                        <FormControlLabel control={<Checkbox/>} label="Indigenous, American Indian or Alaska Native"/>
                        <FormControlLabel control={<Checkbox/>} label="Middle Eastern or North African Native"/>
                        <FormControlLabel control={<Checkbox/>} label="Hawaiian or Pacific Islander"/>
                        <FormControlLabel control={<Checkbox/>} label="South Asian"/>
                        <FormControlLabel control={<Checkbox/>} label="Southeast Asian"/>
                        <FormControlLabel control={<Checkbox/>} label="White or European"/>
                        <FormControlLabel control={<Checkbox/>} label="I prefer to self-describe"/>
                        <FormControlLabel control={<Checkbox/>} label="I don't wish to answer"/>
                    </div>

                    <Divider/>

                    <div>How would you describe your sexual orientation?</div>
                    <div style={{fontSize: 14, color: '#868E96'}}>Mark all that apply</div>
                    <div style={{display: 'flex', alignItems: 'center', gap: 10, flexWrap: 'wrap'}}>
                        <FormControlLabel control={<Checkbox/>} label="Asexual"/>
                        <FormControlLabel control={<Checkbox/>} label="Bisexual and/or pansexual"/>
                        <FormControlLabel control={<Checkbox/>} label="Gay"/>
                        <FormControlLabel control={<Checkbox defaultChecked/>} label="Heterosexual"/>
                        <FormControlLabel control={<Checkbox/>} label="Lesbian"/>
                        <FormControlLabel control={<Checkbox/>} label="Queer"/>
                        <FormControlLabel control={<Checkbox/>} label="I prefer to self-describe"/>
                        <FormControlLabel control={<Checkbox/>} label="I don't wish to answer"/>
                    </div>

                    <Divider/>

                    <div style={{marginBottom: 10}}>Do you have a disability or chronic condition
                        (physical, visual,
                        auditory, cognitive, mental, emotional or other) that substantially limits one or more of your
                        major life activities, including mobility, communication (seeing, hearing, speaking) and
                        learning?
                    </div>
                    <FormControl component="fieldset">
                        <RadioGroup value={selectedValue} onChange={handleChange}>
                            <Box display="flex" flexDirection="row">
                                <FormControlLabel value="option1" control={<Radio/>} label="Yes"/>
                                <FormControlLabel value="option2" control={<Radio/>} label="No"/>
                                <FormControlLabel value="option3" control={<Radio defaultChecked/>}
                                                  label="I prefer to self-describe"/>
                                <FormControlLabel value="option4" control={<Radio/>} label="I don't wish to answer"/>
                            </Box>
                        </RadioGroup>
                    </FormControl>

                    <Divider/>

                    <div style={{marginBottom: 10}}>What is your age?</div>
                    <FormControl component="fieldset">
                        <RadioGroup value={selectedValue1} onChange={handleChange1}>
                            <Box display="flex" flexDirection="row">
                                <FormControlLabel value="option1" control={<Radio defaultChecked/>}
                                                  label="0 - 15 years old"/>
                                <FormControlLabel value="option2" control={<Radio/>} label="15 - 30 years old"/>
                                <FormControlLabel value="option3" control={<Radio/>} label="30 - 45 years old"/>
                                <FormControlLabel value="option4" control={<Radio/>} label="45 - 60 years old"/>
                            </Box>
                        </RadioGroup>
                    </FormControl>

                </div>


                <ButtonBase
                    onClick={() => {
                        navigate('/profile')
                    }}
                    style={{
                        marginTop:       20,
                        padding:         '12px 24px',
                        color:           'white',
                        backgroundColor: '#228BE6',
                        width:           'max-content',
                        borderRadius:    100,
                        marginBottom:    40
                    }}
                >
                    <div>Save</div>
                </ButtonBase>
            </div>
        </div>
    )
};

