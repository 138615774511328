import React, {FC} from 'react';

interface IProps {
    avatarUrl: string
    size: number
}

export const Avatar: FC<IProps> = ({avatarUrl, size}) => {
    return (
        <div>
            <img src={avatarUrl} alt="User Avatar" style={{width: size, height: size, borderRadius: '50%'}}/>
        </div>
    );
};

