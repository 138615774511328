
import React, { ReactNode } from 'react'
import { useGetUserQuery } from "../../../lib/features/user/userApiSlice";
import { shallowEqual, useSelector } from "react-redux";
import { RootState } from "../../../lib/store";
import { Form, Input, Select, Button, Tooltip } from 'antd';
import { useGetStartUpQuery, useUpdateStartUpAndUserMutation } from "../../../lib/features/startup/startupApiSlice";
import { successMessage } from "../../../constants/notification";
import { IoIosInformationCircleOutline } from "react-icons/io";
import { capitalizeFirstLetter } from '../../Recommendation/Recommendation';


const { Option } = Select;

// Mock data for choices, you can replace these with actual imports
const ProductStagess = [
    { stage: "Pre-seed", tip: "Your product is in the initial concept phase, where you're focusing on idea validation, market research, and possibly building a prototype." },
    { stage: "Seed", tip: "You've moved beyond the concept stage and are now developing the product, securing initial funding, and possibly starting to build a small team" },
    { stage: "Series A", tip: "Your product is gaining traction with users or customers, and you're securing further funding to expand development and operations." },
    { stage: "Series B+", tip: "Your product is in the growth phase, focusing on scaling, increasing market share, and potentially preparing for large-scale expansion or additional rounds of investment." },
];

// const UserDedication = {
//     "Full-Time": 'Full-Time',
//     "Part-Time": 'Part-Time',
// };
const Industries = [
    "AI", "Fintech", "Healthtech", "Edtech", "Cleantech", "E-commerce",
    "Marketplace", "Cybersecurity", "Web3", "Enterprise software", "Gaming",
    "Robotics", "Cloud", "Biotech", "Fashion", "Other"
];
const MRR = [
    "Under $15,000", "Between $15,000-$50,000", "Between $50,000-$100,000",
    "$100,000+", "$500,000+", "$1,000,000"
];
const Users = [
    "0-100", "101-1000", "1001-50000", "50001-100000", "100001-500000",
    "500001-1000000", "over 1 million"
];
const Funds_Raised = [
    "$0-$100,000", "$100,000-$250,000", "$250,000-$500,000", "$500,000-$1,000,000",
    "$1,000,000-$2,500,000", "$2,500,000-$5,000,000", "$5,000,000-$10,000,000",
    "Over $10,000,000"
];

const Entrepreneurial_Experience = ['first-time founder', 'Serial entrepreneur', 'Exited entrepreneur', 'VC-backed entrepreneur', 'Previous operator experience in a VC-backed start-up', 'Previous operator experience in a VC-backed, pre-IPO start-up', 'Technical co-founder with at least a 10 percent equity stake', 'Not specified']


function BusinesInfo({ setActiveKey }: { setActiveKey: any }) {
    const user = useSelector((state: RootState) => state.user, shallowEqual)
    const startupData = useSelector((state: RootState) => state.startup, shallowEqual)
    const [updateStartUpAndUser] = useUpdateStartUpAndUserMutation()
    const { refetch: userDataRefetch } = useGetUserQuery(user?.id)
    const { refetch: startupDataRefetch } = useGetStartUpQuery(user?.id)


    const [form] = Form.useForm();

    const handleFinish = (values: any) => {

        const submitData = {
            id: user?.id,
            startup_id: startupData?.id,
            user_data: {
                dedication: values.dedication,
                entrepreneurial_experience: values.entrepreneurial_experience,
                name_full_time_co_founder: values.name_full_time_co_founder,
                name_part_time_co_founder: values.name_part_time_co_founder,
                pre_accelerators: values.pre_accelerators
            },
            start_up_data: {
                name_of_start_up: values.name_of_start_up,
                about_start_up: values.about_start_up,
                product_stages: values.product_stages,
                industry: values.industry,
                monthly_recurring_revenue: values.monthly_recurring_revenue,
                users: values.users,
                foundername: values.foundername,
                founder_bios: values.founder_bios,
                prominent_investor_bios: values.prominent_investor_bios,
                fund_raised: values.fund_raised,
                award_won: values.award_won
            }
        }
        updateStartUpAndUser(submitData).then(() => {
            startupDataRefetch()
            userDataRefetch()
            setActiveKey("2")
            successMessage("Information saved successfully")
        }).catch(() => {

        });
    };

    const initialValues = {
        name_of_start_up: startupData?.name_of_start_up || "",
        about_start_up: startupData?.about_start_up || "",
        product_stages: startupData?.product_stages || "",
        industry: startupData?.industry || "",
        monthly_recurring_revenue: startupData?.monthly_recurring_revenue || "",
        users: startupData?.users || "",
        foundername: startupData?.foundername || "",
        founder_bios: startupData?.founder_bios || "",
        prominent_investor_bios: startupData?.prominent_investor_bios || "",
        fund_raised: startupData?.fund_raised || "",
        award_won: startupData?.award_won || "",
        dedication: user?.dedication || "",
        entrepreneurial_experience: user?.entrepreneurial_experience || "",
        name_full_time_co_founder: user?.name_full_time_co_founder || "",
        name_part_time_co_founder: user?.name_part_time_co_founder || "",
        pre_accelerators: user?.pre_accelerators || "",
    }




    return (
        <>
            <div className="flex lg:flex-row flex-col justify-between gap-x-8">
                {/*LEFT*/}
                <div className=" w-full">
                    <div className="flex flex-col flex-wrap" >
                        <div>
                            {/*SUB TITLE*/}
                            <div
                                style={{
                                    padding: '24px 0'
                                }}
                            >
                                <div className='text-[#4A4A4A] text-[13px]'>
                                    Help us to help you. Please fill out your profile. The more information we have, the more we can personalize the matches.
                                </div>
                                <div className='text-[#4A4A4A] text-[13px]'>
                                    Your info is private and will only be used to make personalized recommendations.
                                </div>
                            </div>
                        </div>


                        <div>
                            <Form
                                form={form}
                                layout="vertical"
                                onFinish={handleFinish}
                                initialValues={initialValues}
                                requiredMark={((label: ReactNode, info: { required: boolean }) => {
                                    return info.required ?
                                        <div className='flex items-center gap-x-1'>
                                            <p className='text-[#0F172A] font-medium text-sm'>{label}</p>
                                            <p className='bg-[#BDDCFF] text-[#4083CE] rounded-md text-[10px] px-2 py-1 font-medium'>Required</p>
                                        </div> : <p className='text-[#0F172A] font-medium text-sm'>{label}</p>;
                                })}
                            >
                                <div className="grid grid-cols-2 gap-x-5">
                                    <Form.Item
                                        name="name_of_start_up"
                                        label="Company Name"
                                        rules={[{ required: true, message: 'Please input the name of your start-up!' }]}

                                    >
                                        <Input placeholder="Name of Start-Up" />
                                    </Form.Item>

                                    <Form.Item
                                        name="foundername"
                                        label="Founder Name"
                                        rules={[{ required: true, message: 'Please input the founder\'s name!' }]}
                                    >
                                        <Input placeholder="Founder Name" />
                                    </Form.Item>
                                </div>

                                <Form.Item
                                    name="founder_bios"
                                    label="Founder Bio(s)"
                                    rules={[{ required: true, message: 'Please input the founder\'s bios!' }]}
                                >
                                    <Input.TextArea placeholder="Founder Bios" />
                                </Form.Item>

                                <Form.Item
                                    name="about_start_up"
                                    label="About Start-Up"
                                    rules={[{ required: true, message: 'Please describe startup' }]}
                                >
                                    <Input.TextArea placeholder="About Start-Up" />
                                </Form.Item>

                                {/* <Form.Item
                                    name="dedication"
                                    label="Dedication"
                                    rules={[{ required: true, message: 'Please select your dedication!' }]}
                                >
                                    <Select placeholder="Select Dedication">
                                        {Object.entries(UserDedication).map(([key, value]) => (
                                            <Select.Option key={key} value={key}>{value}</Select.Option>
                                        ))}
                                    </Select>
                                </Form.Item> */}

                                <div className="grid lg:grid-cols-2 gap-x-5">
                                    <Form.Item
                                        name="product_stages"
                                        label={<div className="flex gap-x-1 items-center">
                                            <p>Stage</p>
                                            <Tooltip title={
                                                <div>
                                                    <p>Choose the stage that best describes the current phase of your product's development</p>
                                                    <ul className="list-disc">
                                                        <li><b>Pre-Seed</b>: Your product is in the initial concept phase, where you're focusing on idea validation, market research, and possibly building a prototype.
                                                        </li>
                                                        <li><b>Seed</b>:  You've moved beyond the concept stage and are now developing the product, securing initial funding, and possibly starting to build a small team.</li>
                                                        <li><b>Seed A</b>:  Your product is gaining traction with users or customers, and you're securing further funding to expand development and operations.</li>
                                                        <li><b>Seed B+</b>:  Your product is in the growth phase, focusing on scaling, increasing market share, and potentially preparing for large-scale expansion or additional rounds of investment.
                                                        </li>
                                                    </ul>
                                                </div>
                                            } placement="right"
                                                overlayClassName="lg:max-w-[500px] max-w-[350px]"
                                            >
                                                <IoIosInformationCircleOutline />
                                            </Tooltip>

                                        </div>}
                                        rules={[{ required: true, message: 'Please select the product stage!' }]}
                                    >
                                        <Select placeholder="Select a product stage">
                                            <Option value={""} >Select a product stage</Option>
                                            {ProductStagess.map(stage => (
                                                <Option key={stage.stage} value={stage.stage} style={{ display: "flex" }}>
                                                    {stage.stage}
                                                    {/* <Tooltip title={stage.tip} placement="right">
                                                        <IoIosInformationCircleOutline />
                                                    </Tooltip> */}
                                                </Option>
                                            ))}
                                        </Select>
                                    </Form.Item>

                                    <Form.Item
                                        name="industry"
                                        // label="Industry"
                                        label={<div className="flex gap-x-1 items-center">
                                            <p>Industry</p>
                                            <Tooltip title="Specify the primary field or domain in which your startup or company operates. This information helps us match you with the most suitable venture capitalists or investors who specialize in your industry" placement="right">
                                                <IoIosInformationCircleOutline />
                                            </Tooltip>

                                        </div>}
                                        rules={[{ required: true, message: 'Please select the industry!' }]}
                                    >
                                        <Select placeholder="Select an industry">
                                            <Option value={""} >Select an industry</Option>
                                            {Industries.map(industry => (
                                                <Option key={industry} value={industry}>{capitalizeFirstLetter(industry)}</Option>
                                            ))}
                                        </Select>
                                    </Form.Item>

                                    <Form.Item
                                        name="monthly_recurring_revenue"
                                        // label="Monthly Recurring Revenue"
                                        label={<div className="flex gap-x-1 items-center">
                                            <p>Monthly Recurring Revenue</p>
                                            <Tooltip title="Enter the total amount of predictable, recurring revenue your startup or company generates each month from subscriptions, contracts, or other recurring sources. This metric helps us understand the financial stability and growth potential of your business." placement="right">
                                                <IoIosInformationCircleOutline />
                                            </Tooltip>

                                        </div>}
                                        rules={[{ required: true, message: 'Please select the monthly recurring revenue!' }]}
                                    >
                                        <Select placeholder="Select monthly recurring revenue">
                                            <Option value={""} >Select monthly recurring revenue</Option>
                                            {MRR.map(revenue => (
                                                <Option key={revenue} value={revenue}>{revenue}</Option>
                                            ))}
                                        </Select>
                                    </Form.Item>

                                    <Form.Item
                                        name="users"
                                        // label="Users"
                                        label={<div className="flex gap-x-1 items-center">
                                            <p>Users</p>
                                            <Tooltip title="Provide the estimated number of users or customers currently engaged with your startup or company. This can include active users, subscribers, or customers who regularly interact with your product or service." placement="right">
                                                <IoIosInformationCircleOutline />
                                            </Tooltip>

                                        </div>}
                                        rules={[{ required: false, message: 'Please select the number of users!' }]}
                                    >
                                        <Select placeholder="Select number of users">
                                            <Option value={""} >Select number of users</Option>
                                            {Users.map(user => (
                                                <Option key={user} value={user}>{user}</Option>
                                            ))}
                                        </Select>
                                    </Form.Item>

                                </div>

                                <Form.Item
                                    name="entrepreneurial_experience"
                                    // label="Entrepreneurial Experience"
                                    label={<div className="flex gap-x-1 items-center">
                                        <p>Entrepreneurial Experience</p>
                                        <Tooltip
                                            title={
                                                <div>
                                                    <p>Share your background as a founder or entrepreneur, highlighting relevant experiences that demonstrate your expertise and track record. This helps us understand your level of experience and connect you with investors or resources that align with your journey. Here are some examples of different types of entrepreneurial experience</p>
                                                    <ul className="list-disc">
                                                        <li><b>Serial Entrepreneur</b>: You have founded multiple startups, indicating a deep experience in launching and scaling businesses.</li>
                                                        <li><b>Exited Entrepreneur</b>: You have successfully sold or exited a startup, showcasing your ability to build and exit profitable businesses.</li>
                                                        <li><b>VC-backed Entrepreneur</b>: You have previously secured venture capital funding for one or more of your startups, demonstrating your ability to attract investment and scale operations.</li>
                                                        <li><b>Previous Operator Experience in a VC-backed Start-up</b>: You have experience working in a significant role (not necessarily as a founder) within a startup that was backed by venture capital, which provides you with insight into scaling and managing a growth-oriented business.
                                                        </li>
                                                        <li><b>Previous Operator Experience in a VC-backed, Pre-IPO Start-up</b>: You have worked in a key role in a venture-backed startup that was preparing for an Initial Public Offering (IPO), giving you experience in managing rapid growth and preparing a company for the public markets.</li>
                                                        <li><b>Technical Co-founder</b>: You are a co-founder with a technical background who holds at least a 10% equity stake in the company, indicating your involvement in both the technical and strategic aspects of the business.</li>
                                                    </ul>
                                                </div>
                                            }
                                            placement="right"
                                            overlayClassName="lg:max-w-[500px] max-w-[350px]"
                                        >
                                            <IoIosInformationCircleOutline />
                                        </Tooltip>
                                    </div>}
                                    rules={[{ required: false }]}
                                >
                                    <Select placeholder="Select entrepreneurial experience">
                                        <Option value={""} >Select entrepreneurial experience</Option>
                                        {Entrepreneurial_Experience.map(experience => (
                                            <Option key={experience} value={experience}>{experience}</Option>
                                        ))}
                                    </Select>
                                </Form.Item>

                                {/* <Form.Item
                                    name="name_full_time_co_founder"
                                    label="Name of Full-Time Co-Founder"
                                >
                                    <Input placeholder="Name of Full-Time Co-Founder" />
                                </Form.Item>

                                <Form.Item
                                    name="name_part_time_co_founder"
                                    label="Name of Part-Time Co-Founder"
                                >
                                    <Input placeholder="Name of Part-Time Co-Founder" />
                                </Form.Item> */}

                                <Form.Item
                                    name="pre_accelerators"
                                    // label="Pre-Accelerators and/or Accelerators"
                                    label={<div className="flex gap-x-1 items-center">
                                        <p>Pre-Accelerators and/or Accelerators</p>
                                        <Tooltip title="Pre-accelerators are short-term programs aimed at helping early-stage startups validate their business ideas and develop prototypes. Accelerators are intensive programs that help startups with a developed product or service scale up and grow rapidly." placement="right">
                                            <IoIosInformationCircleOutline />
                                        </Tooltip>
                                    </div>}

                                >
                                    <Input placeholder="Pre-Accelerators and/or Accelerators" />
                                </Form.Item>



                                <Form.Item
                                    name="prominent_investor_bios"
                                    // label="Lead Investors"
                                    label={<div className="flex gap-x-1 items-center">
                                        <p>Lead Investors</p>
                                        <Tooltip title="A lead investor is the primary or anchor investor in a funding round for a startup or business. This investor often contributes a significant portion of the total investment and plays a crucial role in negotiating the terms of the deal." placement="right">
                                            <IoIosInformationCircleOutline />
                                        </Tooltip>
                                    </div>}

                                >
                                    <Input placeholder="Prominent Investor Bios" />
                                </Form.Item>

                                <Form.Item
                                    name="fund_raised"
                                    // label="Total Amount Raised"
                                    label={<div className="flex gap-x-1 items-center">
                                        <p>Total Amount Raised</p>
                                        <Tooltip title="The total amount raised refers to the cumulative sum of money that a startup or company has secured from investors over one or multiple funding rounds. This figure includes all forms of investment such as equity financing, debt financing, convertible notes, and other financial instruments. It provides a snapshot of the financial backing a company has received to support its growth and operations." placement="right">
                                            <IoIosInformationCircleOutline />
                                        </Tooltip>
                                    </div>}

                                >
                                    <Select placeholder="Select total amount raised">
                                        <Option value={""} >Select total amount raised</Option>
                                        {Funds_Raised.map(fund => (
                                            <Option key={fund} value={fund}>{fund}</Option>
                                        ))}
                                    </Select>
                                </Form.Item>

                                <Form.Item
                                    name="award_won"
                                    // label="Relevant Awards Won"
                                    label={<div className="flex gap-x-1 items-center">
                                        <p>Relevant Awards Won</p>
                                        <Tooltip title="'Relevant awards won' refers to the notable recognitions and honors a company, product, or individual has received that are directly related to their industry, achievements, or contributions. These awards serve as a testament to the quality, innovation, or impact of the recipient and can enhance credibility and reputation." placement="right">
                                            <IoIosInformationCircleOutline />
                                        </Tooltip>
                                    </div>}

                                >
                                    <Input placeholder="Relevant Awards Won" />
                                </Form.Item>

                                <Form.Item className="w-full">
                                    <Button type="primary" htmlType="submit"  >
                                        Submit
                                    </Button>
                                </Form.Item>
                            </Form>
                        </div>

                    </div>
                </div>

                {/*RIGHT*/}
                {/* <div style={{
                    marginTop: 35,
                    padding: 32,
                    display: 'flex',
                    flexDirection: 'column',
                    boxShadow: '0px 10px 15px -3px rgba(0,0,0,0.1)',
                    height: 'max-content',
                    // width: '50%',
                    marginRight: 32,
                    borderRadius: 16
                }} className="lg:w-1/2 w-full">
                    USER
                    <div style={{
                        display: 'flex', alignItems: 'flex-start', gap: 80
                    }}>
                        <div style={{ display: 'flex' }}>
                            <div><img src={user?.profile_image_url || defaultProfile} style={{ height: 48, width: 48, marginRight: 10, borderRadius: '50%' }} alt={'avatar'} />
                            </div>
                            <div style={{ display: 'flex', flexDirection: 'column', width: 'max-content' }}>
                                <div style={{ fontSize: 18 }}>{user?.first_name || 'username'}</div>
                                <div style={{ fontSize: 18, color: '#868E96' }}>First-time founder</div>
                            </div>
                        </div>

                        <div><img src={exportBtn} alt={exportBtn} /></div>
                    </div>


                    CHART
                    <div
                        style={{ marginTop: 20, alignSelf: 'center' }}
                    >
                        <Radar
                            data={{
                                labels: ['Product', 'Market', 'Traction'],
                                datasets: [
                                    {
                                        label: 'Productivity',
                                        backgroundColor: 'rgba(34, 139, 230, 0.3)',
                                        borderColor: '#228BE6',
                                        pointBackgroundColor: 'rgba(0, 0, 255, 0.5)',
                                        pointBorderColor: '#fff',
                                        pointHoverBackgroundColor: '#fff',
                                        pointHoverBorderColor: 'rgba(0, 0, 255, 0.8)',
                                        data: [3, 4, 5],
                                    }
                                ]
                            }}
                        />
                    </div>

                    <div style={{ marginTop: -10 }}>
                        <div
                            style={{
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'space-between',
                                borderTop: '1px solid #E9ECEF',
                                paddingTop: 20
                            }}
                        >
                            <div style={{ fontWeight: '500' }}>Product stage</div>
                            <div style={{ color: '#495057' }}>Scale-up</div>
                        </div>

                        <div
                            style={{
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'space-between',
                                borderTop: '1px solid #E9ECEF',
                                paddingTop: 20,
                                marginTop: 20
                            }}
                        >
                            <div style={{ fontWeight: '500' }}>Market</div>
                            <div style={{ color: '#495057' }}>Multi billion-dollar TAM</div>
                        </div>

                        <div
                            style={{
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'space-between',
                                borderTop: '1px solid #E9ECEF',
                                paddingTop: 20,
                                marginTop: 20
                            }}
                        >
                            <div style={{ fontWeight: '500' }}>Traction</div>
                            <div style={{ color: '#495057' }}>Bootstrapping</div>
                        </div>

                        <div
                            style={{
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'space-between',
                                borderTop: '1px solid #E9ECEF',
                                paddingTop: 20,
                                marginTop: 20
                            }}
                        >
                            <div style={{ fontWeight: '500' }}>Sector</div>
                            <div style={{ color: '#495057' }}>Consumer</div>
                        </div>

                        <div
                            style={{
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'space-between',
                                borderTop: '1px solid #E9ECEF',
                                paddingTop: 20,
                                marginTop: 20
                            }}
                        >
                            <div style={{ fontWeight: '500' }}>Tech theme</div>
                            <div style={{ color: '#495057' }}>Fintech</div>
                        </div>
                    </div>
                </div> */}


            </div>
        </>
    )
}

export default BusinesInfo