import React from 'react'

function UploadIcon() {
    return (
        <svg width="13" height="18" viewBox="0 0 13 18" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M4 5.875H2.75C2.25272 5.875 1.77581 6.07254 1.42417 6.42417C1.07254 6.77581 0.875 7.25272 0.875 7.75V15.25C0.875 15.7473 1.07254 16.2242 1.42417 16.5758C1.77581 16.9275 2.25272 17.125 2.75 17.125H10.25C10.7473 17.125 11.2242 16.9275 11.5758 16.5758C11.9275 16.2242 12.125 15.7473 12.125 15.25V7.75C12.125 7.25272 11.9275 6.77581 11.5758 6.42417C11.2242 6.07254 10.7473 5.875 10.25 5.875H9M9 3.375L6.5 0.875M6.5 0.875L4 3.375M6.5 0.875V11.5" stroke="white" stroke-linecap="round" stroke-linejoin="round" />
        </svg>

    )
}

export default UploadIcon