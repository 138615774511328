export const userBadges = {
    'Founder profile': [
        {
            id:         'SerialFounder',
            label:      'Serial founder',
            isSelected: false
        },
        {
            id:         'PreviousExit',
            label:      'Previous exit',
            isSelected: false
        },
        {
            id:         'First-timeFounder',
            label:      'First-time founder',
            isSelected: true
        },
        {
            id:         'SoloFounder',
            label:      'Solo founder',
            isSelected: false,
        },
        {
            isSelected: false,
            id:         'TechnicalFounder',
            label:      'Technical founder'
        },
        {
            isSelected: false,
            id:         'MBAFounder',
            label:      'MBA founder'
        },
    ],
}