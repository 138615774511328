import { apiSlice } from "../../services/apiSlice";


export const pitchDeckApiSlice = apiSlice.injectEndpoints({
    endpoints: builder => ({
        pitchDeckUpload: builder.mutation({
            query: (data: { user_id: string | undefined, file: FormData }) => ({
                url: `pitch_deck_uploader/uploading_pitch_deck/`,
                method: 'POST',
                body: {
                    user_id: data.user_id,
                    file: data.file,
                }
            })
        }),
        pitchDeckProcess: builder.mutation({
            query: (data: { user_id: string | undefined }) => ({
                url: `pitch_deck_uploader/process_pitch_deck/`,
                method: 'POST',
                body: {
                    user_id: data.user_id,
                }
            })
        }),
        getPitchDeck: builder.mutation({
            query: (data) => ({
                url: `pitch_deck_uploader/getting_pitch_deck/`,
                method: 'POST',
                body: {
                    user_id: data.user_id,
                    object_key: data.object_key,
                }
            })
        }),


    })
})

export const {
    useGetPitchDeckMutation,
    usePitchDeckProcessMutation,
    usePitchDeckUploadMutation
    
} = pitchDeckApiSlice