import React, { FC } from 'react'
import { useLocation } from 'react-router-dom'

interface IProps {
    label: string
    icon: string
    onClick: () => void
    isDisabled: boolean
    disabledIcon: string
    isAuthenticated: boolean
    path: string
}

export const SideMenuItem: FC<IProps> = ({ icon, label, onClick, isDisabled, disabledIcon, isAuthenticated, path }) => {

    const location = useLocation()
    return (isAuthenticated ?
        <div className={`relative z-0 mt-1 w-full flex items-center rounded-lg hover:border-b hover:border-primary ${location.pathname === path ? ' border border-primary bg-primary/10 text-primary' : ''}`}>
            <button
                disabled={isDisabled}
                onClick={onClick}
                className='flex w-full items-center justify-start gap-2 py-2.5 px-3 rounded-lg'
            >
                <div className=''>
                    <img src={location.pathname === path ? icon : disabledIcon} alt={'icon'} />
                </div>
                <div className='relative text-[13px] font-normal' >{label}</div>
            </button>
        </div>
        :
        null
    )
};

