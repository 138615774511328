import React, { useEffect, useState } from 'react'
import Logo from "../../assets/svg/bigLogo.svg"
import { Progress, Timeline } from 'antd';
import { usePitchDeckProcessMutation } from '../../lib/features/pitch_deck/pitchDeck';
import { useAppSelector } from '../../lib/hooks';
import { RootState } from '../../lib/store';
import { useNavigate } from 'react-router-dom';
import { useLazyGetStartUpQuery } from '../../lib/features/startup/startupApiSlice';
import { AiOutlineLoading3Quarters } from "react-icons/ai";


interface TimelineInterface {
  children: string
}

function ProcessPitchDeck() {

  const [percent, setPercent] = useState(0);
  const [semanticCompleted, setSemanticCompleted] = useState(false);
  const [pitchDeckProcess, { isLoading }] = usePitchDeckProcessMutation()
  const user = useAppSelector((state: RootState) => state.user)
  const navigate = useNavigate()
  const semanticNotif = React.useMemo(() => ["Processing request", "Extracting Team name", "Analyzing slides", "Generating insights"], []);

  const [getStartUp] = useLazyGetStartUpQuery()

  const [semanticNofifList, setSemanticNofitList] = React.useState(semanticNotif)
  const [timelineItems, setTimelineItems] = useState<TimelineInterface[] | []>([]);
  const [pendingNotification, setPendingNotification] = useState("");


  useEffect(() => {
    if (user?.id) {
      pitchDeckProcess({ user_id: user.id }).then(() => {
        setPercent(100);
        setSemanticCompleted(true)
        getStartUp(user.id).then(() => {
          navigate("/profile#Information")
        })
      });
    }
  }, [getStartUp, navigate, pitchDeckProcess, user.id]);



  useEffect(() => {
    if (!semanticCompleted) {
      const updateTimeline = (index: number) => {
        if (index < semanticNotif.length) {
          setTimelineItems(prevItems => [
            ...prevItems,
            { children: semanticNotif[index] }
          ]);
          setPendingNotification(semanticNotif[index + 1] || "Finalizing...");
        }
      };

      // Update timeline at intervals
      const intervals = [0, 16000, 32000, 48000];
      intervals.forEach((delay, index) => {
        setTimeout(() => updateTimeline(index), delay);
      });
    }
  }, [semanticCompleted, semanticNotif]);

  useEffect(() => {
    let currentPercent = 0;
    const targetPercent = 99;
    const duration = 2 * 60 * 1000;
    const increment = Math.ceil((targetPercent / duration) * 1000);

    const id = setInterval(() => {
      currentPercent += increment;
      if (currentPercent >= targetPercent) {
        currentPercent = targetPercent;
        clearInterval(id);
      }
      setPercent(prev => (prev < 100 ? currentPercent : prev));
    }, 1000);

    return () => clearInterval(id);
  }, []);

  return (
    <div className='h-full flex justify-center items-start mt-[5%]'>
      <div className='max-w-[1000px] w-full max-h-[530px]'>
        <div className='flex flex-col items-center justify-center w-full mt-5 gap-x-4'>
          <img src={Logo} alt="" />
          <p className='mt-4 font-bold text-xl'>Processing your documents...</p>
          <p className='mt-7'>Hang tight while we analyze the contents and entract the key details for you. This might take a moment</p>

          <div className='mt-5 mb-7 max-w-[500px] w-full'>
            <Progress percent={percent} />
          </div>

          {isLoading &&

            <div>
              <div className='mt-6'>
                <div className='flex items-center gap-x-2'>
                  {/* <Timeline className='text-2xl font-bold'
                    pending={pendingNotification}
                    reverse={false}
                    items={timelineItems}
                  /> */}
                  <AiOutlineLoading3Quarters className='animate-spin text-primary' />
                  {pendingNotification}
                </div>
              </div>

            </div>
          }

        </div>

      </div>
    </div>
  )
}

export default ProcessPitchDeck