import React, { useCallback, useEffect } from 'react'
import HomePageLogo from '../../assets/svg/HompageLogo.svg'

import { Tabs, TabsContent, TabsList, TabsTrigger } from "../../components/Tabs"
import Overview from './tabs/Overview'
import PitchDeckModal from '../../components/PitchDeckModal'
import { useAppSelector } from '../../lib/hooks'
import { RootState } from '../../lib/store'
import { shallowEqual } from 'react-redux'
import { calculatePercentage } from '../../utils/calculatePercentage'
import { Switch } from 'antd'
import Dnd from './components/Dnd'


function Homepage() {
    const [pitchDeckModal, setPitchDeckModal] = React.useState(false)
    const user = useAppSelector((state: RootState) => state.user, shallowEqual)
    const startupData = useAppSelector((state: RootState) => state.startup, shallowEqual)
    const [funnel, setFunnel] = React.useState(false)

    console.log(startupData)

    const calculateNonEmptyPercentage = useCallback(() => {
        const val = calculatePercentage(user, startupData)
        return val
    }, [startupData, user])


    // useEffect(() => {
    //   if(parseInt(calculateNonEmptyPercentage()) < 50 || user.){

    //   }
    // }, [])


    return (
        <div className='h-full p-[30px] overflow-scroll'>

            <div className='w-full h-[100px] bg-primary/10 flex items-center justify-between rounded-md'>

                <div className='flex items-center gap-x-3'>
                    <img src={HomePageLogo} alt="" />
                    <h2 className='text-xl font-semibold max-w-[500px] text-[#205795]'>Welcome to FounderTribes, the world of personalized and contextualized intros.</h2>
                </div>

                <div className='mr-[30px]'>
                    {parseInt(calculateNonEmptyPercentage()) < 50 ?
                        <button onClick={() => { setPitchDeckModal(true) }} className='flex items-center gap-x-2 bg-primary text-[15px] text-white px-5 py-3.5 rounded-md text-sm font-normal'>
                            <p>Get Started</p>
                        </button>
                        : null
                    }
                </div>



            </div>
            <Switch checkedChildren="Funnel" unCheckedChildren="Overview" checked={funnel} onChange={() => { setFunnel(!funnel) }} className='float-right mt-4 mr-3'/>

            {!funnel ?
                <div className='mt-10'>

                    <Tabs defaultValue="overview" className="">
                        <TabsList className='gap-x-10'>
                            <TabsTrigger value="overview">Overview</TabsTrigger>
                            <TabsTrigger value="matches">Matches</TabsTrigger>
                            <TabsTrigger value="shortlist">Shortlist</TabsTrigger>
                            <TabsTrigger value="reached">Reached out</TabsTrigger>
                            <TabsTrigger value="views">Views</TabsTrigger>


                        </TabsList>


                        <TabsContent value="overview">
                            <Overview />
                        </TabsContent>
                        <TabsContent value="matches">
                            Matches
                        </TabsContent>
                    </Tabs>
                </div>
                :
                <div className='mt-20'>
                    <Dnd 
                    />
                </div>
            }




            <PitchDeckModal pitchDeckModal={pitchDeckModal} setPitchDeckModal={setPitchDeckModal} />


        </div>
    )
}

export default Homepage