import { apiSlice } from "../../services/apiSlice";


export const startupApiSlice = apiSlice.injectEndpoints({
    endpoints: builder => ({
        getStartUp: builder.query({
            query: (userId) => ({
                url: `startup/${userId}`,
                method: 'GET',
            })
        }),
        updateStartUpAndUser: builder.mutation({
            query: (credentials) => ({
                url: `users/${credentials.id}/${credentials.startup_id}/`,
                method: 'PATCH',
                body: {
                    start_up_data: credentials.start_up_data,
                    user_data: credentials.user_data,
                }
            })
        }),
        updateUserProfileImg: builder.mutation({
            query: (credentials: { userId: string, formData: FormData }) => ({
                url: `users/${credentials.userId}/profile-image`,
                method: 'POST',
                body: credentials.formData,
            })
        }),
    })
})

export const {
    useLazyGetStartUpQuery,
    useGetStartUpQuery,
    useUpdateStartUpAndUserMutation,
    useUpdateUserProfileImgMutation
} = startupApiSlice