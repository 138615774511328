import React, { FC } from 'react'


interface IProps {
    username: string
    comment: string
    avatar: string
    onClick: () => void
}

export const SessionItem: FC<IProps> = ({ username, comment, avatar, onClick }) => {


    return (
        <div className=' flex items-center mb-3 mt-1 gap-2 px-2.5 py-3 bg-[#E9E9E9] border border-[#E9ECEF] rounded-md cursor-pointer hover:bg-[#e2e3e4]/30 hover:-translate-y-1 hover:border-black transition-all'
            onClick={onClick}
        >
            <div>
                <img src={avatar} alt={'avatar'} className='size-[27px]' />
            </div>
            <div className='flex flex-col gap-1'>
                <div className='text-[10px]'>{username}</div>
                <div className='text-[#4A4A4A] text-xs whitespace-nowrap overflow-hidden overflow-ellipsis'>{comment}</div>

            </div>
        </div>
    )
};

