import React from 'react'

function PenIcon() {
    return (
        <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M9.83617 2.61732L10.8203 1.63265C11.0254 1.42751 11.3036 1.31226 11.5938 1.31226C11.8839 1.31226 12.1621 1.42751 12.3673 1.63265C12.5724 1.8378 12.6876 2.11603 12.6876 2.40615C12.6876 2.69627 12.5724 2.97451 12.3673 3.17965L3.98533 11.5616C3.67694 11.8698 3.29663 12.0963 2.87875 12.2207L1.3125 12.6874L1.77917 11.1212C1.90358 10.7033 2.13012 10.323 2.43833 10.0146L9.83675 2.61732H9.83617ZM9.83617 2.61732L11.375 4.15615" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
        </svg>

    )
}

export default PenIcon
