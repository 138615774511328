import axios, { AxiosError, AxiosRequestConfig } from "axios";
import axiosRetry from "axios-retry";
import { IBusiness } from "../@types/types";
import { AUTH } from "../constants/storageKey";

// export const BASE_URL = 'https://foundertribes-api.com/';
export const BASE_URL = process.env.REACT_APP_ENV === 'local' ? 'http://127.0.0.1:8000/' : 'https://foundertribes-api.com/';

const API = axios.create({
    baseURL: "http://0.0.0.0:8000/",
    validateStatus: (status) => status <= 500,
    timeout: 300000,
})

axiosRetry(API, {
    retryDelay: axiosRetry.exponentialDelay,
    retries: 1,
    onRetry: (retryCount: number, error: AxiosError, requestConfig: AxiosRequestConfig) => {






    }
}
);

API.interceptors.request.use(
    function (config) {
        // Do something before request is sent
        const auth = localStorage.getItem(AUTH);

        // If idToken exists, attach it to the Authorization header
        if (auth) {
            const token = JSON.parse(auth)
            // 
            config.headers.Authorization = `Bearer ${token.IdToken}`;
        }
        return config;
    },
    function (error) {
        // Do something with request error

        return Promise.reject(error);
    });

// Add a response interceptor
API.interceptors.response.use(
    function (response) {
        // Any status code that lie within the range of 2xx cause this function to trigger
        // Do something with response data
        return response;
    },
    function (error) {
        // Any status codes that falls outside the range of 2xx cause this function to trigger
        // Do something with response error

        return Promise.reject(error);
    });

export const askQuestion = async (userId: string, content: { role: string, content: string }[], chatPersona: 'Gary' | 'Manuela' | 'Valentina', sessionId?: string,) => {
    const res = await API.post(`ask`, {
        id: sessionId,
        userId,
        title: '',
        content,
        chatPersona
    })
    return res.data;
}


export const getConversation = async (userId: string) => {
    const res = await API.get(`ask/${userId}`)
    return res.data
}


export const getConversationById = async (conversationId: string, userId: string) => {
    const res = await API.get(`ask/${userId}/${conversationId}`)
    return res.data
}

export const getUser = async (userId: string) => {
    const res = await API.get(`users/${userId}`)
    return res.data
}

export const updateUser = async (userId: string, name: string, badges: string[], role: string, bio?: string) => {
    const res = await API.patch(`users/${userId}`, { name, badges, bio, role })
    return res.data
}
export const updateUserProfileImg = async (userId: string, formData: FormData) => {
    const res = await API.put(`users/${userId}/profile-image`, formData)
    return res.data
}

export const createAccount = async (name: string, email: string, password: string,) => {
    const res = await API.post(`auth/signup`, {
        username: email,
        name,
        password,
        email,
        type: "Founder",
        countryCode: "usa",
        companyId: "665c627ed6bb84f68bbea3ea",
    })
    return res.data
}

export const loginAccount = async (email: string, password: string,) => {
    const res = await API.post(`auth/signin`, {
        username: email,
        password,
    })
    return res.data
}

export const confirmAccount = async (email: string, code: string,) => {
    const res = await API.post(`auth/confirm`, {
        username: email,
        code
    })
    return res.data
}

export const updateBusiness = async (businessId: string, business: IBusiness) => {
    const res = await API.patch(`business/${businessId}`, { business })
    return res.data
}

export const getBusiness = async (businessId: string) => {
    const res = await API.get(`users/${businessId}`)
    return res.data
}

// Need to make this work targetEntityType
// export const getRecommendation = async (userId: string, searchEntityName = 'user', targetEntityName = 'user', targetEntityType = 'investor') => {
//     const res = await API.post(`semantic/`, {
//         searchEntityId: userId,
//         searchEntityName,
//         targetEntityName,
//         targetEntityType
//     })
//     return res.data
// }

export const getRecommendation = async (userId: string, searchEntityName = 'user', targetEntityName = 'fund') => {
    const res = await API.post(`semantic/`, {
        searchEntityId: userId,
        searchEntityName,
        targetEntityName
    });
    return res.data
}

const avatarsBaseUrl = 'https://d3fvz68pqwu1x4.cloudfront.net/chat-avatars/'
export const garySvg = `${avatarsBaseUrl}avatar1.svg`;
export const manuelaSvg = `${avatarsBaseUrl}avatar2.svg`;
export const valentinaSvg = `${avatarsBaseUrl}avatar3.svg`;
export const defaultSvg = `${avatarsBaseUrl}avatar4.svg`;

