import { apiSlice } from "../../services/apiSlice";


export const aiAgentApiSlice = apiSlice.injectEndpoints({
    endpoints: builder => ({
        aiCompleteProfile: builder.mutation({
            query: (data: { user_id: string | undefined, startup_id: string, user_response: string }) => ({
                url: `ai_agent/ai_conversational_agent/`,
                method: 'POST',
                body: {
                    user_id: data.user_id,
                    startup_id: data.startup_id,
                    user_response: data.user_response
                }
            })
        }),
        aiFAQ: builder.mutation({
            query: (data: { user_id: string | undefined, question: string }) => ({
                url: `ai_agent/ai_faq_agent/`,
                method: 'POST',
                body: {
                    user_id: data.user_id,
                    question: data.question
                }
            })
        }),
        getSingleAIConversation: builder.query({
            query: (data) => ({
                url: `ai_agent/conversation/${data.userID}/`,
                method: 'GET',
            })
        }),
        getSingleAIFAQConversation: builder.query({
            query: (data) => ({
                url: `ai_agent/faq_conversation/${data.userID}/`,
                method: 'GET',
            })
        }),


    })
})

export const {
    useAiCompleteProfileMutation,
    useGetSingleAIConversationQuery,
    useAiFAQMutation,
    useGetSingleAIFAQConversationQuery
} = aiAgentApiSlice