
import { createSlice } from '@reduxjs/toolkit'
import { conversationApiSlice } from './conversationApiSlice';
import { Conversation } from '../../../@types/types';



// const initialData: Conversation = {
//     id: "",
//     title: "",
//     userId: "",
//     chatPersona: "",
//     content: []
// };

interface ConversationData {
    conversation: Conversation[] | []
}

const initialData: ConversationData = { 
    conversation: []
}


const conversationSlice = createSlice({
    name: 'conversation',
    initialState: initialData,
    reducers: {
        setStartUpData: (state, action) => {
            state = { ...state, ...action.payload };
            return state
        },
        unSetStartUpData: state => {
            return initialData
        },
    },
    extraReducers: (builder) => {
        builder
            .addMatcher(conversationApiSlice.endpoints.getConversations.matchPending, (state, action) => {
                return
            })
            .addMatcher(conversationApiSlice.endpoints.getConversations.matchFulfilled, (state, action) => {
                state.conversation = [ ...state.conversation, ...action.payload ];
                return state
            })
            .addMatcher(conversationApiSlice.endpoints.getConversations.matchRejected, (state, action) => {
                return state
            })
    },
})


export const { setStartUpData, unSetStartUpData } = conversationSlice.actions
export default conversationSlice.reducer