import React from 'react'
import activeFire from '../../../assets/svg/active-fire.svg'
import inactiveFire from '../../../assets/svg/inactivefire.svg'
import halfFire from '../../../assets/svg/halffire.svg'


const ActiveImage = () => <img src={activeFire} alt="Active" />;
const HalfImage = () => <img src={halfFire} alt="Half Active" />;
const InactiveImage = () => <img src={inactiveFire} alt="Inactive" />;


function Rating({ rating, maxRating }: { rating: number, maxRating: number }) {

    const activeCount = Math.floor(rating);
    const hasHalf = rating % 1 !== 0;
    const inactiveCount = maxRating - Math.ceil(rating);



    let images = [];

    for (let i = 0; i < activeCount; i++) {
        images.push(<ActiveImage key={`active_${i}`} />);
    }

    if (hasHalf) {
        images.push(<HalfImage key="half" />);
    }

    for (let i = 0; i < inactiveCount; i++) {
        images.push(<InactiveImage key={`inactive_${i}`} />);
    }
    return (
        <div className='flex flex-row'>
            {images.map((image, index) => (
                <React.Fragment key={index}>
                    {image}
                </React.Fragment>
            ))}
        </div>
    )
}

export default Rating