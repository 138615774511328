

export function calculatePercentage(userData: any, startupData: any) {
    const userDataFields = [
        "under_school", "graduate_school",
          "race", 
        "recent_employer", "penultimate_employer", "employers_last_10_years",
        "country", "state", "city", 
    ];

    // Startup data fields
    const startupDataFields = [
        "name_of_start_up", "about_start_up", "product_stages", "industry",
        "monthly_recurring_revenue", "foundername", "founder_bios",

    ];

    // Combine all fields
    const allFields = [...userDataFields, ...startupDataFields];

    const combinedData = { ...userData, ...startupData };
    const totalFields = allFields.length;
    const nonEmptyFields = allFields.filter(field => {
        const value = combinedData[field];
        return value !== "" && value !== null && value !== undefined &&
            !(Array.isArray(value) && value.length === 0) &&
            !(typeof value === 'boolean' && value === false);
    });



    const percentage = (nonEmptyFields.length / totalFields) * 100;
    return percentage.toFixed(2);
}