import React from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { Button, Form, FormProps, Input } from 'antd';
import logo from '../../assets/svg/logo.svg'
import { useConfirmPasswordResetMutation, useInitiatePasswordResetMutation } from '../../lib/features/auth/authApiSlice';
import { errorMessage, successMessage } from '../../constants/notification';

type FieldType = {
    email?: string;
};

type ConfirmFieldType = {
    code?: string;
    new_password?: string;
};



function ForgotPassword() {
    const [isConfirm, setIsConfirm] = React.useState(false)
    const [email, setEmail] = React.useState("")

    const navigate = useNavigate();


    const [initiateResetPassword, { isLoading: inititalLoading }] = useInitiatePasswordResetMutation()
    const [resetPassword, { isLoading }] = useConfirmPasswordResetMutation()

    const onFinishInititate: FormProps<FieldType>["onFinish"] = async (values) => {

        const { email } = values
        if (!email) {
            errorMessage('Complete the fields')
            return
        }
        await initiateResetPassword({ username: email }).unwrap().then((res: any) => {
            successMessage("Please check your email for password reset code")
            setIsConfirm(true)
            setEmail(email)
            console.log(email)
        }).catch((err) => {
            errorMessage(err?.data?.detail || "Failed to reset password")

            setIsConfirm(false)
            setEmail("")
        })
    };

    const onFinishReset: FormProps<ConfirmFieldType>["onFinish"] = async (values) => {

        const { code, new_password } = values
        if (!email) {
            errorMessage('Please re-initiate reset password ')
            setIsConfirm(false)
            return
        }
        if (!code || !new_password) {
            errorMessage('Complete the required fields ')
            return
        }
        await resetPassword({ username: email, code: code, new_password: new_password }).unwrap().then((res: any) => {
            successMessage("Password reset successful")
            setIsConfirm(false)
            navigate("/")
            setEmail("")
        }).catch((err) => {
            errorMessage(err?.data?.detail || "Failed to reset password")

        })
    };

    const onFinishFailed: FormProps<FieldType>["onFinishFailed"] = (errorInfo) => {

    };

    const onFinishConfirmFailed: FormProps<ConfirmFieldType>["onFinishFailed"] = (errorInfo) => {

    };
    return (
        <div className='flex flex-col items-center justify-center self-center h-[96vh] w-full bg-white rounded-lg'>

            <div className='p-4 lg:p-8 border border-[#DEE2E6] rounded-lg'>
                <div style={{
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignItems: 'center',
                }}>
                    <img src={logo} style={{ width: 32, margin: 'auto', }} alt={'logo'} />
                    <div style={{ textAlign: 'center', fontSize: 18, fontWeight: '500', margin: '10px 0' }}>Reset Password
                    </div>
                </div>
                {isConfirm ?
                    <Form
                        layout="vertical"
                        style={{ width: 350 }}
                        initialValues={{}}
                        onFinish={onFinishReset}
                        onFinishFailed={onFinishConfirmFailed}
                        autoComplete="off"
                    >
                        <Form.Item<ConfirmFieldType>
                            hasFeedback
                            label="Code"
                            name="code"
                            rules={[{ required: true, message: 'Please input your code sent to your email!' }]}
                        >
                            <Input />
                        </Form.Item>

                        <Form.Item<ConfirmFieldType>
                            label="New Password"
                            name="new_password"
                            hasFeedback
                            rules={[
                                { required: true, message: 'Please input your password!' },
                                ({ getFieldValue }) => ({
                                    validator(_, value) {
                                        if (!value || value.length <= 7) {
                                            return Promise.reject(new Error('Please input a stronger password'));
                                        }
                                        const regex = /^(?=.*[0-9])(?=.*[!@#$%^&*-])(?=.*[A-Z])(?=.*[a-z]).{8,}$/;
                                        if (!regex.test(value)) {
                                            return Promise.reject(new Error('Password must contain at least one number, one special character (!@#$%^&*-), one uppercase letter, and one lowercase letter.'));
                                        }
                                        return Promise.resolve()
                                    },
                                }),
                            ]}
                        >
                            <Input.Password />
                        </Form.Item>

                        <Form.Item>
                            <Button
                                disabled={isLoading}
                                loading={isLoading}
                                type="primary"
                                style={{ backgroundColor: '#228BE6', width: '100%', marginTop: 10 }}
                                htmlType="submit"
                            >
                                Submit
                            </Button>

                        </Form.Item>

                    </Form>
                    :
                    <Form
                        layout="vertical"
                        style={{ width: 350 }}
                        initialValues={{}}
                        onFinish={onFinishInititate}
                        onFinishFailed={onFinishFailed}
                        autoComplete="off"
                    >
                        <Form.Item<FieldType>
                            hasFeedback
                            label="Email"
                            name="email"
                            rules={[{ required: true, message: 'Please input your email!', type: 'email' }]}
                        >
                            <Input />
                        </Form.Item>

                        <Form.Item>
                            <Button
                                disabled={inititalLoading}
                                loading={inititalLoading}
                                type="primary"
                                style={{ backgroundColor: '#228BE6', width: '100%', marginTop: 10 }}
                                htmlType="submit"
                            >
                                Submit
                            </Button>

                        </Form.Item>

                    </Form>
                }


                <Link to={'/'} className='float-right'>Remember password? <span className='text-primary cursor-pointer' >Login</span></Link>
            </div>
        </div>
    )
}

export default ForgotPassword