import React, { FC, useCallback } from 'react'
import { SideMenuItem } from "./components/SideMenuItem";
import { SessionItem } from "./components/SessionItem";
import { Link, useNavigate } from "react-router-dom";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { garySvg } from '../../api/index'
import { manuelaSvg } from '../../api/index'
import { valentinaSvg } from '../../api/index'
import { chatPersonas } from '../../@types/types';
import recommendations from '../../assets/svg/recommendation.svg'
import recommendationsDisabled from '../../assets/svg/recommendation-inactive.svg'
import dashboard from '../../assets/svg/dashboard.svg'
import dashboardInactive from '../../assets/svg/dashboard-inactive.svg'
import profile from '../../assets/svg/profile.svg'
import profileInactive from '../../assets/svg/profile-inactive.svg'
import aiWhite from '../../assets/svg/ai-white.svg'
import History from '../../assets/svg/history.svg'
import savedConversation from '../../assets/svg/saved.svg'
import savedConversationInactive from '../../assets/svg/saved-inactive.svg'

import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { RootState } from '../../lib/store';
import { unSetProfile } from '../../lib/features/user/userSlice';
import { unauthenticate } from '../../lib/features/auth/authSlice';
import { unSetStartUpData } from '../../lib/features/startup/startupSlice';
import { useGetConversationsQuery } from '../../lib/features/conversation/conversationApiSlice';
import { useSidebar } from '../../hooks/use-sidebar';
import { getUserInitials } from '../../utils/utils';

interface IProps {
}

const ChatPersonaAvatars = {
    Gary: garySvg,
    Manuela: manuelaSvg,
    Valentina: valentinaSvg,
};

export const Navbar: FC<IProps> = () => {
    const { toggleSidebar, isSidebarOpen, isLoading } = useSidebar()
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);
    const handleClick = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    const isAuthenticated = useSelector((state: RootState) => state.auth.isAuthenticated)


    // const conversations = useSelector((state: RootState) => state.conversation?.conversation)
    const userId = useSelector((state: RootState) => state.user.id)

    const { data: conversations } = useGetConversationsQuery(userId)
    const user = useSelector((state: RootState) => state.user, shallowEqual)

    const navigate = useNavigate()
    const dispatch = useDispatch()


    const signOut = () => {
        handleClose();
        dispatch(unauthenticate())
        dispatch(unSetProfile());
        dispatch(unSetStartUpData());
        navigate('/');
    }

    const profileClick = () => {
        navigate('/userSetting');
        handleClose();
    }

    const menuItems = [
        {
            id: 1,
            label: 'Dashboard',
            icon: dashboard,
            disableIcon: dashboardInactive,
            path: '/home'
        },
        {
            id: 2,
            label: 'Profile',
            icon: profile,
            disableIcon: profileInactive,
            path: '/profile'
            // path: user?.uploaded_pitch_deck ? '/complete-profile' : '/profile'
        },

        {
            id: 3,
            label: 'Recommendations',
            icon: recommendations,
            disableIcon: recommendationsDisabled,
            path: '/recommendation'
        },

        {
            id: 4,
            label: 'Saved Conversaions',
            icon: savedConversation,
            disableIcon: savedConversationInactive,
            path: '/chat'
        },
    ]

    const generateSessionItems = useCallback(() => {
        if (!userId) return

        if (conversations) {
            return conversations?.map((item: any, index: number) => {
                const comment = item.title
                const conversationId = item.id
                return <SessionItem
                    key={index}
                    username={item.chatPersona}
                    comment={comment}
                    avatar={ChatPersonaAvatars[item.chatPersona as chatPersonas]}
                    onClick={() => {

                        try {
                            navigate(`/answer/${conversationId}`)
                            toggleSidebar()
                        }
                        catch (e: any) {

                        }
                    }} />
            })
        }
        else return <></>
    }, [conversations, navigate, toggleSidebar, userId])

    return (
        <div className={`flex flex-col justify-between mt-6 peer lg:h-auto h-[90dvh] ${isSidebarOpen ? 'lg:w-[280px] w-full ' : 'w-0 hidden lg:block'} transition-all`} data-state={isSidebarOpen && !isLoading ? 'open' : 'closed'}>
            <h4 className='text-sm font-medium'>Discover</h4>


            <div className={`flex flex-col  h-full`}>
                {/*NAVIGATION*/}
                <div className='mt-2 mb-6'>
                    {menuItems.map(item => {
                        return <SideMenuItem
                            key={item.id}
                            disabledIcon={item.disableIcon}
                            isDisabled={false}
                            label={item.label}
                            icon={item.icon}
                            path={item.path}
                            isAuthenticated={isAuthenticated}
                            onClick={() => {
                                navigate(item.path)
                                if (window.innerWidth < 768) {
                                    toggleSidebar()
                                }


                            }}
                        />
                    })}
                </div>

                {/*SESSIONS*/}
                {isAuthenticated &&
                    <div className='flex flex-col'>
                        <h4 className='text-sm font-medium'>Chats</h4>
                        <Link to={'/chat'} className='flex items-center gap-x-2 bg-[#097DFF] text-[15px] text-white px-3 py-2.5 rounded-md text-sm font-normal mt-2.5'>
                            <img src={aiWhite} alt="" />
                            <p>Ask me anything</p>
                        </Link>
                        {conversations?.length > 0 &&
                            <div className='mt-1.5 ml-3'>
                                <div className='py-4 text-sm text-[#495057] flex items-center gap-x-2.5' >
                                    <img src={History} alt="" />
                                    <p className='text-sm'>Chat History</p>
                                </div>
                                <div className='overflow-y-auto max-h-[40dvh] border-l pl-4 border-[#D9D9D9]'>
                                    {generateSessionItems()}
                                </div>
                            </div>
                        }
                    </div>
                }




            </div>
            {/*LOGGED USER*/}
            {isAuthenticated &&
                <div className=''>

                    <div className=' flex items-center gap-2.5 p-4 text-sm lg:border-t lg:border-t-[#DEE2E6]  cursor-pointer'>
                        {isSidebarOpen &&
                            <div>
                                <Button
                                    id="demo-positioned-button"
                                    aria-controls={open ? 'demo-positioned-menu' : undefined}
                                    aria-haspopup="true"
                                    aria-expanded={open ? 'true' : undefined}
                                    onClick={handleClick}
                                >
                                    <div >
                                        {
                                            user?.founder_image_url ?
                                                <img src={user?.founder_image_url} alt={'avatar'} style={{
                                                    width: 32,
                                                    height: 32,
                                                    borderRadius: '50%'
                                                }} />
                                                :
                                                <div className="flex size-8 shrink-0 select-none items-center justify-center rounded-full bg-primary text-xs font-medium uppercase text-white">
                                                    {getUserInitials(user.first_name!!, user.last_name!!)}
                                                </div>
                                        }

                                        {/* <img src={user?.founder_image_url ? defaultProfile : defaultProfile} alt={'avatar'} style={{
                                            width: 32,
                                            height: 32,
                                            borderRadius: '50%'
                                        }} /> */}
                                    </div>
                                    <div style={{ marginLeft: '10px', color: '#495057', textTransform: 'none' }}>{user?.first_name}</div>
                                </Button>
                                <Menu
                                    id="demo-positioned-menu"
                                    aria-labelledby="demo-positioned-button"
                                    anchorEl={anchorEl}
                                    open={open}
                                    onClose={handleClose}
                                    anchorOrigin={{
                                        vertical: 'top',
                                        horizontal: 'left',
                                    }}
                                    transformOrigin={{
                                        vertical: 'top',
                                        horizontal: 'left',
                                    }}
                                    style={{ marginTop: '-70px' }}
                                >
                                    <MenuItem onClick={() => { profileClick(); toggleSidebar() }}>Account</MenuItem>
                                    <MenuItem onClick={() => { signOut() }}>Logout</MenuItem>
                                </Menu>
                            </div>
                        }


                        <div className='flex items-center gap-x-8 ml-6 lg:hidden z-[99]'>
                            <div onClick={() => { profileClick(); toggleSidebar() }} >Account</div>
                            <div onClick={() => { signOut() }}>Logout</div>
                        </div>
                    </div>
                </div>
            }
        </div>
    )
};

