import React, { FC, useState } from 'react'
import logo from '../../assets/svg/logo.svg'
import { Button, Form, FormProps, Input } from 'antd';
// import { , createAccount, getBusiness, loginAccount } from "../../api";
import { Link, Navigate, useNavigate } from "react-router-dom";
import VerificationInput from "react-verification-input";
import { useDispatch } from "react-redux";
import { useLoginMutation, useRegisterMutation, useConfirmAccountMutation } from '../../lib/features/auth/authApiSlice';
import { errorMessage, successMessage } from '../../constants/notification';
import { setProfile } from '../../lib/features/user/userSlice';
import { authenticate } from '../../lib/features/auth/authSlice';
import { useAppSelector } from '../../lib/hooks';
import { useLazyGetStartUpQuery } from '../../lib/features/startup/startupApiSlice';
import { useLazyGetConversationsQuery } from '../../lib/features/conversation/conversationApiSlice';
import { useUpdateProfileImageMutation } from '../../lib/features/user/userApiSlice';

type FieldType = {
    username?: string;
    password?: string;
    email?: string;
    first_name?: string;
    last_name?: string;
};

interface IProps {
}

export const Login: FC<IProps> = () => {
    const [isConfirm, setIsConfirm] = useState(false);
    const [confirmationCode, setConfirmationCode] = useState<string>();
    const [isLogin, setIsLogin] = useState(true);
    const isAuthenticated = useAppSelector(state => state.auth.isAuthenticated);

    // const [userFields, setUserFields] = useState<{ username: string, email: string, password: string }>();
    const navigate = useNavigate();
    const dispatch = useDispatch()

    const [login, { isLoading: loginIsLoading }] = useLoginMutation()
    const [register, { isLoading: registerIsLoading }] = useRegisterMutation()
    const [confirmAccount, { isLoading: confirmIsLoading }] = useConfirmAccountMutation()
    const [getStartUp] = useLazyGetStartUpQuery()
    const [getConversations] = useLazyGetConversationsQuery()
    const [updateProfileImage] = useUpdateProfileImageMutation()

    const onFinish: FormProps<FieldType>["onFinish"] = async (values) => {

        try {
            if (isLogin) {
                const { username, password } = values
                if (!username || !password) {
                    errorMessage('Complete the fields')
                    return
                } else if (password.length <= 7) {
                    errorMessage("Password should be at least 8 characters long")
                    return
                }


                await login({ username: username, password: password }).unwrap().then(async (res: any) => {
                    // await updateProfileImage({ userId: res?.userData.id })
                    successMessage("Login successful")
                    dispatch(authenticate(res.signinResponse.AuthenticationResult))
                    dispatch(setProfile(res?.userData))
                    getStartUp(res?.userData.id)
                    getConversations(res?.userData.id)
                    if (res?.userData.watched_tutorial) {
                        navigate('/home')
                    } else {
                        navigate('/first-time-login')
                    }
                }).catch((err) => {
                    errorMessage(err?.data?.detail || "Failed to login")

                })
            } else {
                const { email, password, first_name, last_name } = values
                await register({ username: "", email: email, password: password, first_name: first_name, last_name: last_name }).unwrap().then((res) => {
                    successMessage("Registration successful, Kindly confirm your account.")
                    localStorage.setItem("EMAIL", email ?? "")
                    setIsConfirm(true)
                }).catch((err) => {
                    if (err?.data?.detail === "User not confirmed") {
                        successMessage("Registration successful, Kindly confirm your account.")
                        localStorage.setItem("EMAIL", email ?? "")
                        setIsConfirm(true)
                        return
                    }
                    errorMessage(err?.data?.detail?.length < 50 ? err?.data?.detail : "Something went wrong, Please try again later")

                })
            }
        }
        catch (e: any) {
            errorMessage(e)

        }
    };

    const onFinishFailed: FormProps<FieldType>["onFinishFailed"] = (errorInfo) => {

    };
    // if (isAuthenticated) {
    //     return <Navigate to={'/home'} />
    // }

    if (isLogin) {
        return (
            <div className='flex flex-col items-center justify-center self-center h-[96vh] w-full bg-white rounded-lg'>

                <div className='p-4 lg:p-8 border border-[#DEE2E6] rounded-lg'>
                    <div style={{
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'center',
                        alignItems: 'center',
                    }}>
                        <img src={logo} style={{ width: 32, margin: 'auto', }} alt={'logo'} />
                        <div style={{ textAlign: 'center', fontSize: 18, fontWeight: '500', margin: '10px 0' }}>Sign in to you Account
                        </div>
                    </div>

                    <Form
                        layout="vertical"
                        style={{ width: 350 }}
                        initialValues={{}}
                        onFinish={onFinish}
                        onFinishFailed={onFinishFailed}
                        autoComplete="off"
                    >
                        <Form.Item<FieldType>
                            hasFeedback
                            label="Email"
                            name="username"
                            rules={[{ required: true, message: 'Please input your email!', type: 'email' }]}
                        >
                            <Input />
                        </Form.Item>

                        <Form.Item<FieldType>
                            label="Password"
                            name="password"
                            hasFeedback
                            rules={[{ required: true, message: 'Please input your password!' }]}
                        >
                            <Input.Password />
                        </Form.Item>

                        <Form.Item>
                            <Button
                                disabled={loginIsLoading}
                                loading={loginIsLoading}
                                type="primary"
                                style={{ backgroundColor: '#228BE6', width: '100%', marginTop: 10 }}
                                htmlType="submit"
                            >
                                Login
                            </Button>
                            <Link to={'/forgot-password'} className='float-right'><span className='text-primary cursor-pointer' >Forgot Password?</span></Link>
                        </Form.Item>

                    </Form>


                    <p className='text-center'>Dont have an account? <span className='text-primary cursor-pointer' onClick={() => {
                        setIsLogin(false)
                    }}>Register</span></p>

                    {/* <Button
                        onClick={() => {
                            setIsLogin(false)
                        }}
                        type="dashed"
                        style={{ width: '100%', borderColor: '#228BE6', color: '#228BE6' }}
                        htmlType="submit"
                    >
                        back
                    </Button> */}
                </div>
            </div>
        )
    }

    return (
        <>
            <style scoped>
                {`
                    .VerificationInputContainer {
                    
                    } 
                    
                    .VerificationInputStyle {
                        border: none;
                    }   
                    
                    .VerificationInputInactiveStyle {
                        border: none;
                        background-color: transparent;
                    } 
                          
                    .VerificationInputSelectedStyle {
                        border: none !important;
                        background-color: transparent;
                        outline: none;
                    }
                `}
            </style>
            <div className='flex flex-col items-center justify-center self-center h-[96vh] w-full bg-white rounded-lg' >
                <div className='p-4 m-4 overflow-auto lg:p-8 border border-[#DEE2E6] rounded-lg'>
                    <div className='flex flex-col justify-center items-center' >
                        <img src={logo} style={{ width: 32, }} alt={'logo'} />
                        <div style={{ textAlign: 'center', fontSize: 18, fontWeight: '500', margin: '10px 0' }}>Create
                            Your
                            Account
                        </div>


                        {isConfirm ?
                            <div>
                                <div style={{ color: '#868E96', marginBottom: 50 }}>Enter the verification code we just
                                    sent
                                    on your email
                                </div>

                                <div style={{ fontWeight: '600' }}>Email verification code</div>

                                <div style={{
                                    padding: 10,
                                    display: "flex",
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                    border: '1px solid #CED4DA',
                                    marginTop: 10,
                                    borderRadius: 8
                                }}>
                                    <VerificationInput
                                        onChange={(value) => {
                                            setConfirmationCode(value)
                                        }}
                                        placeholder={'*'}
                                        classNames={{
                                            container: 'VerificationInputContainer',
                                            character: 'VerificationInputStyle',
                                            characterInactive: 'VerificationInputInactiveStyle',
                                            characterSelected: 'VerificationInputSelectedStyle'
                                        }}
                                    />
                                </div>

                                <Button
                                    onClick={async () => {
                                        if (!confirmationCode || confirmationCode?.length !== 6) {
                                            alert('Please enter your confirmation code')
                                            return
                                        }

                                        const localSEmail = localStorage.getItem("EMAIL")
                                        if (!localSEmail) {
                                            errorMessage('Cannot confirm your account. Please try again later')
                                            return
                                        }

                                        try {
                                            confirmAccount({ email: localSEmail, code: confirmationCode }).then((res) => {
                                                localStorage.setItem("EMAIL", "")
                                                successMessage("Your account has been successfully confirmed, Kindly proceed to sign in")
                                                setIsConfirm(false)
                                                setIsLogin(true)

                                            }).catch((err) => {

                                            });
                                            // setIsConfirming(true)
                                            // if (!userFields || !userFields.email || !confirmationCode) return
                                            // const confirm = await confirmAccount(userFields.email, confirmationCode)
                                            // 
                                            // localStorage.setItem(USER, JSON.stringify(confirm))
                                            // dispatch(setUser(confirm))
                                            // navigate('/chat')
                                            // setIsConfirming(false)
                                        }
                                        catch (e: any) {

                                        }
                                    }}
                                    disabled={confirmIsLoading}
                                    loading={confirmIsLoading}
                                    type="primary"
                                    style={{ backgroundColor: '#228BE6', width: '100%', marginTop: 20 }}
                                >
                                    Verify
                                </Button>
                            </div>

                            :
                            <Form
                                layout="vertical"
                                style={{ width: 350 }}
                                initialValues={{}}
                                onFinish={onFinish}
                                onFinishFailed={onFinishFailed}
                                autoComplete="off"
                            >
                                <Form.Item
                                    name={'email'}
                                    hasFeedback
                                    label="Email"
                                    rules={[{ required: true, message: 'Email is invalid!', type: 'email' }]}>
                                    <Input />
                                </Form.Item>

                                {/* <Form.Item<FieldType>
                                    hasFeedback
                                    label="Username"
                                    name="username"
                                    rules={[{ required: true, message: 'Please input your username!' }]}
                                >
                                    <Input />
                                </Form.Item> */}

                                <Form.Item<FieldType>
                                    hasFeedback
                                    label="First Name"
                                    name="first_name"
                                    rules={[{ required: true, message: 'Please input your first name!' }]}
                                >
                                    <Input />
                                </Form.Item>

                                <Form.Item<FieldType>
                                    hasFeedback
                                    label="Last Name"
                                    name="last_name"
                                    rules={[{ required: true, message: 'Please input your last name!' }]}
                                >
                                    <Input />
                                </Form.Item>

                                <Form.Item<FieldType>
                                    label="Password"
                                    name="password"
                                    hasFeedback
                                    rules={[
                                        { required: true, message: 'Please input your password!' },
                                        ({ getFieldValue }) => ({
                                            validator(_, value) {
                                                if (!value || value.length <= 7) {
                                                    return Promise.reject(new Error('Please input a stronger password'));
                                                }
                                                const regex = /^(?=.*[0-9])(?=.*[!@#$%^&*-])(?=.*[A-Z])(?=.*[a-z]).{8,}$/;
                                                if (!regex.test(value)) {
                                                    return Promise.reject(new Error('Password must contain at least one number, one special character (!@#$%^&*-), one uppercase letter, and one lowercase letter.'));
                                                }
                                                return Promise.resolve()
                                            },
                                        }),
                                    ]}
                                >
                                    <Input.Password />
                                </Form.Item>

                                <Form.Item
                                    name="confirm"
                                    label="Confirm Password"
                                    dependencies={['password']}
                                    hasFeedback
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Please confirm your password!',
                                        },
                                        ({ getFieldValue }) => ({
                                            validator(_, value) {
                                                if (!value || getFieldValue('password') === value) {
                                                    return Promise.resolve();
                                                }
                                                return Promise.reject(new Error('The new password that you entered do not match!'));
                                            },
                                        }),
                                    ]}
                                >
                                    <Input.Password />
                                </Form.Item>

                                <Form.Item>
                                    <Button
                                        disabled={registerIsLoading}
                                        loading={registerIsLoading}
                                        type="primary"
                                        style={{ backgroundColor: '#228BE6', width: '100%', marginTop: 10 }}
                                        htmlType="submit"
                                    >
                                        Register
                                    </Button>
                                </Form.Item>
                            </Form>
                        }
                        <p className='text-center'>Already have an account? <span className='text-primary cursor-pointer' onClick={() => {
                            setIsLogin(true)
                        }}>Login</span></p>

                        {/* <Button
                            onClick={() => {
                                setIsLogin(true)
                            }}
                            type="dashed"
                            style={{ width: '100%', borderColor: '#228BE6', color: '#228BE6' }}
                            htmlType="submit"
                        >
                            Sign in
                        </Button> */}

                    </div>
                </div>
            </div>
        </>
    )
};

