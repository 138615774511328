import React from 'react'
import Table, { SelectColumnFilter, StatusPill } from '../../../components/CustomTable';


const getData = () => [
    {
        name: "Jane Cooper",
        match: "81%",
        type: "VC Fund",
        country: "United States",
        stage: "Pre-seed, Seed, Series A",
        funnel_stage: "",
        stats: "",
    },

    {
        name: "Jane Cooper",
        match: "81%",
        type: "VC Fund",
        country: "United States",
        stage: "Pre-seed, Seed, Series A",
        funnel_stage: "",
        stats: "",
    },

    {
        name: "Jane Cooper",
        match: "81%",
        type: "VC Fund",
        country: "united States",
        stage: "Pre-seed, Seed, Series A",
        funnel_stage: "",
        stats: "",
    },
];

function FundRaising() {

    const columns = React.useMemo(
        () => [
            {
                Header: "Name",
                accessor: "name"
            },
            {
                Header: "Match",
                accessor: "match"
            },
            {
                Header: "Type",
                accessor: "type",
            },
            {
                Header: "Country",
                accessor: "country",

            },
            {
                Header: "Stage",
                accessor: "stage",

            },
            {
                Header: "Funnel Stage",
                accessor: "funnel_stage",

            },
            {
                Header: "Status",
                accessor: "status",

            }
        ],
        []
    );

    const data = React.useMemo(() => getData(), []);
    return (
        <div className='px-4 pt-4 w-full'>
            <section className='border-b w-full border-[#E9ECEF]'>
                <div className='flex items-center justify-between '>
                    <div className='flex gap-x-6 items-center'>
                        <h2 className='text-xl font-medium'>Fund Raising</h2>
                    </div>
                </div>
                <div className=' mt-5'></div>
            </section>

            <section>
                <div className='mt-7 gap-x-4'>
                    <p>Investor List</p>
                </div>
            </section>

            <section className='mt-12'>
                <Table columns={columns} data={data} />
            </section>


        </div>
    )
}

export default FundRaising
