import { IUser } from "../../../@types/types";
import { createSlice } from '@reduxjs/toolkit'
import { userApiSlice } from "./userApiSlice";



const initialUser: IUser = {
    id: "",
    first_name: "",
    last_name: "",
    profile_image_url: "",
    founder_image_url: "",
    email: "",
    name_of_startup: "",
    linkedin_profile: "",
    personal_website: "",
    dedication: "",
    name_full_time_co_founder: "",
    name_part_time_co_founder: "",
    bio: "",
    bio_link: "",
    under_school: "",
    graduate_school: "",
    highest_degree: "",
    entrepreneurial_experience: "",
    race: "",
    pre_accelerators: "",
    recent_employer: "",
    penultimate_employer: "",
    employers_last_10_years: "",
    gender: "",
    ethnicity: "",
    disability_status: false,
    sexual_orientation: "",
    religion: "",
    nationality: "",
    country: "",
    city: "",
    interests: [],
    hobbies: [],
    relationship_status: "",
    has_kids: false,
    us_first_gen: false,
    birth_date: null,
    previous_offered_vcs: null,
    previous_offered_investors: null,
    feedback: null,
    subscription: null, 
    sub_response: "",
    watched_tutorial: false,
    uploaded_pitch_deck: false,
    pitch_deck_object_key: "",
};


const userSlice = createSlice({
    name: 'user',
    initialState: initialUser,
    reducers: {
        setProfile: (state, action) => {
            state = { ...state, ...action.payload };
            return state
        },
        unSetProfile: state => {
            return initialUser
        },
    },
    extraReducers: (builder) => {
        builder
            .addMatcher(userApiSlice.endpoints.getUser.matchPending, (state, action) => {
                return
            })
            .addMatcher(userApiSlice.endpoints.getUser.matchFulfilled, (state, action) => {
                state = { ...state, ...action.payload };
                // console.log(state)
                return state
            })
            .addMatcher(userApiSlice.endpoints.getUser.matchRejected, (state, action) => {
                return state
            })
    },
})


export const { setProfile, unSetProfile } = userSlice.actions
export default userSlice.reducer