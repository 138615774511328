import React from 'react'
import { Link, useNavigate } from 'react-router-dom';
import UploadIcon from './Icons/UploadIcon';
import PenIcon from './Icons/PenIcon';
import { Modal, Upload, UploadProps } from 'antd';
import { useAppSelector } from '../lib/hooks';
import { RootState } from '../lib/store';
import { BASE_URL } from '../lib/services/reAuth';
import { useGetUserQuery, useUpdateUserMutation } from '../lib/features/user/userApiSlice';
import { errorMessage, successMessage } from '../constants/notification';


function PitchDeckModal({ pitchDeckModal, setPitchDeckModal }: { pitchDeckModal: boolean, setPitchDeckModal: any }) {
    const user = useAppSelector((state: RootState) => state.user)
    const navigate = useNavigate()
    const handleOkPitchDeckModalClose = () => {
        setPitchDeckModal(false)
    };

    const [updateUser] = useUpdateUserMutation()

    const accessToken = useAppSelector((state: RootState) => state.auth.access)
    const startupData = useAppSelector((state: RootState) => state.startup)

    const { refetch: userDataRefetch } = useGetUserQuery(user?.id)

    const props: UploadProps = {
        name: 'file',
        action: `${BASE_URL}pitch_deck_uploader/uploading_pitch_deck/`,
        method: 'POST',
        accept: '.pdf,.ppt,.pptx,.doc,.docx',
        headers: {
            authorization: `Bearer ${accessToken}`,
        },
        data: {
            user_id: user?.id,
            startup_id: startupData?.id,
        },
        onChange(info) {
            console.log(info.file.status, "status")
            if (info.file.status !== 'uploading') {
                console.log(info.file, info.fileList);
            }
            if (info.file.status === 'done') {
                updateUser({ userId: user?.id, uploaded_pitch_deck: true }).then((res) => {
                    userDataRefetch().then(() => {
                        navigate('/process-pitch-deck')
                    })
                }).catch((err) => {
                    // errorMessage("Failed to update information")
                });
                successMessage(`${info.file.name} file uploaded successfully`);
                // navigate('/ai-complete-profile')
            } else if (info.file.status === 'error') {
                errorMessage(`${info.file.name} file upload failed.`);
            }
        },
    };
    return (
        <Modal
            cancelButtonProps={{ style: { display: 'none' } }}
            okButtonProps={{ style: { display: 'none' } }}
            open={pitchDeckModal} onOk={handleOkPitchDeckModalClose} onCancel={handleOkPitchDeckModalClose}
            className='lg:!w-1/2 !w-full'
            title="How would you like to get started?"
            centered

        >
            <section className="bg-white">
                <div className='border-b' />
                <div className="py-8 px-4 mx-auto max-w-screen-xl lg:py-8 lg:px-6">
                    <div className="space-y-8 lg:grid lg:grid-cols-2 sm:gap-6 xl:gap-10 lg:space-y-0">
                        <div className='h-[300px] rounded-lg border border-gray-100 shadow flex items-center'>
                            <div className="flex flex-col p-6 mx-auto max-w-lg text-center text-gray-900 bg-white  xl:p-8">
                                <h3 className="mb-4 text-sm font-semibold">Upload your pitch deck</h3>
                                <p className="font-light text-gray-500 sm:text-[13px] max-w-[285px]">Upload your pitch deck to get personalized recommendations. (Recommended)</p>
                                <div className="flex justify-center items-baseline my-7">
                                    <Upload {...props}>
                                        {/* <Button size='large' type='primary' className='!bg-primary' icon={<UploadOutlined />}>Click to Upload</Button> */}
                                        <button className='flex items-center gap-x-3 bg-primary text-[15px] text-white px-6 py-3.5 rounded-lg text-sm font-normal'>
                                            <UploadIcon />
                                            <p>Click to Upload</p>
                                        </button>
                                    </Upload>

                                </div>

                            </div>
                        </div>
                        <div className='h-[300px] rounded-lg border border-gray-100 shadow flex items-center'>
                            <div className="flex flex-col p-6 mx-auto max-w-lg text-center text-gray-900 bg-white xl:p-8">
                                <h3 className="mb-4 text-sm font-semibold">Enter Information Manually</h3>
                                <p className="font-light text-gray-500 sm:text-[13px] max-w-[285px]">Enter your information manually to get personalized recommendations.</p>
                                <div className="flex justify-center items-baseline my-8">
                                    <Link to={"/complete-profile"} className='flex items-center gap-x-3 bg-primary text-[15px] text-white px-6 py-3.5 rounded-lg text-sm font-normal'>
                                        <PenIcon />
                                        <p>Enter manually</p>
                                    </Link>
                                </div>

                            </div>
                        </div>


                    </div>
                </div>
            </section>

        </Modal>
    )
}

export default PitchDeckModal
