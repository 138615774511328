/* eslint-disable jsx-a11y/alt-text */
import React, { FC, useState } from 'react'
import askBtn from '../../assets/svg/askBtn.svg'
import { garySvg } from '../../api/index';
import { manuelaSvg } from '../../api/index';
import { valentinaSvg } from '../../api/index';
import arrowDown from '../../assets/svg/arrowDown.svg'
import {  useSelector } from "react-redux";
import { PulseLoader } from "react-spinners";
import { Navigate, useNavigate } from "react-router-dom";
import { Dropdown } from 'antd';
import type { MenuProps } from 'antd';
import { RootState } from '../../lib/store';
import { useAskMutation, useGetConversationsQuery } from '../../lib/features/conversation/conversationApiSlice';
import { useAppSelector } from '../../lib/hooks';

interface IProps {
}

export const AskAnything: FC<IProps> = () => {
    const [question, setQuestion] = useState('');
    const userId = useAppSelector((state: RootState) => state.user?.id)

    const [select, setSelect] = useState<"Gary" | "Manuela" | "Valentina">('Gary');
    const navigate = useNavigate();

    const isAuthenticated = useSelector((state: RootState) => state.auth.isAuthenticated)

    const { refetch: conversationRefetch } = useGetConversationsQuery(userId)

    const inputRef = React.useRef<HTMLInputElement | null>(null);
    const buttonRef = React.useRef<HTMLButtonElement | null>(null);

    const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
        if (event.key === 'Enter') {
            // Trigger button click
            buttonRef.current?.click();
        }
    };



    const [ask, { isLoading: askIsLoading }] = useAskMutation()

    const hint = [
        {
            id: 1,
            title: "What is TAM in business?"
        },
        {
            id: 2,
            title: "How to launch my app?"
        },
        {
            id: 3,
            title: "How to find an investor for my app?"
        },
        {
            id: 4,
            title: "How to pitch my project?"
        },
    ]





    const items: MenuProps['items'] = [
        {
            key: '1',
            label: 'Gary',
            onClick: (info) => handleSelect(info.key),
            icon: <img src={garySvg} />
        },
        {
            key: '2',
            label: 'Manuela',
            onClick: (info) => handleSelect(info.key),
            icon: <img src={manuelaSvg} />
        },
        {
            key: '3',
            label: 'Valentina',
            onClick: (info) => handleSelect(info.key),
            icon: <img src={valentinaSvg} />
        },

    ];

    const handleSelect = (key: string) => {
        switch (key) {
            case '1':
                setSelect('Gary')
                return

            case '2':
                setSelect('Manuela')
                return

            case '3':
                setSelect('Valentina')
                return
        }
    }

    const handleSelectBtn = (key: string) => {
        switch (key) {
            case 'Gary':
                return (
                    <div style={{ display: 'flex', alignItems: 'center', gap: 6 }}>
                        <img src={garySvg} />
                        <div style={{ fontSize: 16 }}>Gary</div>
                        <img src={arrowDown} style={{ marginTop: 4 }} />
                    </div>
                )

            case 'Manuela':
                return (
                    <div style={{ display: 'flex', alignItems: 'center', gap: 6 }}>
                        <img src={manuelaSvg} />
                        <div style={{ fontSize: 16 }}>Manuela</div>
                        <img src={arrowDown} style={{ marginTop: 4 }} />
                    </div>
                )

            case 'Valentina':
                return (
                    <div style={{ display: 'flex', alignItems: 'center', gap: 6 }}>
                        <img src={valentinaSvg} />
                        <div style={{ fontSize: 16 }}>Valentina</div>
                        <img src={arrowDown} style={{ marginTop: 4 }} />
                    </div>
                )
        }
    }

    if (!isAuthenticated) {

        return <Navigate to={"/"} />
    }

    return (
        <div className='flex flex-col items-center h-full justify-center'
        >
            {/*TITLE*/}
            <div style={{
                display: 'flex',
                justifyContent: 'center',
                gap: 8,
                alignItems: 'center',
                fontSize: 36,
                fontWeight: '600',
                color: '#343A40'
            }}>
                <div style={{ marginBottom: 0 }}>Ask</div>

                <Dropdown
                    onOpenChange={() => {
                    }}

                    menu={{ items }}
                >
                    <div style={{ border: '4px solid #D0EBFF', borderRadius: 100, padding: 4 }}>
                        {handleSelectBtn(select)}
                    </div>
                </Dropdown>

                <div style={{ marginBottom: 0 }}>anything</div>
            </div>

            {/*ASK*/}
            <div
                style={{
                    position: 'relative',
                    display: 'flex',
                    alignSelf: 'center',
                    justifyContent: 'center',
                    alignItems: 'center',
                    marginTop: 20,
                }} className='lg:w-min w-full px-3'
            >
                <input
                    ref={inputRef}
                    onKeyDown={handleKeyDown}
                    placeholder={'What do you want to know?'}
                    value={question}
                    onChange={({ target: { value } }) => {
                        setQuestion(value)
                    }}
                    style={{
                        outline: 'none',
                        border: '1px solid #A5D8FF',
                        borderRadius: 100,
                        height: 54,
                        boxShadow: '0px 0px 8px 8px #E7F5FF',
                        paddingRight: 100,
                        paddingLeft: 20,
                        fontSize: 16,
                        color: '#495057'
                    }} className='lg:w-[32vw] w-full'
                />

                <div className='flex items-center absolute gap-x-1.5 right-5'>
                    {askIsLoading
                        ? <PulseLoader color="#228BE6" style={{ marginTop: 10, marginRight: 5 }} size={12} />
                        : <>
                            {/* <div style={{ cursor: 'pointer' }}>
                                <img src={newSessionBtn} alt={'newSessionBtn'} />
                            </div> */}
                            <button
                                ref={buttonRef}
                                onClick={async () => {
                                    ask({
                                        id: "",
                                        userId: userId,
                                        title: "",
                                        chatPersona: select,
                                        content: [
                                            {
                                                role: "user",
                                                content: question
                                            }
                                        ]
                                    }).then((res) => {

                                        navigate(`/answer/${res.data.id}`)
                                        // getConversations(res?.data?.userId)
                                        conversationRefetch()
                                    }).catch((err) => {

                                    })
                                    // if (!userId) return

                                    // setIsLoading(true)
                                    // const answer = await askQuestion(userId, [{
                                    //     role:    'user',
                                    //     content: question
                                    // }], select, "")
                                    // const conversation = await getConversationById(answer.id, userId)
                                    // const conversations = await getConversation(userId)
                                    // 
                                    // dispatch(setConversations(conversations))
                                    // dispatch(setCurrentConversations(conversation))
                                    // setQuestion('')
                                    // navigate(`/answer/${res.data.id}`)
                                    // setIsLoading(false)
                                }}
                                style={{ cursor: 'pointer' }}>
                                <img src={askBtn} alt={'askBtn'} />
                            </button>
                        </>
                    }
                </div>
            </div>

            {/* <div
                style={{
                    display: 'flex',
                    alignSelf: 'center',
                    justifyContent: 'center',
                    alignItems: 'center',
                    marginTop: 20
                }}
            >
                <img src={hints} style={{ resize: 'horizontal' }} alt={'hints'} />
            </div> */}

            <div className='flex flex-wrap items-center justify-center gap-2 max-w-[500px] mt-4'>
                <div className=''>
                    <div className='text-xs'>Ice breakers:</div>
                </div>
                {hint.map((item, index) => (
                    <button className='text-xs flex items-center justify-center gap-x-1 border py-1 px-2.5 border-gray-5300 rounded-full cursor-pointer' key={index}
                        disabled={askIsLoading}
                        onClick={() => {
                            setQuestion(item.title)
                            ask({
                                id: "",
                                userId: userId,
                                title: "",
                                chatPersona: select,
                                content: [
                                    {
                                        role: "user",
                                        content: item.title
                                    }
                                ]
                            }).then((res) => {

                                navigate(`/answer/${res.data.id}`)
                                // getConversations(res?.data?.userId)
                                conversationRefetch()
                            }).catch((err) => {

                            })
                        }}
                    >
                        <div className='bg-gray-700 w-3 h-3'></div>
                        <span>{item.title}</span>
                    </button>
                ))}


            </div>
        </div>
    )
};

