import { Route, Routes } from 'react-router-dom';
import './App.css';
import { AskAnything } from "./screens/AskAnything/AskAnything";
import { Recommendation } from "./screens/Recommendation/Recommendation";
import { Profile } from "./screens/Profile/Profile";
import {
    Chart as ChartJS,
    Tooltip,
    Legend,
    RadialLinearScale,
    PointElement, LineElement,
    Filler
} from "chart.js";
import { Settings } from "./screens/Settings/Settings";
import { Login } from "./screens/Login/Login";
import { Bounce, ToastContainer } from 'react-toastify';
import { UserSetting } from "./screens/UserSetting/UserSetting";
import 'react-toastify/dist/ReactToastify.css';
import ProtectedRoute from './components/ProtectedRoute';
import Conversation from './screens/Answer/Conversation';
import Layout from './screens/Layout';
import ForgotPassword from './screens/password/ForgotPassword';
import AICompleteProfile from './screens/Profile/AICompleteProfile';
import Homepage from './screens/Home/Homepage';
import FirstTimeLogin from './screens/FirstTimeLogin/FirstTimeLogin';
import MainProfile from './screens/Profile/MainProfile';
import ProcessPitchDeck from './screens/FirstTimeLogin/ProcessPitchDeck';

ChartJS.register(RadialLinearScale,
    PointElement,
    LineElement,
    Filler,
    Tooltip,
    Legend);

function App() {


    return (
        <>
            <Routes>
                <Route path="/" element={<Login />} />
                <Route path="/forgot-password" element={<ForgotPassword />} />
                <Route element={<ProtectedRoute />}>
                    <Route element={<Layout showNavbar={false} />}>
                        <Route path="/first-time-login" element={<FirstTimeLogin />} />
                        <Route path="/process-pitch-deck" element={<ProcessPitchDeck />} />
                    </Route>
                    <Route element={<Layout showNavbar={true} />}>
                        <Route path="/home" element={<Homepage />} />
                        <Route path="/chat" element={<AskAnything />} />
                        <Route path="/recommendation" element={<Recommendation />} />
                        <Route path="/profile" element={<MainProfile />} />
                        <Route path="/complete-profile" element={<Profile />} />
                        <Route path="/settings" element={<Settings />} />
                        <Route path="/answer/:id" element={<Conversation />} />
                        <Route path="/userSetting" element={<UserSetting />} />
                        <Route path="/ai-complete-profile" element={<AICompleteProfile />} />
                    </Route>
                </Route>
            </Routes>
            <ToastContainer
                position="top-right"
                autoClose={5000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                theme="light"
                transition={Bounce}
            />
        </>
    );
}

export default App;
