import { Button, Form, Input, Modal } from 'antd'
import React, { useState } from 'react'
import Play from "../../../assets/svg/Play.svg"
import '@react-pdf-viewer/core/lib/styles/index.css';
import { IoClose } from 'react-icons/io5';
import { Upload, UploadProps } from 'antd';
import { useNavigate } from 'react-router-dom';
import { useAppSelector } from '../../../lib/hooks';
import { RootState } from '../../../lib/store';
import { useGetUserQuery, useUpdateUserMutation } from '../../../lib/features/user/userApiSlice';
import { BASE_URL } from '../../../lib/services/reAuth';
import { errorMessage, successMessage } from '../../../constants/notification';
import UploadIcon from '../../../components/Icons/UploadIcon';
import { useGetPitchDeckMutation } from '../../../lib/features/pitch_deck/pitchDeck';
import { DocumentViewer } from 'react-documents';



function PitchDeck() {

    const [isReplaceModalOpen, setReplaceIsModalOpen] = useState(false);
    const [isShareModalOpen, setShareIsModalOpen] = useState(false);

    const user = useAppSelector((state: RootState) => state.user)
    const navigate = useNavigate()

    const [getPitchDeck] = useGetPitchDeckMutation()


    const [updateUser] = useUpdateUserMutation()

    const accessToken = useAppSelector((state: RootState) => state.auth.access)
    const startupData = useAppSelector((state: RootState) => state.startup)

    const { refetch: userDataRefetch } = useGetUserQuery(user?.id)

    const [pitchDeckFile, setPitchDeckFile] = useState<string>("")

    React.useEffect(() => {
        if (user?.pitch_deck_object_key) {
            getPitchDeck({ user_id: user?.id, object_key: user?.pitch_deck_object_key }).then((res) => {
                console.log(res.data.file_path)
                setPitchDeckFile(res.data.file_path)
            }).catch((err) => {
                setPitchDeckFile("")
                errorMessage("Failed to get pitch deck")
            })
        }
    }, [getPitchDeck, user?.id, user?.pitch_deck_object_key])

    const handleCancel = () => {
        setReplaceIsModalOpen(false);
    }
    const handleShareModalCancel = () => {
        setShareIsModalOpen(false);
    }
    const handleShareModalOk = () => {
        setShareIsModalOpen(false);
    }
    const handleOk = () => {
        setReplaceIsModalOpen(false);
    }

    const props: UploadProps = {
        name: 'file',
        action: `${BASE_URL}pitch_deck_uploader/uploading_pitch_deck/`,
        method: 'POST',
        accept: '.pdf,.ppt,.pptx,.doc,.docx',
        headers: {
            authorization: `Bearer ${accessToken}`,
        },
        data: {
            user_id: user?.id,
            startup_id: startupData?.id,
        },
        onChange(info) {
            console.log(info.file.status, "status")
            if (info.file.status !== 'uploading') {
                console.log(info.file, info.fileList);
            }
            if (info.file.status === 'done') {
                updateUser({ userId: user?.id, uploaded_pitch_deck: true }).then((res) => {
                    userDataRefetch().then(() => {
                        navigate('/process-pitch-deck')
                    })
                }).catch((err) => {
                    // errorMessage("Failed to update information")
                });
                successMessage(`${info.file.name} file uploaded successfully`);
                // navigate('/ai-complete-profile')
            } else if (info.file.status === 'error') {
                errorMessage(`${info.file.name} file upload failed.`);
            }
        },
    };

    return (
        <>
            <div className='lg:px-10 px-4 pt-4 w-full'>
                <section className='border-b w-full border-[#E9ECEF]'>
                    <div className='flex items-center justify-between '>
                        <div className='flex gap-x-6 items-center'>
                            <h2 className='text-2xl font-medium'>Pitch Deck</h2>

                            <div className='flex gap-x-4 items-center'>
                                {/* <Link to={pitchDeckFile} className='text-primary'>Preview</Link> */}
                                <img src={Play} alt="" />
                            </div>
                        </div>
                        <div className='flex items-center gap-x-2'>
                            {/* <p className='mr-8'>2 Views</p> */}
                            <Button size="large" onClick={() => { setShareIsModalOpen(true) }} className='bg-black text-white px-12 py-6'>
                                Share
                            </Button>
                            <Button size="large" onClick={() => { setReplaceIsModalOpen(true) }} className='bg-primary text-white px-12 py-6'>
                                Replace
                            </Button>
                        </div>
                    </div>

                    <div
                        className='mt-8'
                        style={{

                            height: '750px',
                        }}
                    >

                        {pitchDeckFile !== "" ?
                            <DocumentViewer
                                url={pitchDeckFile}
                                viewer="url"

                            />
                            :
                            <div className='flex items-center justify-center h-full'>
                                <p className='text-2xl font-bold'>No pitch deck uploaded yet</p>
                            </div>
                        }
                    </div>

                    <div className=' mt-5'></div>
                </section>
            </div>

            <Modal centered title="Replace Pitch Deck" closeIcon={<IoClose className='size-6 absolute right-0 top-0 rounded-md bg-white text-black' />}
                cancelButtonProps={{ style: { display: 'none' } }}
                okButtonProps={{ style: { display: 'none' } }}
                open={isReplaceModalOpen} onOk={handleOk} onCancel={handleCancel}
                className='lg:!w-1/3 !w-full'
            >
                <div className="flex justify-center items-baseline my-7">
                    <div className="flex flex-col p-6 mx-auto max-w-lg text-center text-gray-900 bg-white  xl:p-8">
                        <h3 className="mb-4 text-sm font-semibold">Upload your pitch deck</h3>
                        <p className="font-light text-gray-500 sm:text-[13px] max-w-[285px]">Upload your pitch deck to get personalized recommendations. (Recommended)</p>
                        <div className="flex justify-center items-baseline my-7">
                            <Upload {...props}>
                                {/* <Button size='large' type='primary' className='!bg-primary' icon={<UploadOutlined />}>Click to Upload</Button> */}
                                <button className='flex items-center gap-x-3 bg-primary text-[15px] text-white px-6 py-3.5 rounded-lg text-sm font-normal'>
                                    <UploadIcon />
                                    <p>Click to Upload</p>
                                </button>
                            </Upload>

                        </div>
                    </div>
                </div>

            </Modal>

            <Modal centered title="Share" closeIcon={<IoClose className='size-6 absolute right-0 top-0 rounded-md bg-white text-black' />}
                cancelButtonProps={{ style: { display: 'none' } }}
                okButtonProps={{ style: { display: 'none' } }}
                open={isShareModalOpen} onOk={handleShareModalOk} onCancel={handleShareModalCancel}
                className='lg:!w-1/3 !w-full'
            >

                <div className='flex flex-col gap-y-3'>
                    <div className='w-full py-4 px-5 border border-[#E8E8E8] rounded-md shadow-sm shadow-black/10 '>
                        <Form
                            layout="vertical"
                            className='w-full'
                        >
                            <div className='flex gap-x-1 items-end w-full'>
                                <Form.Item
                                    name="name_of_start_up"
                                    className='w-full'
                                    label="Share your pitch"
                                    rules={[{ required: false, message: 'Please input the name of your start-up!' }]}

                                >
                                    <Input addonBefore={<p>https://</p>} placeholder="Share your pitch" defaultValue={pitchDeckFile.replace(/^https?:\/\//, '')} />
                                </Form.Item>
                                <Form.Item>
                                    <Button type="text" htmlType="submit" className='bg-black text-white'>
                                        Copy Link
                                    </Button>
                                </Form.Item>
                            </div>
                        </Form>
                    </div>

                </div>


            </Modal>
        </>
    )
}

export default PitchDeck